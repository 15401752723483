//-------------------------------------------------------------
//blockquote
//-------------------------------------------------------------

// Variables

// General
.quote {
    position: relative;
    width: 100%;

    //background-icon
    &:before {
        @extend %cadfemicon;
        position: absolute !important;
        z-index: 1;
        content: '\42';
        margin-left: -69px;
        left: 50%;
        height: 90px;
        font-size: 90px !important;
        line-height: 90px !important;
        color: $cadfem-darkblue;
        opacity: 0.08;
    }

    &__image {
        max-height: 120px;
        margin-bottom: building-units(1);
    }

    &__img {
        border-radius: 8px;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 120px;
        border: 1px solid $cadfem-steamgray;
    }

    &__content {
        position: relative;
        z-index: 2;
        margin: 0 auto;
        @include make-col(6, 12);

        p {
            @extend %text-large;
            font-weight: $font-weight-semibold;
            margin-bottom: building-units(1);
            text-align: center;
            color: $cadfem-darkblue;

            .section_cf--bg-primary & {
                color: $cadfem-purewhite;
            }

            @include media-breakpoint-down(sm) {
                font-size: $font-size-default;
                line-height: $line-height-default;
            }
        }

        @include media-breakpoint-only(md) {
            @include make-col(8, 12);
        }

        @include media-breakpoint-only(sm) {
            @include make-col(10, 12);
        }

        @include media-breakpoint-only(xs) {
            @include make-col(12, 12);
        }
    }

    &__source {
        position: relative;
        z-index: 2;

        @extend %text-small;
        font-style: normal;
        text-align: center;
        display: block;
        margin: 0 auto;
        @include make-col(4, 12);


        @include media-breakpoint-only(md) {
            @include make-col(6, 12);
        }

        @include media-breakpoint-only(sm) {
            @include make-col(6, 12);
        }

        @include media-breakpoint-only(xs) {
            @include make-col(12, 12);
        }
    }

    a {
        color: $cadfem-darkblue;
        text-align: center;
        margin-left: 5px;
        text-decoration: none;

        &.link--intern:after {
            content: "\4f";
            width: 25px;
            margin-left: 5px;
        }

        .section_cf--bg-primary & {
            color: $cadfem-purewhite;

            &:hover {
                color: $cadfem-cornflower;
            }
        }

        .section_cf--bg-secondary & {
            color: $cadfem-darkblue;
            &:hover {
                color: $cadfem-cornflower;
            }
        }
    }
}

.card_cf--bg-secondary .quote:before {
    color: #EBEFF2;
}


.quote-testimonial {
    .quote__content {
        justify-content: center;
        align-items: center;
        display: block;
        @include media-breakpoint-down(md) {
           margin-bottom: 45px;
        }
    }
    .quote__img {
        margin: 0 auto;
        display: block;
    }
    .quote__source {
        margin-bottom: building-units(1);
    }

    a.link {
        text-decoration: underline;
        display: block;
    }
}

// Adjust the width of the swiper quote-container without offset-huge for lg
.swiper-testimonial {
    .quote-testimonial {
        .quote__content {
            @include media-breakpoint-up(lg) {
                max-width: 60%;
             }
        }
    }
}
// Adjust the width of the swiper quote-container at offset-huge for lg and md, sm
.section_cf--offset-huge .swiper-testimonial {
    .quote-testimonial {
        .quote__content {
            @include media-breakpoint-up(lg) {
                max-width: 80%;
             }
            @include media-breakpoint-between(sm, md) {
                max-width: 90%;
             }
        }
    }
}

/**
 * override styles of swiper for subcomponent
 */
.swiper {
    .swiper-button-next,
    .swiper-button-prev {
        &:after {
            font-size: 36px;
            color: $cadfem-pearlgray;
            text-shadow: none !important;
        }

        &:hover{
            background: transparent;
        }

        &:hover:after {
            color: $cadfem-ashgray;
        }

        @include media-breakpoint-only(xs) {
            top: calc(100% + 30px) !important;
            z-index: 59;
        }
        &.swiper-button-disabled {
            &:hover, &:hover:after {
                color: $cadfem-pearlgray;
                cursor: not-allowed;
            }
        }
    }
    .swiper-slide__content {
        position: inherit;
        max-height: inherit;
        border-color: transparent;
        left: 0;
        bottom: inherit;
        width: 100%;
        max-width: 100%;
        padding-top: 0;
    }

    .swiper-pagination-bullet {
        background-color: $cadfem-steamgray;
        width: building-units(1);
        height: building-units(1);
        margin-right: building-units(0.5);

        &:hover {
            background-color: $cadfem-pearlgray;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: $cadfem-cornflower;
    }
    .swiper-container {
        height: 100%;
        overflow-y: hidden;
    }
}
