//-------------------------------------------------------------
// Section
//-------------------------------------------------------------
// Variables
$section-bg-dark-gray: #4a4a4a;

.section_cf {
    color: $cadfem-darkblue;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: $white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: building-units(4) building-units(1);
    //default padding

    &__content {
        @extend .container;
        min-width: auto;
        position: relative;
        padding: 0 building-units(1); //default padding

        @include media-breakpoint-down(sm) {
            padding: 0 building-units(0.5);
        }
    }

    &--fullwidth {
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: calc(50%);
    }

    //BACKGROUND COLORS
    &--bg-primary {
        border-color: $cadfem-darkblue;
        background-color: $cadfem-darkblue;

        h1, h2, h3, h4, h5, h6,  p, .card_cf h3, ul, ol, li {
            color: $cadfem-purewhite;
        }
    }

    &--bg-default,
    &--bg-secondary {
        border-color: $cadfem-iceblue;
        background-color: $cadfem-iceblue;

        h1, h2, h3, h4, h5, h6, p, ul, ol, li {
            color: $cadfem-darkblue;
        }
    }

    &--bg-gradient {
        background-color: $cadfem-iceblue;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$cadfem-iceblue', endColorstr='$white',GradientType=0 );
        background: $cadfem-iceblue;
        background: -moz-linear-gradient(top, $cadfem-iceblue 0%, $white 100%);
        background: -webkit-linear-gradient(top, $cadfem-iceblue 0%, $white 100%);
        background: linear-gradient(to bottom, $cadfem-iceblue 0%, $white 100%);
        color: $cadfem-darkblue;
    }

    &--offset-huge {
        .section_cf__content {
            @include media-breakpoint-up(lg) {
                padding: 0 calc((((100% / 12) / 2) * 3));
            }

            @include media-breakpoint-only(md) {
                padding: 0 calc((((100% / 12) / 2) * 2));
            }

            @include media-breakpoint-only(sm) {
                padding: 0 calc(((100% / 12) / 2));
            }
        }
    }

    &--offset-small {
        .section_cf__content {
            @include media-breakpoint-up(sm) {
                padding: 0 calc(((100% / 12) / 2));
            }
        }
    }

    &--image-top-left {
        background-position: top left;
    }

    &--image-center {
        background-position: center center;
    }

    &--align-left {
        .section_cf__content {
            text-align: left;
        }
    }

    &--align-center {
        .section_cf__content {
            text-align: center;
            margin: 0 auto;
        }

        .topline:before {
            left: calc(50% - 22.5px);
        }
    }

    &--align-right {
        .section_cf__content {
            text-align: right;
        }
    }

    &--no-overflow {
        overflow: inherit;
    }

    .card_cf.card_cf--bg-primary {
        > h3, p {
            color: $cadfem-darkblue;
        }
    }
}
