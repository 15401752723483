.login {
    padding: rem(12px);
    
    @include media-breakpoint-up(lg) {
        padding: rem(36px) rem(84px);
        max-width: rem(504px);
        border: 1px solid #ccc;
        background: #fff;
        border-radius: rem(4px);
        margin-top: rem(36px);
        margin-bottom: rem(36px);
    }
    
    @include media-breakpoint-up(xl) {
        max-width: rem(648px);
        margin-top: rem(60px);
        margin-bottom: rem(60px);
        padding: rem(36px) rem(108px);
    }

    &__heading {
        @extend %h1;
    }

    &__submit {
        margin-top: rem(20px);
    }
}