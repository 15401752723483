//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.site_chooser {

    &__description {
        margin-bottom: 50px;
    }

    &__country {
        display: flex;
        align-content: flex-start;

        img {
            margin-right: building-units(1);
            height: 26px;
        }

        &:hover .site_chooser__name {
            text-decoration: underline;
        }

        &:hover {
            color: $cadfem-darkblue;
        }
    }

    &__flag {
        margin-right: building-units(1);
    }

    &__hr {
        width: 100%;
        border-color: #C2C2C2;
        margin-top: 35px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 25px;
        }
    }

    &__vr {
        border-right: 1px solid #C2C2C2;
    }

    &__text {
        @extend %text-small;
        line-height: 18px;
        display: block;
    }
}
