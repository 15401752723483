//-------------------------------------------------------------
// Tabbar_2
//-------------------------------------------------------------

$tabbar-container-height: auto;
$tabbar-container-br-color: $cadfem-steamgray;

.tabbar_new {
    margin-bottom: building-units(2);

    &-container {
        display: flex;
        justify-content: center;
    }

    .horizontal-scroller-wrapper {
        overflow: inherit;
    }

    .tabbar {
        &-list {
            @extend %shadow--light;
            height: 45px;
            border-radius: 20px;
        }

        &__tab {
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                content: none;
            }

            &:not(:last-child) {
                border-right: 1px solid $cadfem-steamgray;
            }

            &.active {
                background-color: $cadfem-darkblue;
                a {
                    color: $cadfem-purewhite;
                }
            }
        }

        &-link {
            font-size: 13px;
            padding: 0 25px;
            text-transform: uppercase;
            color: $cadfem-darkblue;
            font-weight: $font-weight-semibold;
            letter-spacing: 1.62px;
            &:hover {
                color: $cadfem-denimblue;
            }
        }
    }
}

/*
.tabbar-content .product-tile-wrapper {
    padding-top: 0;
    max-width: 100% !important;
    flex: 0 0 100% !important;
}
*/

.tabbar-container {
    display:       inline-block;
    height:        $tabbar-container-height;

    &:before {
        display:        block;
        content:        "";
        width:          100%;
        position:       absolute;
        left:           0;
        bottom:         0;
        border-bottom:  3px solid $tabbar-container-br-color;
    }
}

@import "tabbar-list";
@import "tabbar-link";
@import "tabbar-content";
@import "tabbar-dropdown";
