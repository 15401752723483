hr {
    margin: 30px 0;
    border-top: 0;
    border-left:0;
    border-right:0;
    border-bottom-width: 1px;
    border-style: solid;

    &.dashed {
        border-style: dashed;
    }

    &.emphasized {
        border-bottom-width: 3px;
        margin: 60px 0;
    }

    &.strong-emphasized {
        border-bottom-width: 7px;
    }
}