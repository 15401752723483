//-------------------------------------------------------------
// Link icon
//-------------------------------------------------------------
$link-icon-icon-size: $font-size-default;

// General
.link-icon {
    display:         inline-block;
    color:           $link-icon-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        outline:             0;
        border-bottom-color: $link-icon-hover-color;
        color:               $link-icon-hover-color;
        text-decoration:     underline;
    }

    .teleicon {
        font-size: $link-icon-icon-size;
        padding-right: building-units(2 / 3);
    }
}

.link {
    &__icon-left {
        margin-right: 6px;
    }

    &__icon-right {
        margin-left: 6px;
    }
}

