//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------
// Variables

.image-box {
  margin: 0;

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  &__caption {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 21px;
    color: $images-caption-color;
    font-size: $font-size-text-small;
    line-height: $line-height-text-small;
  }
}
