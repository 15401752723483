//-------------------------------------------------------------
// Link List
//-------------------------------------------------------------

// General
.linklist {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: building-units(1);

    li {
        position: relative;
        display: flex;
        flex-flow: row;
        margin-bottom: building-units(1);
        padding-left: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            @extend %cadfemicon;
            content: "\36";
            position: absolute;
            top: 2px;
            left: 0;
            width: 18px;
        }
    }

    &--small {
        @extend %text-small;

        li:before {
            font-size: $font-size-text-small;
        }
    }

    &--large {
        @extend %text-large;

        li:before {
            font-size: $font-size-text-large;
        }
    }

    a {
        color: $cadfem-darkblue;
        text-decoration: none;

        &:hover {
            color: $cadfem-darkblue;
            text-decoration: underline;
        }
    }
}
.card_cf--bg-secondary > .linklist > li > a:hover {
    color: $cadfem-purewhite;
}
