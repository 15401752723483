// Frame helper classes. Move to spacing helpers

$spacing-helpers: (
  tiny: building-units(1),
  extra-small: building-units(2),
  small: building-units(4),
  medium: building-units(6),
  large: building-units(8),
  extra-large: building-units(10)
) !default;

@each $size, $value in $spacing-helpers {
    .frame-space-before-#{$size} {
        margin-top:  $value;
    }

    .frame-space-after-#{$size} {
        margin-bottom:  $value;
    }
}