.price {
    color: $cadfem-darkblue;

    &__number {
        font-size:   rem($font-size-h1-l);
        font-weight: $font-weight-bold;
        line-height: $line-height-h1-l;
        letter-spacing: 2.4px;
    }

    &__betterment_text {
        @extend %text-small;
    }

    &__discount {
        margin-left: building-units(1);
        color: $cadfem-pinegreen;
    }

    &__top {
        color: $cadfem-ashgray;
        @extend %text-large;
        text-transform: none;
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
    }

    &__bottom {
        display: flex;
        flex-flow: row;
        flex-direction: column;
    }

    &__crossed-out > div.price-box > span.price {
        position: relative;
        color: $cadfem-ashgray !important;
        font-size: 15px !important;
        line-height: 15px;

        &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 1px solid;
            border-color: $cadfem-ashgray;

            -webkit-transform: rotate(-5deg);
            -moz-transform: rotate(-5deg);
            -ms-transform: rotate(-5deg);
            -o-transform: rotate(-5deg);
            transform: rotate(-5deg);
        }
    }

    &__label {
        @extend %text-large;
        text-transform: none;
        font-weight: $font-weight-semibold;
        color: $cadfem-darkblue;
        line-height: $line-height-h1-l;
        margin-right: building-units(0.5);
    }

    &__tax {
        font-size: $font-size-text-small;
        line-height: $line-height-text-small;
        color: $cadfem-darkblue;
        font-weight: $font-weight-base;
        text-transform: none;
        margin-bottom: 0;
        letter-spacing: 0;
        white-space: nowrap;
    }

    &--discount {
        color: $cadfem-pinegreen;
    }
}

form.form-cart .price__bottom {
    text-align: right;

    .price__number {
        text-align: right;
        width: 100%;
    }
}
