//-------------------------------------------------------------
//Action Items
//-------------------------------------------------------------

// Variables

// General
.product-switch {
    &__button:not(:first-child) {
        margin-left: building-units(1);
        width: 24px;
        height: 24px;
    }

    &--active {
        > .cadfemicon {
            color: $cadfem-darkblue !important;
        }

        .icon-tile:before,
        .icon-grid:before {
            content: "\5b" !important;
        }

        .icon-list:before {
            content: "\22" !important;
        }
    }

    .cadfemicon {
        font-size: rem(24px);
        color: $cadfem-cornflower;

        &:hover {
            color: $cadfem-denimblue;
        }

        &:active {
            color: $cadfem-darkblue;
        }

        &.icon-tile:before,
        &.icon-grid:before {
            @extend .icon-tile-view-inactive;
        }

        &.icon-list:before {
            @extend .icon-list-view-inactive;
        }
    }
}
