$avatar-width: rem(62px);

.comments {
    list-style: none;

    &__form {
        margin: rem(12px) 0;

        flex: 1 100%;
        @include media-breakpoint-up(md) {
            flex: 1 calc(100% - #{$avatar-width});
        }
    }

    &__thread {
        margin-top: rem(1px);
        list-style: none;
        padding-left: 0;
        font-size: $font-size-base;
    }

    &__add-comment,
    &__submit-button {
        margin-bottom: rem(12px);
    }
}

.comment {
    display: flex;
    flex-flow: row wrap;

    &__avatar {
        display: none;
        padding-right: rem(12px);

        @include media-breakpoint-up(md) {
            display: block;
            flex: 0 $avatar-width;

        }
    }

    &__main {
        flex: 1 100%;
        border-top: 1px solid $gray-184;
        border-bottom: 1px solid $gray-184;
        padding: rem(18px) rem(12px);
        margin-top: -1px;

        @include media-breakpoint-up(md) {
            flex: 1 calc(100% - #{$avatar-width});
        }

        &.comment--done {
            padding-left: rem(9px);
            border-left: rem(3px) solid $green;
        }
    }

    &__head {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
    }

    &__actions {
        margin-left: auto;
    }

    &__author {
        font-weight: bold;
        display: inline-block;
        padding-right: rem(6px);
    }

    &__date {
        font-size: rem(15px);
        color: $comment-date-color;
    }

    &__tasks {
        .btn {
            padding-left: 0;
            padding-right: 0;
        }

        // .teleicon {
        //     margin-right: rem(6px);
        // }
    }

    &__child {
        flex: 1 0 100%;
        padding-left: rem(6px);
        list-style: none;
        font-size: $font-size-base;
        // padding-bottom: rem(18px);
        // align-self: flex-end;

        @include media-breakpoint-up(md) {
            padding-left: rem(36px);
        }
    }
    &__loading {
        margin: rem(12px) 0;
    }
}
