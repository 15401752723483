//-------------------------------------------------------------
// Link List
//-------------------------------------------------------------

// Variables
$link-list-line-height: building-units(2);

// General
.link-list {
    list-style-type: none;
    padding:         0;
    margin:          0 0 building-units(2);

    li {
        padding:     building-units(1 / 3) 0;
        line-height: $link-list-line-height;
    }

    .link {
        display: block;
        color: $link-list-link-color;
    }
}
