//-------------------------------------------------------------
// Screenreader
//-------------------------------------------------------------

@mixin sr-only {
    position:    absolute;
    width:       1px;
    height:      1px;
    padding:     0;
    overflow:    hidden;
    clip:        rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path:   inset(50%);
    border:      0;
}

// Use in conjunction with .sr-only to only display content when it's focused.

@mixin sr-only-focusable {
    &:active,
    &:focus {
        position:    static;
        width:       auto;
        height:      auto;
        overflow:    visible;
        clip:        auto;
        white-space: normal;   
        clip-path:   none;
    }
}
