//-------------------------------------------------------------
// CARD
//-------------------------------------------------------------
// Variables
%card--padding {
  margin-left: building-units(1) + 2;
  margin-right: building-units(1) + 2;
}

.card {
  display: block;
  width: 100%;

  &__kicker {
    display: block;
    font-size: $font-size-text-small;
    line-height: $line-height-base;
    color: $card-kicker-text-color;

    margin-bottom: building-units(1);
    margin-top: rem(14px);
    @extend %card--padding;
  }

  &__image {
    width: 100%;
  }

  &__title {
    display: block;
    letter-spacing: 0;
    line-height: $line-height-base;
    margin-top: 12px;
    margin-bottom: 12px;
    color: $card-title-text-color;
    font-size: 21px;

    @extend %card--padding;
  }

  &__text {
    @extend %card--padding;

    > p:first-child {
      margin-top: 0;
    }
  }

  &__content {
    padding: 0 rem(14px);
  }

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.30);
  }

  &--bg-default {
    background-color: $card-default-background-color;
    border: 1px solid $card-default-border-color;
    color: $card-default-text-color;
  }

  &--bg-primary {
    background-color: $card-primary-background-color;
    border: 1px solid $card-primary-border-color;
    color: $card-primary-text-color;

    .card__kicker, .card__title, .card-footer__timestamp, .card-footer__icon .teleicon {
      color: $card-primary-teleicon-color;
    }
  }

  &--bg-dark-grey {
    background-color: $card-dark-grey-background-color;
    border: 1px solid $card-dark-grey-border-color;
    color: $card-dark-grey-text-color;

    .card__kicker, .card__title, .card-footer__timestamp, .card-footer__icon .teleicon {
      color: $card-dark-grey-icon-color;
    }
  }
}

.card-footer {
  margin-top: building-units(1.5);
  padding: building-units(1.5) building-units(1) + 2;
  border-top: 1px solid $card-footer-border-color;
  display: flex;
  min-height: 60px;

  &__timestamp {
    font-size: $font-size-text-small;
    color: $card-footer-timestamp-color;
    line-height: $line-height-base;
    display: inline-block;
    min-width: 120px;
  }

  &__icon__wrapper {
    text-align: right;
    margin: 0 auto;
    width: 100%;
  }

  &__icon {
    margin-left: building-units(2);
    line-height: $line-height-base;

    .teleicon {
      font-size: 18px;
      color: $card-footer-teleicon-color;
    }
  }
}