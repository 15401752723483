//-------------------------------------------------------------
// Lists
//-------------------------------------------------------------

ol,
ul,
dl {
    @extend %text-default;
}

.list {
    &__download > li {
        list-style-type: none;
        position: relative;

        &:before {
            @extend %cadfemicon;
            content: '\64';
            font-size: rem(18px) !important;
            position: absolute !important;
            left: rem(-20px);
            top: 0;
            width: rem(20px);
            text-align: center;
        }
    }

    &--small {
        li, dt, dd {
            font-size:  $font-size-text-small;
            line-height: $line-height-text-small;
        }
    }

    &--large {
        li, dt, dd {
            font-size: 20px;
            line-height: 29px;
        }
    }
}

.list-square-l > li {
    margin-bottom:  rem(12px);

    span {
        font-size: rem(building-units(2)-2);
        line-height: rem(building-units(2)-2);
    }
}

dt {
    display: list-item;
    list-style-type: square;
    font-weight: $font-weight-semibold;
}

dd {
    margin: 0 0 rem(12px);
}

//-------------------------------------------------------------
// global stylings
//-------------------------------------------------------------
.list--style {
    &-none {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-left: 0;
        list-style-type: none;

        li {
            padding-left: 0 !important;
        }

        li:before {
            content: '' !important;
            margin-right: 0;
        }
    }

    &-disc {
        list-style-type: disc !important;
    }

    &-circle {
        list-style-type: circle !important;
    }
}
