//-------------------------------------------------------------
// Tabbar: list
//-------------------------------------------------------------

$tabbar-list-margin: 0 building-units(2.5);
$tabbar-list-padding: 0;
$tabbar-list-height: auto;

$tabbar-list-color: $cadfem-cornflower;
$tabbar-list-color-hover: $cadfem-darkblue;
$tabbar-list-color-active: $cadfem-darkblue;
$tabbar-list-color-inactive: $cadfem-ashgray;

$tabbar-list-br-color: transparent;
$tabbar-list-br-color-hover: $cadfem-cornflower;
$tabbar-list-br-color-active: $cadfem-darkblue;

.tabbar-list {
    display:         flex;
    height:          $tabbar-list-height;
    justify-content: center;
    list-style:      none;
    margin:          0;
    padding:         0;
    transition:      all .2s ease;
    white-space:     nowrap;
    z-index:         1;
    position:        relative;
    float:           left;

    .tabbar-container:not(.show-controls) & {
        width: 100% !important;
    }

    li {
        display:            flex;
        flex-flow:          column-reverse;
        padding:            $tabbar-list-padding;
        color:              $tabbar-list-color;

        > a {
          text-decoration:     none;
          outline:             0;
          color:               $tabbar-list-color;
        }

        &:before {
            display:            block;
            content:            "";
            position:           relative;
            width:              100%;
            border-bottom:      3px solid $tabbar-list-br-color;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            text-decoration:     none;
            outline:             0;
            cursor:              pointer;

            > a {
                text-decoration:   none;
            }
        }

        &:hover:before {
            border-color: $tabbar-list-br-color-hover;
        }

        &.active {
            > a {
                text-decoration:   none;
                outline:           0;
                color:             $tabbar-list-color-active;
            }

            &:before {
                border-color: $tabbar-list-br-color-active;
            }
        }

        &.inactive {
            > a {
                cursor:            no-drop;
                color:             $tabbar-list-color-inactive;
            }
        }

        &.inactive:hover:before {
            border-color: $cadfem-steamgray;
        }
    }
}
