%cookie-banner {
    position: fixed;
    padding: rem(30px) 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    background: $white;
    z-index: 1002;
}

.cookie-banner {
    @extend %cookie-banner;

    p {
        margin: 0 0 rem(12px);

        + .btn {
            margin-top: rem(18px);
        }
    }

    &__container {
        position: relative;
        padding-right: rem(60px);
    }

    &__close {
        display: inline-block;
        position: absolute;
        top: rem(-10px);
        right: rem(10px);
        padding: rem(10px);
        height: rem(40px);
    }

    // CC specific Styling
    &.cc-invisible {
        display: none;
    }

    &.cc-theme-dark {
        background: $gray-56;
        color: $white;

        a {
            color: $white;
        }
    }
}

.cc-window {
    @extend %cookie-banner;
    &.cc-invisible {
        display: none;
    }
}

.cc-theme-light {
    background: $white;
}

.cc-theme-dark {
    background: $cadfem-darkblue; //$gray-56;
    color: $cadfem-purewhite;
    padding: building-units(3) building-units(3) building-units(3) building-units(1);
    box-shadow: 0 -5px 24px rgba($black, 0.25);

    a {
        color: $white;
    }
}

.cc-message {
    @extend .text-default;
    display: block;
}

.cc-close {
    position: absolute;
    top: -32px;
    right: 0;
    cursor: pointer;
}

.cc-dismiss {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--small;

    display: inline-flex;
}

.cc-top {
    bottom: auto;
    top: 0;
}
