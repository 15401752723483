//-------------------------------------------------------------
// Footer link
//-------------------------------------------------------------

.footer-info {
  margin-top: 12px;

  .footer-link {
    color: $footer-link-color;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: $footer-link-hover-color;
    }
  }
}

.footer-doormat {
  border-bottom: 1px solid $black;
}