//-------------------------------------------------------------
// Tiles
//-------------------------------------------------------------

// Variables
$tile-min-height: 165px;

// // General
// .tiles-container {
//     @media all and (min-width: $screen-md) {
//         display:         flex;
//         justify-content: stretch;

//         > [class*='col-'] { flex: 0 0 auto; }
//     }
// }

// .tile {
//     display:         flex;
//     flex-direction:  column;
//     justify-content: space-between;
//     background:      $tile-background-color;
//     min-height:      $tile-min-height;
//     height:          100%;
//     padding:         building-units(1.5);
//     margin-bottom:   building-units(2);
// }

// .tile-header {
//     display: table;

//     > p { margin: 0; }
// }

// .tile-headline {
//     font-weight: $font-weight-semibold;
//     margin:         0;
//     text-transform: uppercase;
// }

// .tile-content { display: table; }

//

.tile {
  background: $tile-background-color;
  border: 1px solid $tile-border-color;
  min-height: $tile-min-height;
  height: 100%;
  color: $tile-text-color;

  &__header {
    background: $tile-header-background-color;
    padding: building-units(1.5) building-units(1);
    display: flex;
    justify-content: space-between;

    &-side {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      margin-left: auto;
    }

    &-title {
      font-size: building-units(1.75);
      line-height: building-units(1.2);
      font-weight: $font-weight-semibold;
    }
  }

  &__header-link {
    color: $gray-56;
    margin-right: building-units(1);
    border-right: 1px solid $gray-208;
    padding-right: building-units(1);
  }

  &__reference-icon::before {
    height: building-units(1.5);
    width: building-units(1.5);
  }

  &__body {
    // padding: building-units(3) building-units(1);
  }
}
