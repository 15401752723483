//-------------------------------------------------------------
// Footer
//-------------------------------------------------------------

// Variables
$font-size-footer: 16px;
//$line-height-footer: 16px;
$line-height-footer: 18px;

.footer {
  background-color: $footer-background-color;
  color: $footer-text-color;
  font-size: $font-size-footer;
  line-height: $line-height-footer;
  padding: building-units(1.5) 0;

  .footer-social {
    border-bottom: 1px solid $black;

    .customer-logo {
      img {
        width: 175px;
        height: 61px;
        margin-top: 18px;
        display: none;
        @media screen and (min-width: $screen-sm) {
          display: block;
        }
      }
    }
    a.customer-logo.customer-footer-logo-right{
      float: right;
    }
    .section-header {
      text-align: center;
      margin-top: 13px;
      font-weight: normal;
    }
    .social-icons {
      text-align: center;
      margin-bottom: 20px;
      .social {
        text-decoration: none;
        &:nth-child(1), &:nth-child(2) {
          margin-right: 26px;
        }
        &:hover img {
          transform: scale(1.3);}
      }
    }
  }

  .footer-info {

    .logo-wrapper {
      display: flex;   
    }

    &__copyright {
      display: flex;
      align-items: center;

    }
  }
}

@import "footer-link";
@import "footer-navigation";
@import "footer-copyright";
@import "link-list";