//-------------------------------------------------------------
// image-collage
//-------------------------------------------------------------
// Variables

.image-collage {
    width: 100%;

    &__wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    &__left {
        margin-right: 1px;
        width: calc((100% / 3 * 2));
    }

    &__right {
        margin-left: 1px;
        margin-right: 0;
        width: calc((100% / 3 * 1));
    }

    &__caption {
        @extend .text-small;
        margin-top: 20px;
        color: $cadfem-darkgray;
    }

    /**
     * XS Viewports
     */
    @include media-breakpoint-only(xs) {
        .image-collage__wrapper {
            flex-flow: column;
        }

        .image-collage__left {
            margin-right: 0;
            width: 100%;
        }

        .image-collage__right {
            display: none;
        }
    }

    .img_content-box {
        margin-bottom: 0;
    }

    .img_content-box__image {
        border-radius: 0;
    }
}
