//-------------------------------------------------------------
// Radiobutton
//-------------------------------------------------------------

// Variables
$radiobutton-size: building-units(2);
$radiobutton-br-radius: 100%;
$radiobutton-icon-size: 20px;
$radiobutton-shadow: $form-elements-box-shadow;

$radiobutton-bg-color: $gray-237;
$radiobutton-br-color: $gray-178;
$radiobutton-bg-color-hover: $gray-220;
$radiobutton-bg-color-active: $gray-199;
$radiobutton-br-color-focus: $blue;
$radiobutton-shadow-focus: $radiobutton-shadow $blue;
$radiobutton-icon-color: $cadfem-darkblue;
$radiobutton-color-hover: $cadfem-denimblue;

$radiobutton-bg-color-disabled: $gray-248;
$radiobutton-br-color-disabled: $gray-220;
$radiobutton-icon-color-disabled: $cadfem-ashgray;

$radiobutton-bg-color-error: rgba($cadfem-chesnut, .05);
$radiobutton-br-color-error: $cadfem-chesnut;
$radiobutton-bg-color-error-hover: $cadfem-chesnut;
$radiobutton-br-color-error-focus: $cadfem-chesnut;
$radiobutton-shadow-error-focus: $radiobutton-shadow $cadfem-chesnut;
$radiobutton-icon-color-error: $cadfem-chesnut;

// General styles
.form-radiobutton-set {
    margin-bottom:  25px;
    position:       relative;

    &.has-error .validation-message {
        padding-left: building-units(1);
    }

    &:last-child {
        margin-bottom: 0;
    }
}
.form-radiobutton-group {
    margin: building-units(2) 0;

    &.has-error .validation-message {
        font-weight: $font-weight-semibold;
        margin-bottom: 10px;
    }
}

.form-radiobutton {
    position: absolute;
    top:      0;
    left:     0;
    z-index:  1;
    opacity:  0;

    + label {
        font-size:      $font-size-default !important;
        line-height:    $line-height-default !important;
        font-weight:    $font-weight-regular;
        display:        inline-block;
        margin-bottom:  0; //override default label
        text-transform: none;

        &::before {
            @extend %cadfemicon;

            position:      absolute;
            top:           -2px;
            left:          0;
            height:        calc(#{$radiobutton-size} - 10px);
            width:         calc(#{$radiobutton-size} - 10px);
            font-size:     $radiobutton-icon-size !important;
            color:         $radiobutton-icon-color;
            margin-right:  5px;
            text-align:    center;
            transition:    opacity .2s ease-in-out;
        }

        &::before {
            content: "\4c";
        }


        &:hover {
            cursor: pointer;
            color:  $radiobutton-color-hover;
        }
    }

    &:checked {
        + label {
            &::before {
                color:          $radiobutton-color-hover;
                border-color:   $radiobutton-color-hover;
                content:        "\4b";
            }
        }
    }

    &:hover {
        cursor: pointer;

        + label {
            &::before {
                color:        $radiobutton-color-hover;
                border-color: $radiobutton-color-hover;
            }
        }
    }

    &:active {
        outline: 0;
    }

    // Disabled
    &:disabled {
        cursor: not-allowed;

        + label {
            cursor: not-allowed;
            color:        $radiobutton-icon-color-disabled;

            &::before {
                border-color: $radiobutton-br-color-disabled;
                color:        $radiobutton-icon-color-disabled;
            }
        }
    }

    // Error
    .has-error & {
        + label {
            color: $radiobutton-icon-color-error;
            &::before {
                background-color:   $radiobutton-bg-color-error;
                color:              $radiobutton-icon-color-error;
            }
        }

        &:hover {
            + label {
                &::before {
                    background-color: $radiobutton-bg-color-error;
                }
            }
        }
    }
}
