//-------------------------------------------------------------
// Links
//-------------------------------------------------------------

// Variables
$link-color:                    $cadfem-darkblue;
$link-color-hover:              $cadfem-cornflower;

$link-standalone-color:         $cadfem-cornflower;

$external-link-size-small:      8px;
$external-link-margin-small:    3px;
$external-link-size-default:    8px;
$external-link-margin-default:  4px;
$external-link-size-large:      10px;
$external-link-margin-large:    6px;

%link {
    display:         inline-block;
    color:           $link-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        outline:             0;
        color:               $link-color-hover;
    }
}

%externalLinkHover {
    animation:externalLinkAnimationHover 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-decoration: none;
}

@keyframes externalLinkAnimationHoverOut {
    from { top: -3px; right: -6px; }
    to { top: 0; right: -3px; }
}

%externalLinkHoverOut {
    animation:externalLinkAnimationHoverOut 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes internalLinkAnimationHover {
    from { text-indent: -15px; }
    to { text-indent: -2px; }
}

// custom animations
@keyframes externalLinkAnimationHover {
    from { top: 0; right: -3px; }
    to { top: -3px; right: -6px; }
}

%internalLinkHover {
    animation:internalLinkAnimationHover 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes internalLinkAnimationHoverOut {
    from { text-indent: -2px; }
    to { text-indent: -15px; }
}

%internalLinkHoverOut {
    animation:internalLinkAnimationHoverOut 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode:forwards;
}

a {
    @extend %link;

    &[id^=c]:empty {
        display: inherit !important;
    }
}

// General
.link {
    @extend %link;
    text-decoration: underline;

    &__icon {
        margin-right: 5px;
    }

    &--intern:after,
    &--extern:after {
        @extend %cadfemicon;
    }

    &--extern {
        position: relative;
        margin-right: 6px;

        &:after {
            @extend %externalLinkHoverOut;

            content: '\67';
            margin-top: -20px;
            margin-left: -5px !important;
            text-decoration: underline; // Bug: Required for IE11
            //
            // https://stackoverflow.com/questions/27018420/how-to-remove-an-underline-on-a-pseudo-element/32120247#32120247
            // https://codepen.io/kmuncie/pen/mKJMxa
        }

        &:hover:after {
            @extend %externalLinkHover;
        }
    }

    &--intern {
        text-decoration: none;

        &:after {
            @extend %internalLinkHoverOut;
            content: '\66';

            top: 0;
            right: 0;
            text-indent: -15px;
            overflow: hidden;
        }

        &:hover:after {
            @extend %internalLinkHover;
        }
    }

    &--extern:after {
        width:          $external-link-size-default;
        height:         $external-link-size-default;
        margin-left:    $external-link-margin-default;
        text-decoration: none;
    }

    &--intern:after {
        width:          70px;
        height:         20px;
        margin-left:    10px;
        line-height:    18px !important;
    }

    &--standalone {
        @extend .text-small;
        color: $link-standalone-color;
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.2px;
    }

    &--standalone,
    &--special {
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

            span.cadfemicon {
                text-decoration: none;
            }
        }
    }

    &--intern.link--standalone:hover {
        text-decoration: none;
    }

    //special links have no underline
    &--special:hover {
        text-decoration: none;
    }

    &--file,
    &--thumbnail {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        text-decoration: none;
        color: $cadfem-cornflower;
    }

    > .cadfemicon {
        margin-right: 5px;
    }
}
.card_cf--bg-secondary > .link.link--standalone {
    color: $link-standalone-color;
}

.link--file {
    > .link__wrapper span {
        display: block;
        word-break: break-word;
    }

    //filelink with a thumbnail
    .link__image {
        margin-right: building-units(1);
        position: relative;
        min-width: building-units(10);
        width: building-units(10);
        height: building-units(10);
        display: block;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            min-width:  building-units(6);
            width:  building-units(6);
            height: building-units(6);
        }

        .link__icon {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;

            width: building-units(2);
            height: building-units(2);
            background-color: $cadfem-cornflower;
            margin: 0;

            &:before {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                line-height: 30px;
                color: $cadfem-purewhite;
            }
        }

        &:hover .link__icon {
            background-color: $cadfem-denimblue;
        }
    }

    .link__icon {
        color: $cadfem-cornflower;
    }

    .link__filename {
        color: $cadfem-cornflower;
        display: block;
    }

    .link__description {
        color: $cadfem-darkblue;
        line-height: 20px;
    }

    &:hover {
        text-decoration: none !important;

        .link__filename {
            text-decoration: underline;
            color: $cadfem-cornflower;
        }

        .link__description {
            color: $cadfem-darkblue;
        }
    }
}

.text-small .link--extern:after {
    width:          $external-link-size-small;
    height:         $external-link-size-small;
    margin-left:    $external-link-margin-small;
}

.text-default .link--extern:after {
    width:          $external-link-size-default;
    height:         $external-link-size-default;
    margin-left:    $external-link-margin-default;
}

.text-large .link--extern:after {
    width:          $external-link-size-large;
    height:         $external-link-size-large;
    margin-left:    $external-link-margin-large;
}

.no-underline {
    text-decoration: none !important;
}
