//-------------------------------------------------------------
// Body Copy Text
//-------------------------------------------------------------

// Mixins and Extend Classes

%text-default {
    font-size:   rem($font-size-default);
    line-height: rem($line-height-default);
    margin-bottom: 25px;
}

%text-x-large {
    font-size:   rem($font-size-text-x-large);
    line-height: $line-height-text-x-large;
    font-weight: $font-weight-light;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
        font-size:   rem($font-size-text-x-large-md);
        line-height: $line-height-text-x-large-md;
        margin-bottom: 50px;
    }
}

%text-large {
    font-size:   rem($font-size-text-large);
    line-height: $line-height-text-large;
    margin-bottom: building-units(2);

    @include media-breakpoint-down(md) {
        font-size:   rem($font-size-default);
        line-height: rem($line-height-default);
    }
}

%text-small {
    font-size:   rem($font-size-text-small);
    line-height: $line-height-text-small;
    margin-bottom: 20px;
}

%text-x-small {
    font-size:   rem($font-size-text-x-small);
    line-height: $line-height-text-x-small;
    margin-bottom: building-units(1);
}

%text-center {
    text-align: center;
}

%text-right {
    text-align: right;
}

%text-bold {
    font-weight: $font-weight-semibold;
}

%text-italic {
    font-style: italic;
}

%text-underline {
    text-decoration: underline !important;
}

// Styles
p,
.text-default,
.text-normal {
    font-size:   rem($font-size-default);
    line-height: rem($line-height-default);
    margin-bottom: 25px;
}

.text-x-large {
    @extend %text-x-large;
}

.text-large {
    @extend %text-large;
}

.text-small {
    @extend %text-small;
}

.text-x-small {
    @extend %text-x-small;
}

.text-center {
    @extend %text-center;
}

.text-right {
    @extend %text-right;
}

.text-bold {
    @extend %text-bold;
}

.text-italic {
    @extend %text-italic;
}

.text-underline {
    @extend %text-underline;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text-center#{$infix} {
            text-align: center;
        }

        .text-right#{$infix} {
            text-align: right;
        }

        .text-left#{$infix} {
            text-align: left;
        }
    }
}
