//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------
// Variables

$teaser-magenta-background-color: $magenta;
$teaser-magenta-hover-background-color: $magenta-160;
$teaser-grey-background-color: $gray-56;
$teaser-grey-hover-background-color: $black;

/*****************************************
 * teaser
 *****************************************/
.teaser {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    margin-bottom: building-units(1);

    display: flex;
    flex-flow: column;
    justify-content: stretch;
    flex: 1 1 auto;

    &__date {
        @extend %text-small;
        font-weight: $font-weight-semibold;
        color: $cadfem-cornflower;
        margin-bottom: 5px;
        display: block;
    }

    &__image {
        border-radius: 8px;
        position: relative;

        > picture {
            display: block;
            position: relative;
            overflow: hidden;
            width: 100%;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
    }

    &__badge {
        padding: 5px 15px;
        position: absolute;
        height: 30px;
        line-height: 20px;
        font-size: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        bottom: -15px;
        right: 25px;
        background-color: $cadfem-cornflower;
        color: $cadfem-purewhite;
    }

    &__skyline {
        display: block;
        font-size: $font-size-text-small;
        line-height: $line-height-text-small;
        font-weight: $font-weight-bold;
        color: $cadfem-darkblue;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        margin-bottom: 20px;
    }

    &__header {
        display: block;
        font-size: $font-size-text-large;
        line-height: 25px;
        letter-spacing: 0.5px;
        font-weight: $font-weight-bold;
        color: $cadfem-darkblue;
        margin-bottom: building-units(1);

        &:hover {
            color: $cadfem-darkblue;
        }
    }

    &__content {
        position: relative;
        padding: rem(20px) rem(25px) rem(25px) rem(25px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: space-between;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    &__link-wrapper {
        flex-grow: 1; //align bottom
    }

    &__text {
        @extend %text-default;
        color: $cadfem-darkblue;
        margin-bottom: building-units(1);
    }

    &__link-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        text-decoration: none;
    }

    &__link-label,
    .link {
        font-size: rem($font-size-text-small);
        line-height: $line-height-text-small;
        color: $cadfem-cornflower;
    }

    &--bg-white {
        background-color: $white !important;;
    }

    &--bg-iceblue {
        background-color: $cadfem-iceblue !important;
    }

    &--shadow {
        box-shadow: $shadow;
    }

    &--outline {
        border: 1px solid $cadfem-steamgray;
    }

    &__company {
        &-logo {
            max-width: 210px;
            max-height: 80px;
            height: auto;
            min-height: 50px;
            width: auto;
            margin-bottom: 15px;
        }

        &-name {
            @extend %text-small;
            margin-bottom: 5px;
            color: $cadfem-darkblue;
        }
    }
}

a:hover .teaser div.link {
    color: $cadfem-denimblue;
    text-decoration: underline;
}

/*****************************************
 * news teaser
 *****************************************/
.teaser__news {
    border-radius: 8px;
    margin-bottom: building-units(0);

    .teaser__img,
    .img_content-box__img {
        @extend %imageTransitionHoverOut;
    }

    .img_content-box {
        margin-bottom: 0;
    }

    .img_content-box__image {
        border-radius: 8px 8px 0 0;
    }
}

a:hover > .teaser__news .link--intern:after {
    @extend %internalLinkHover;
}

a:hover > .teaser__news .teaser__link-label {
    text-decoration: underline;
}

/*****************************************
 * teaser--text-image
 *****************************************/
.teaser--text-image,
.teaser--project {
    position: relative;
    border-radius: 8px;

    .teaser__image {
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
    }

    .teaser__img,
    .img_content-box__img {
        @extend %imageTransitionHoverOut;
    }

    .link--standalone {
        margin-bottom: 0;
    }

    .img_content-box {
        margin-bottom: 0;
    }

    .img_content-box__image {
        border-radius: 8px 8px 0 0;
    }
}

.teaser--text-image {
    margin-bottom: building-units(0);
}

/** CADFEMEB-2506 **/
.teaser--text-image:not(.teaser--overlay) .teaser__image.lazyloaded {
    background-image: none !important;
}

a:hover > .teaser--project .link--intern:after,
a:hover > .teaser--text-image .link--intern:after {
    @extend %internalLinkHover;
}

a:hover .teaser--project .teaser__img,
a:hover .teaser--project .img_content-box__img,
a:hover .teaser--text-image .teaser__img,
a:hover .teaser--text-image .img_content-box__img,
a:hover .teaser__news .teaser__img,
a:hover .teaser__news .img_content-box__img {
    @extend %imageTransitionHover;
}

/*****************************************
 * subteaser from teaser--text-image
 *****************************************/
.teaser--overlay {

    .teaser__header,
    .teaser__text,
    .teaser__skyline,
    .link {
        color: $white;
    }

    .teaser__content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
    }

    .teaser__image {
        @extend %imageTransitionHoverOut;
        background-size: cover;
        background-position: center center;
        overflow: hidden;
    }

    .teaser__img {
        visibility: hidden;
    }

    @include media-breakpoint-only(xs) {
        .teaser__text {
            display: none;
        }
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($cadfem-darkblue, 0.7);
    }

    &:hover .teaser__image {
        @extend %imageTransitionHover;
    }
}

a:hover > .teaser--overlay .link--intern:after {
    @extend %internalLinkHover;
}

a:hover .teaser__subline {
    color: $cadfem-darkblue;
}

//text-image-teaser
.teaser--offset-overlay {
    overflow: inherit;

    .teaser__subline {
        @extend %text-small;
        display: block;
        margin-bottom: 5px;
    }

    .teaser__header {
        @extend %h3;
        margin-bottom: building-units(1);
    }

    .teaser__image {
        position: relative;
        right: 0;
        top: 0;
        border-radius: 8px;
        margin-left: 15%;
        height: 100%;
        display: inherit;

        > a {
            width: 100%;
        }
    }

    .teaser__img {
        border-radius: 8px;
        width: 100%;

        @include media-breakpoint-down(sm) {
            border-radius: 8px 8px 0 0;
        }
    }

    .teaser__content {
        position: relative;
        width: 85%;
        margin-top: -35%;
        margin-right: 15%;
        border-radius: 8px;
        min-height: 300px;
        background-color: $white;
        @include shadow();

        @include media-breakpoint-down(sm) {
            border-radius: 0 0 8px 8px;
            min-height: auto;
            width: 100%;
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;

        .teaser__image,
        .teaser__content {
            position: inherit;
            margin: 0;
        }
    }
}

a:hover .teaser--offset-overlay .link--intern:after {
    @extend %internalLinkHover;
}

/*****************************************
 * teaser--editorial
 *****************************************/
.teaser--editorial {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid $cadfem-iceblue;

    @include media-breakpoint-up(md) {
        height: 480px;
    }

    @include media-breakpoint-up(lg) {
        height: 530px;
    }

    @include media-breakpoint-up(xl) {
        height: 550px;
    }

    &.teaser--bg-iceblue {
        border-bottom: 1px solid $cadfem-purewhite;
    }

    //list
    &-wrapper {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border: 1px solid $cadfem-steamgray;

        > li {
            width: 50%;
            @include media-breakpoint-up(md) {
                max-height: 480px;
            }

            @include media-breakpoint-up(lg) {
                max-height: 530px;
            }

            @include media-breakpoint-up(xl) {
                max-height: 550px;
            }

            > a:hover {
                .teaser__kicker {
                    color: $cadfem-darkblue;
                }

                .teaser__header {
                    color: $cadfem-cornflower;
                }
            }

            &:nth-child(odd) .teaser--editorial.teaser--bg-white {
                @include media-breakpoint-up(md) {
                    border-right: 1px solid $cadfem-iceblue;
                    border-bottom: 1px solid $cadfem-iceblue;
                }
            }

            &:last-child .teaser--editorial,
            &:nth-last-child(2) .teaser--editorial {
                border-bottom: none !important;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .teaser__kicker {
        @extend %text-small;
        display: block;
        margin-bottom: building-units(1);
        text-transform: uppercase;
    }

    .teaser__header {
        @extend %h3;
        margin-bottom: building-units(1);
    }

    .teaser__img {
        border-radius: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .teaser__content {
        justify-content: space-between;
        padding: building-units(3) building-units(2);
        height: auto;

        @include media-breakpoint-only(sm) {
            padding: building-units(2) 20px;
        }

        @include media-breakpoint-only(xs) {
            padding: building-units(1);
        }
    }

    .teaser__text, .teaser__text p {
        font-size: rem($font-size-default);
        line-height: rem($line-height-default);
        font-weight: $font-weight-medium;

        p {
            margin-bottom: 15px;
        }

        @include media-breakpoint-only(md) {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }

        @include media-breakpoint-only(xs) {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }
    }

    .link {
        @extend %text-default;
        line-height: 1; // fixes jumping in IE 11
        text-decoration: none;
    }

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 100%;
    }

    .icon-wrapper span.cadfemicon {
        font-size: 30px;
        color: $cadfem-cornflower;
        background-color: rgba($cadfem-purewhite, 0.5);
        border-radius: 50%;

        -webkit-transition: font-size 1s;
        -moz-transition: font-size 1s;
        -o-transition: font-size 1s;
        transition: font-size 1s;
    }

    &:hover .icon-wrapper span.cadfemicon {
        font-size: 45px;
        text-decoration: none;
    }

    .link {
        color: $cadfem-cornflower;
    }

    &:hover .link {
        text-decoration: underline;
        color: $cadfem-cornflower;
    }

    &.teaser--image-position--left .icon-wrapper,
    &.teaser--image-position--right .icon-wrapper {

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }
    }
}

a:hover .teaser--grid {
    cursor: default;
}

a:hover .teaser--editorial .teaser__header {
    color: $cadfem-denimblue;
}

a:hover .teaser--editorial div.link {
    color: $cadfem-cornflower;
}

.teaser--editorial-wrapper li .teaser--no-image {

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
    }

    .teaser__content {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
    }
}

.teaser--image-position--left,
.teaser--image-position--right {
    flex-flow: row wrap;

    @include media-breakpoint-up(sm) {
        flex-flow: row nowrap;
    }

    @include media-breakpoint-only(xs) {
        .teaser__header,
        .teaser__kicker,
        .teaser__content,
        .link {
            text-align: center;
        }
    }

    .teaser__content {
        flex: 1 1 auto;
        min-width: 60%;
        order: 0;

        @include media-breakpoint-down(xs) {
            min-width: 100%;
            flex: 1 1 auto;
        }
    }

    .teaser__img,
    .img_content-box,
    .img_content-box__image {
        width: auto;
        overflow: hidden;
        order: 1;
        flex: 1 1 auto;
        min-width: 40%;

        img {
            max-width: inherit;
            @include media-breakpoint-up(md) {
                height: 100%;
                width: auto;
            }
        }
    }

    .img_content-box,
    .img_content-box__image {
        height: 100%;
        margin-bottom: 0;
    }

    .img_content-box__image {
        border-radius: 0;
        max-width: inherit;
    }
}

.teaser--image-position--right {
    @include media-breakpoint-up(sm) {
        flex-flow: row nowrap;
    }
}

.teaser--image-position--left {
    @include media-breakpoint-up(sm) {
        flex-flow: row-reverse nowrap;
    }
}

.teaser--image-position--below {
    flex-flow: row wrap;
    justify-content: space-between;

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
        width: 100%;
    }

    .teaser__content {
        order: 0;
        flex: 1 1 auto;
    }

    .teaser__img {
        flex: 1 1 auto;
        order: 1;
        width: 100%;
    }
}

// @ToDo determine which of these is correct and remove redundant
.teaser--image-position--above,
.teaser--image-position--top {
    flex-flow: row wrap;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        flex-flow: row wrap-reverse;

        .teaser__content {
            order: 0;
        }

        .teaser__img {
            order: 1;
        }
    }

    .teaser__header,
    .teaser__kicker,
    .teaser__content {
        text-align: center;
        width: 100%;
    }


    .teaser__content {
        flex: 1 1 auto;
    }

    .teaser__img {
        flex: 1 1 auto;
    }
}

.teaser--project {
    .teaser__logo {
        position: absolute;
        left: 20px;
        top: -70px;
        padding: 5px;
        background-color: rgba($cadfem-purewhite, 0.8);
        max-width: 225px;
        max-height: 50px;
        width: auto;
        height: 100%;
    }

    .teaser__kicker {
        @extend %text-small;
        margin-bottom: 0;
    }
}

a:hover > .teaser--project .teaser__kicker {
    color: $cadfem-darkblue;
}

/*****************************************
 * teaser--video
 *****************************************/
$video-border-radius: 8px;
.teaser--video {
    margin-bottom: building-units(0);
    border-radius: $video-border-radius;

    &:hover {
        .teaser--locked:after,
        .teaser--play:after {
            color: $cadfem-cornflower;
        }

        .video-player__preview {
            @extend %imageTransitionHover;
        }
    }

    .video-embed {
        margin-bottom: 0 !important;
    }

    .teaser__skyline {
        .cadfemicon {
            font-size: 20px;
            margin-right: 5px;
        }

        .cadfem-plus {
            line-height: 20px;
            padding-right: 6px;
            margin-right: 1px;
        }

        .cadfem-plus.pipe-right {
            margin-right: 3px;
        }
    }

    .teaser--play figure.video {
        margin-bottom: 0;
    }

    .teaser--play figure.video:after {
        position: absolute;
        bottom: -25px;
        right: 25px;
        z-index: 8;
        font-size: 55px;
        padding-top: 13px;
        width: 55px;
        height: 40px;
        margin-left: -27.5px;
        background-color: $cadfem-purewhite;
        border-radius: 50%;
        font-family: 'cadfemicon';
        content: '\b3';
        color: $cadfem-darkblue;
    }
}

/**
 * CADFEMEB-3481
 */
div .news > div.row.news-list-view  > div[class^=col-],
div.research_module > div.row > div[class^=col-],
div.frame-type-gridelements_pi1 > div.row > div[class^=col-] {
    margin-bottom: building-units(2);

    @include media-breakpoint-down(sm) {
        margin-bottom: building-units(1);
    }
}

/* news row col verschachtelung */
div.frame-type-gridelements_pi1 > div.row > div[class^=col-] div.row > div[class^=col-] {
    margin-bottom: 0;
}

div.row > div[class^=col-] > div.frame.frame-type-shortcut,
div.row > div[class^=col-] > div.frame.frame-type-shortcut > div.frame.frame-type-mmscadfemebccintegration_textimage-teaser,
div.row > div[class^=col-] > div.frame.frame-type-shortcut > div.frame.frame-type-mmscadfemebccintegration_menu_teaser,
div.row > div[class^=col-] > div.frame.frame-type-list,
div.row > div[class^=col-] > div.frame.frame-type-mmscadfemebccintegration_textimage-teaser,
div.row > div[class^=col-] > div.frame.frame-type-mmscadfemebccintegration_menu_teaser,
div.row > div[class^=col-] > div.frame.frame-type-gridelements_pi1 {
    height: 100%;

    > a.no-underline {
        height: 100%;

        div.teaser--text-image,
        div.teaser__news,
        div.teaser--video,
        div.teaser--project,
        div.card_cf {
            height: 100%;
        }
    }

    > .news,
    > .news > .news-list-view,
    > .news > .news-list-view > .col-12 {
        height: 100%
    }

    div.card_cf {
        height: 100%;
    }
}

div.row > div[class^=col-] > div.frame.frame-type-mmscadfemebccintegration_menu_teaser {
    > div.row > div[class^=col-],
    > div.row {
        height: 100%;
    }
}

div.row > div[class^=col-] > a.no-underline {
    height: 100%;

    > div.teaser--project,
    > div.teaser--text-image,
    > div.teaser--video {
        height: 100%;
    }
}

div.row > div[class^=col-].js-Stickyincontainer,
div.row > div[class^=col-].no-alignment {
    height: inherit !important;

    > div {
        height: inherit !important;
    }

    div.row > div[class^=col-] {
        margin-bottom: building-units(1) !important;
    }

    a.no-underline {
        height: inherit !important;

        div.teaser--project,
        div.teaser--text-image,
        div.teaser--video,
        div.teaser__news,
        div.card_cf,
        .news,
        .news > .news-list-view,
        .news > .news-list-view > .col-12 {
            height: inherit !important;
        }
    }
}
