@mixin shadow() {
    box-shadow: $shadow;
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}