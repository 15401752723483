//-------------------------------------------------------------
// Tabbar: content
//-------------------------------------------------------------

.tabbar-content {
    > .tab-pane {
        display: none;
        padding-top: 15px;
    }

    > .active {
        display: block;
    }
}

.fade {
    opacity:    0;
    transition: opacity .15s linear;

    &.in {
        opacity: 1;
    }
}
