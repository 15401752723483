//-------------------------------------------------------------
// Teaser
//-------------------------------------------------------------
// Variables

.img_content-box {
    margin: 0 0 30px;

    //image wrapper to use a zoom effect
    &__image {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: 8px;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        &--outline {
            border: 1px solid $cadfem-steamgray;
            border-radius: 8px;
        }

        &:hover .link {
            background-color: $cadfem-denimblue;
        }
    }

    &--linked {
        width: 100%;

        .img_content-box__img:hover {
            transition: transform .8s ease-in, .8s ease-out;
            transform: scale(1.04);
        }

        .cadfemicon:before {
            content: "\4d"
        }
    }

    &--linked[target="_blank"] {
        .cadfemicon:before {
            content: "\4e"
        }
    }

    &__img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    &__caption {
        width: 100%;
        margin: 15px 0 30px 0;
        color: $cadfem-ashgray;
        font-size: $font-size-text-small;
        line-height: $line-height-text-small;
    }

    .link {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        background-color: $cadfem-cornflower;
        border-bottom-right-radius: 8px;

        .cadfemicon {
            color: $cadfem-purewhite;
            margin-left: 5px;
            margin-top: 6px;
        }
    }
}
