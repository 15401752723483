//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$top-navigation-border-color: $cadfem-steamgray;

.top-navigation {
    padding:            0 building-units(1);
    background-color:   $cadfem-purewhite;
    border-top:         1px solid $top-navigation-border-color;
    border-bottom:      1px solid $top-navigation-border-color;
    max-height:         40px;
    position:           relative;

    @include media-breakpoint-down(md) {
        border-top: none;
        border-bottom: none;
    }

    &__menu {
        display: flex;
    }

    &__left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        width: 40%;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__right {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 60%;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__item {
        color:          $cadfem-ashgray;
        font-size:      rem(13px);
        line-height:    38px;

        &:last-child { padding-right: 0; }

        //general overwrites for flyout in top navigation
        .flyout {
            position: absolute;
            z-index: 2;

            &--open {
                background-color: $white;
                box-shadow: 0 5px 24px 1px rgba(0,0,0,0.05);
            }
        }

        .flyout__headline {
            height: 39px;

            &--open {
                background-color: $white;
            }
        }

        /***********************************
         * WHY CADFEM
         ***********************************/
        &.why-cadfem .flyout {
            width: 201px;
        }

        &.why-cadfem .flyout .top-navigation__listitem:hover .top-navigation__icon .cadfemicon {
            color: $cadfem-cornflower;
        }

        /***********************************
         * COUNTRY-SWITCHER
         ***********************************/
        &.country-switcher .flyout {
            width: 165px;
            z-index: 5;

            &:hover {
                z-index: 12;
            }

            .top-navigation__listitem:nth-child(1) {
                border-bottom: 3px solid $cadfem-steamgray;
            }

            .acc_content2--open .acc_content2__headline {
                background-color: $cadfem-iceblue;
                border-left: 3px solid $cadfem-cornflower;
            }

            .acc_content2__headline {
                height: 50px;
                border-bottom-color: transparent;
            }

            .acc_content2__title {
                font-size: $font-size-text-small;
                line-height: $line-height-text-small;
            }

            .acc_content2__content {
                background-color: $cadfem-purewhite;
                padding: 0 building-units(1);

                ul {
                    list-style: none;
                    padding-left: 0;
                }

                ul li a {
                    padding: building-units(1);
                    font-size: $font-size-text-small;
                    font-weight: $font-weight-base;
                    line-height: rem(20px);
                    display: block;
                    color: $cadfem-darkblue;

                    &:hover {
                        color: $cadfem-cornflower;
                    }
                }
            }
        }

        /***********************************
         * LANGUAGE-SWITCHER
         ***********************************/
        &.language-switcher .flyout {
            width: 130px;
            z-index: 4;

            &:hover {
                z-index: 12;
            }
        }



        //general overwrites for accordion in top navigation
        .acc_content--open .acc_content__headline,
        .acc_content__headline {
            border-left: 3px solid transparent;
            border-bottom: 1px solid transparent;
        }

        .acc_content__headline {
            border-bottom: none;
        }

        .acc_content__headline,
        .acc_content__content {
            padding: 0 building-units(1) !important;
        }

        .acc_content__title {
            font-size: rem(13px) !important;
            font-weight: $font-weight-base !important;
        }

        .acc_content__toggle {
            background-color: transparent !important;
        }

        .acc_content__title--active {
            color: $cadfem-cornflower;
        }

        .acc_content--active .acc_content__headline,
        .acc_content--open .acc_content__headline {
            background-color: $cadfem-iceblue;
            border-left-color: $cadfem-cornflower;
            border-bottom: 1px solid $cadfem-steamgray;
            color: $cadfem-cornflower;
        }
    }

    &__link {
        display:        block;
        font-weight:    $font-weight-semibold;
    }


    //-------------------------------------------------------------
    // List within flyouts
    //-------------------------------------------------------------
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__listitem {
        > a {
            display: flex;
            flex-flow: row;
            box-sizing: border-box;
        }

        &:last-child {
            border-bottom: none;
        }

        /**
         * accordion
         */
        &:first-child {
            .acc_content2__headline {
                border-top-color: transparent;
            }
        }

        .acc_content2:not(.acc_content2--open) .acc_content2__headline {
            border-bottom-color: transparent;
        }

        &.cadfemai {
            border-top: 2px solid $cadfem-steamgray;
        }
    }

    &__icon {
        padding-top: 8px;
        padding-left: building-units(1);
        margin-right: -5px;
        align-self: flex-start;

        > .cadfemicon {
            top: 0;
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: $cadfem-darkblue;
        }
    }

    &__text {
        display: block;
        //color: $cadfem-denimblue;
        font-size: rem(13px);
        font-weight: $font-weight-base;
        line-height: rem(20px);
        padding: building-units(1) !important;
        width: 100%;

        &:hover {
            color: $cadfem-cornflower;
        }
    }
}
