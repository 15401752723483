@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        .hidden#{$infix}-up {
            display: none !important;
        }
    }

    @include media-breakpoint-down($breakpoint) {
        .hidden#{$infix}-down {
            display: none !important;
        }
    }
    @include media-breakpoint-only($breakpoint) {
        .hidden#{$infix}-only {
            display: none !important;
        }
    }
}

.invisible {
    visibility: hidden;
}
