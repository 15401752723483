//-------------------------------------------------------------
// Color Helpers
//-------------------------------------------------------------


%color-normal {
    color: $text-color;
}

.color-normal {
    @extend %color-normal;
}

%color-success {
    color: $green;
}

.color-success {
    @extend %color-success
}

%color-error {
    color: $red;
}

.color-error {
    @extend %color-error;
}

@each $color, $value in $theme-colors {
    .c-#{$color} {
        color: $value !important;
    }
}
