//-------------------------------------------------------------
// Header
//-------------------------------------------------------------

.header-cf {
    &--fixed {
        position: fixed;
        width:    100%;
        z-index:  9999;

        .brandbar-cf {
            height: 65px;
            padding-bottom: 0;
        }

        .navbar-cf__container {
            height: 50px;
            min-height: 50px;
        }

        @include media-breakpoint-up(lg) {
            .navbar-cf {
                height: 50px;
                min-height: 50px;
            }
        }

        .main-navigation-cf__item {
            height: 50px;
            min-height: 50px;
        }

        .main-navigation-cf__link {
            padding: 0 15px;
            height: 50px;
            line-height: 50px;
        }

        .navbar-search-cf__input,
        input[type=submit],
        .navbar-search-cf__close-button {
            height: 36px !important;
        }
    }
}

//-------------------------------------------------------------
// Brandbar
//-------------------------------------------------------------
.brandbar-cf {
    background-color: $brandbar-background-color;
    height: 98px;
    padding-bottom: 20px;
    transition: height 0.3s, padding-bottom 0.3s;

    @include media-breakpoint-down(md) {
        height: 60px;
        padding-bottom: 10px;
    }

    &__container {
        height: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-end;
    }

    &__container-left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-end;
        width: 50%;
    }

    &__container-right {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
    }

    &__action-wrapper {
        justify-self: flex-end;
    }

    &__flyout-wrapper {
        display: flex;
        flex-flow: row;
        justify-self: flex-end;
    }

    &__flyout-item {
        padding: 0 10px;

        .flyout__headline > div,
        .flyout__toggle {
            display: none;
        }
    }

    &__logo-wrapper {
        min-width: 160px;
    }

    &__logo {
        height: 37px;
        margin: 0 building-units(1) 0 0;

        @include media-breakpoint-down(md) {
            height: 25px;
            margin-left: 0;
        }
    }

    &__claim-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-start;
        max-height: 37px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__claim {
        font-size: $font-size-text-small;
        font-weight: $font-weight-semibold;
        line-height: $line-height-text-small;
        display: block;
    }

    a.link {
        text-decoration: none;
        display: inline-block; //ie did not place them side by side
    }

    /***********************************
    * MY-ACCOUNT
    ***********************************/
    .my-account .flyout {
        position: fixed;
        width: 315px;
        z-index: 40;
        height: 100%;
        text-align: left;
        font-weight: $font-weight-regular;

        .flyout__content {
            line-height: rem(20px);
        }

        &:hover {
            z-index: 50;
        }

        /**
         * contact box adjustments
         */
        .contact_box__label {
            font-size: rem($font-size-text-small);
            line-height: rem($line-height-text-small);
            font-weight: $font-weight-semibold;
            color: $cadfem-darkblue;
            text-transform: none;
        }

        .contact_box__name {
            font-size: rem($font-size-text-small);
            line-height: rem($line-height-text-small);
            letter-spacing: normal;
            margin-bottom: 0;
            font-weight: $font-weight-semibold;
            text-transform: none;
        }

        .contact_box__position {
            margin-bottom: building-units(0.5);
        }

        .contact_box__image {
            width: 50px;
            min-width: 50px;
            margin-right: 0 !important;
        }

        .contact_box__image img {
            max-width: 50px;
            border-radius: 50%;
        }

        .contact_box__content {
            padding-left: 15px;

            .contact_box__link {
                word-break: break-all;
            }
        }

        @include media-breakpoint-up(md) {
            .my_account_wrapper,
            .ecadfem_wrapper {
                .logo {
                    max-height: 29px;
                    height: 29px;
                }
            }
        }
    }
}

.action {
    @extend         %text-small;
    margin:         0 building-units(1);
    text-align:     center;
    color:          $cadfem-darkblue;
    font-weight:    $font-weight-semibold;

    .cadfemicon {
        position:   relative;
        font-size:  18px;
        height:     18px;
        width:      18px;
    }

    .badge {
        position:   absolute;
        left:       14px;
        top:        -14px;
    }

    &__text {
        line-height: rem(28px);
    }

    &:last-child {
        margin-right: 0;
    }
}
