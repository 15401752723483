//-------------------------------------------------------------
// Textarea
//-------------------------------------------------------------

// Variables
$textarea-minheight: rem(215px);
$textarea-height:    rem(97px);

// Textarea Styling
.form-textarea {
    @extend .form-input;

    overflow:   auto;
    min-height: $textarea-minheight;
    height:     $textarea-height;
    resize:     vertical;

    &:disabled {
        resize:     none;
    }
}

.textarea-group {
    @extend .input-group;

    &.has-error:after {
        top: inherit !important;
        bottom: 55px !important;
    }
}

::placeholder {
    // extending breaks all .text-small instances on edge because of 'invalid' ::placeholder selector
    // @extend .text-small;
    font-size:   rem($font-size-text-small);
    line-height: $line-height-text-small;
    margin-bottom: 20px;
}

textarea:disabled,
input:disabled {
    cursor: not-allowed;
}