//-------------------------------------------------------------
// On-Page Section Navigation
//-------------------------------------------------------------
// Variables
.section_nav.section_nav--noDropdown {
    background-color: $cadfem-iceblue;
    border-radius: 8px;
    position: relative;

    .section_nav-container {
        justify-content: center;
    }
}

.section_nav {
    background-color: $cadfem-iceblue;
    border-radius: 8px;
    position: relative;

    &-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        padding: 0 25px;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
            padding: 0;
        }
    }

    &__flyout {
        margin: 20px 0;
        position: relative;
        border-left: 1px solid $cadfem-steamgray;

        @include media-breakpoint-only(xs) {
            border-left-color: transparent;
        }


        .flyout {
            position: absolute;
            right: -10px;
            bottom: -21px;
            left: auto !important;

            &__content {
                ul {
                    background-color: $cadfem-purewhite;
                    list-style: none;
                    padding-left: 0
                }

                li {
                    padding-right: 0;
                    white-space: nowrap;
                    border-bottom: 1px solid $cadfem-steamgray;

                    &:last-child {
                        border-bottom-color: transparent;
                    }
                }

                a {
                    font-size: $font-size-text-small;
                    line-height: $line-height-text-small;
                    width: 100%;
                    height: 100%;
                    padding: building-units(1) 25px;

                    &:hover {
                        color: $cadfem-darkblue;
                        text-decoration: underline;
                    }
                }
            }

            &__headline {
                justify-content: flex-start;
                padding-left: building-units(1);
                padding-right: 0;

                &:hover {
                    color: $cadfem-denimblue;
                    text-decoration: underline;

                    .flyout__title {
                        color: $cadfem-denimblue !important;
                    }
                }
            }

            &__title {
                white-space: nowrap;
                color: $cadfem-denimblue;
                line-height: 30px;

                &:hover {
                    color: $cadfem-denimblue;
                    text-decoration: underline;
                }
            }

            &__toggle-icon {
                line-height: 30px;
            }
        }
    }

    &-dropdown {
        justify-self: flex-end;
        align-self: flex-start;
        position: relative;
        padding-top: 7px;

        .dropdown__toggle {
            white-space: nowrap;
        }

        @include media-breakpoint-only(xs) {
            display: none;
        }
    }

    &__accordion {

        @include media-breakpoint-only(xs) {
            border-top: 1px solid $cadfem-steamgray;
        }

        .acc_content2 {
            &__toggle {
                margin-left: 10px;
                background-color: transparent;
                color: $cadfem-cornflower;
            }

            &__headline {
                padding: building-units(1) 0;
                justify-content: center;
                height: 50px; // fix height for IE

                &:active,
                &:hover {
                    .acc_content2 {
                        &__toggle {
                            background-color: transparent;
                        }

                        &__title {
                            color: $cadfem-cornflower;
                        }
                    }
                }
            }

            &__title {
                color: $cadfem-cornflower;
            }

            &__content {
                background-color: transparent;
                padding: 0;
            }

            &:not(.acc_content2--open) .acc_content2__headline {
                border-bottom-color: transparent;
            }
        }

        ul {
            list-style-type: none;
            text-align: center;
            padding-left: inherit;
            margin-bottom: 0;

            li {
                padding: 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $cadfem-steamgray;
                }
            }

            .link {
                text-decoration: none;
                margin-bottom: 0;
                padding: building-units(1) 0;
                width: 100%;
                height: 100%;

                &:hover {
                    text-decoration: underline;
                    color: $cadfem-darkblue;
                }
            }
        }
    }

    .footer-cf-navigation {
        display: inline-flex;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}

@import "section-nav-list";
@import "section-nav-link";
