
// Sets styles for lists in navigations and breadcrumb
@mixin navigation-list() {
    margin:     0;
    padding:    0;
    list-style: none;
}

%navigation-list {
    @include navigation-list();
}

@mixin horizontal-nav {
    @include navigation-list();
    
    display: flex;
    flex-flow: row nowrap;
}

%horizontal-nav {
    @include horizontal-nav();
}