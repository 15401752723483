//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

// Mixins and Extend Classes

%heading-margin {
    margin-bottom: rem(building-units(2));
}

%heading-margin-small {
    margin-bottom: rem(building-units(1));
}

%heading-uppercase {
    text-transform: uppercase;
}

%h1 {
    font-size:   rem($font-size-h1-l);
    font-weight: $font-weight-bold;
    line-height: $line-height-h1-l;
    letter-spacing: 1px;
    margin: building-units(2) 0;

    @include media-breakpoint-down(md) {
        font-size:     $font-size-h1-sm;
        line-height:   $line-height-h1-sm;
        letter-spacing: 1px;
    }
}

%h2 {
    font-size:   rem($font-size-h2-l);
    line-height: rem($line-height-h2-l);
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    @extend %heading-margin;

    @include media-breakpoint-down(md) {
        font-size:     rem($font-size-h2-sm);
        line-height:   rem($line-height-h2-sm);
        letter-spacing: 1px;
    }
}

%h3 {
    font-size:   rem($font-size-h3-l);
    line-height: rem($line-height-h3-l);
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    @extend %heading-margin-small;

    @include media-breakpoint-down(md) {
        font-size:     rem($font-size-h3-sm);
        line-height:   rem($line-height-h3-sm);
        letter-spacing: 1px;
    }
}

%h4 {
    font-size:   rem($font-size-h4);
    line-height: rem($line-height-h4);
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    @extend %heading-margin-small;

    @include media-breakpoint-down(md) {
        letter-spacing: 1px;
        font-size: rem($font-size-h3-sm);
    }
}

%h5 {
    font-size:   rem($font-size-h5);
    line-height: rem($line-height-h5);
    font-weight: $font-weight-bold;
    letter-spacing: 0.8px;
    @extend %heading-margin-small;

    @include media-breakpoint-down(md) {
        font-weight: $font-weight-bold;
    }
}

%h6 {
    font-size:   rem($font-size-h6);
    line-height: rem($line-height-h6);
    font-weight: $font-weight-bold;
    letter-spacing: 0.65px;

    @extend %heading-margin-small;

    @include media-breakpoint-down(md) {
        font-weight: $font-weight-bold;
    }
}

%display {
    font-size:   rem($font-size-display);
    font-weight: $font-weight-light;
    @extend %heading-margin-small;
    @extend %heading-uppercase;
}

// Styles

h1, .h1 {
    @extend %h1;
}

h2, .h2 {
    @extend %h2;
}

h3, .h3 {
    @extend %h3;
}

h4, .h4 {
    @extend %h4;
}

h5, .h5 {
    @extend %h5;
}

h6, .h6 {
    @extend %h6;
}

.topline {
    &:before {
        content: ' ';
        display: block;
        width: building-units(3);
        border-top: 4px solid $cadfem-cornflower;
        margin-bottom: building-units(1);


        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &--small:before {
        margin-bottom: building-units(1);

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
}

.text-center.topline:before {
    margin-left: calc(50% - 22.5px);
}

.display {
    @extend %display;
}
