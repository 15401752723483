.infohint {
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    padding: 18px 8px;
    margin-bottom: 12px;
    color: $text-color;
    background: $white;

    &--info {
        border-left: 4px solid $info-hint-info-color;
    }

    &--warning {
        border-left: 4px solid $info-hint-warning-color;
    }

    &--alert {
        border-left: 4px solid $info-hint-alert-color;
    }

    &--hint {
        border-left: 4px solid $info-hint-hint-color;
    }

    &--tip {
        border-left: 4px solid $info-hint-tip-color;
    }

    &__title {
        font-weight: bold;
        margin-right: rem(4px);

        .teleicon {
            margin-right: rem(2px);
        }

        + p {
            display: inline; // To enable text being in line with the title
        }

    }
    
    p:last-child {
        margin-bottom: 0;
    }
}