//-------------------------------------------------------------
// datepck
//-------------------------------------------------------------
// Variables

.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 99999;
    display: none;
    font-size: 15px;
    line-height: 1em;

    &__ranges {
        float: none;
        text-align: left;
        margin: 0;

        ul {
            list-style: none;
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

        li {
            font-size: 12px;
            padding: 8px 12px;
            cursor: pointer;
        }

        li:hover {
            background-color: $cadfem-silkwhite;
            color: $cadfem-darkblue;
        }

        li.active {
            background-color: $cadfem-purewhite;
            color: $cadfem-cornflower;

            &:hover {
                background-color: $cadfem-silkwhite;
                color: $cadfem-cornflower !important;
            }
        }
    }

    &__buttons {
        clear: both;
        text-align: right;
        padding: 8px;
        border-top: 1px solid #ddd;
        display: none;
        line-height: 12px;
        vertical-align: middle;

        .btn {
            margin-left: 8px;
            font-size: 12px;
            font-weight: $font-weight-semibold;
            padding: 4px 8px;
        }
    }

    &__calendar {
        display: none;
        max-width: 270px;

        &--single {
            .calendar-table {
                border: none;
            }
        }

        &--right {
            padding: 8px;
        }

        &--left {
            padding: 8px 0 8px 8px;
        }
    }

    &__calendar-table {
        border: 1px solid #fff;
        border-radius: 4px;
        background-color: #fff;

        table {
            width: 100%;
            margin: 0;
            border-spacing: 0;
            border-collapse: collapse;
        }

        th,
        td {
            white-space: nowrap;
            text-align: center;
            vertical-align: middle;
            min-width: 32px;
            width: 32px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            border-radius: 4px;
            border: 1px solid transparent;
            cursor: pointer;
        }

        .next span,
        .prev span {
            color: #fff;
            border: solid black;
            border-width: 0 2px 2px 0;
            border-radius: 0;
            display: inline-block;
            padding: 3px;
        }

        .next span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .prev span {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }

    &__calendar-time {
        text-align: center;

        select {
            border-radius: 8px;
            background-color: $cadfem-purewhite;
            border: 1px solid $cadfem-steamgray;

            &:hover {

            }
        }
    }

    &--single {

    }

    &--show-ranges {

        .daterangepicker__ranges {
            width: 100%;
        }

        .daterangepicker-calendar.daterangepicker-calendar--left {
            border-left: 1px solid #ddd;
        }
    }

    &--show-calendar {

        .daterangepicker__calendar,
        .daterangepicker__buttons {
            display: block;
        }

        .daterangepicker__ranges {
            margin-top: 8px;
        }

        &.daterangepicker.daterangepicker--show-ranges .daterangepicker__ranges {
            width: 140px !important;
        }
    }

    &--auto-apply {
        .daterangepicker__buttons {
            display: none;
        }
    }

    &--selected {
        font-size: $font-size-text-small;
        margin-right: building-units(1);
    }

    &--opens-center {
        &:before {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }

        &:after {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--opens-left,
    &--opens-right {

        &:before {
            right: 9px
        }

        &:after {
            right: 10px;
        }
    }

    &--drops-up {
        margin-top: -7px;

        &:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid #ccc;
        }

        &:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid #fff;
        }
    }

    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    &:before {
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #ccc;
    }

    &:after {
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
}


.daterangepicker.daterangepicker--single .daterangepicker .daterangepicker__ranges, .daterangepicker.daterangepicker--single .daterangepicker__calendar {
    float: none;
}

.daterangepicker td, .daterangepicker th {
    color: $cadfem-darkblue;
    height: 30px;
    width: 30px;
    margin: 0;
    font-size: $font-size-text-small;
    line-height: $font-size-text-small;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: $cadfem-steamgray;
    border-color: transparent;
    color: $cadfem-darkblue;
}

.daterangepicker td.week, .daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: $cadfem-purewhite;
    border-color: transparent;
    color: $cadfem-ashgray;
}

.daterangepicker td.in-range {
    background-color: $cadfem-iceblue;
    border-color: transparent;
    color: #000;
    border-radius: 0;
}

.daterangepicker td.start-date {
    border-radius: 8px 0 0 8px;
}

.daterangepicker td.end-date {
    border-radius: 0 8px 8px 0;
}

.daterangepicker td.start-date.end-date {
    border-radius: 8px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $cadfem-cornflower;
    border-color: transparent;
    color: $cadfem-purewhite;
}

.daterangepicker th.month {
    width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}

.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}

.daterangepicker select.yearselect {
    width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
    width: 50px;
    margin: 0 auto;
    padding: 2px;
    font-size: 12px;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
    .daterangepicker {
        width: auto; }
    .daterangepicker.daterangepicker--single .daterangepicker__ranges ul {
        width: 100%; }
    .daterangepicker.daterangepicker--single .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: none; }
    .daterangepicker.daterangepicker--single.ltr .daterangepicker__ranges, .daterangepicker.daterangepicker--single.ltr .daterangepicker__calendar {
        float: left; }
    .daterangepicker.daterangepicker--single.rtl .daterangepicker__ranges, .daterangepicker.daterangepicker--single.rtl .daterangepicker__calendar {
        float: right; }
    .daterangepicker.ltr {
        direction: ltr;
        text-align: left; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: left;
        margin-right: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--right {
        margin-left: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--right .daterangepicker__calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.ltr .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        padding-right: 8px; }
    .daterangepicker.ltr .daterangepicker__ranges, .daterangepicker.ltr .daterangepicker__calendar {
        float: left; }
    .daterangepicker.rtl {
        direction: rtl;
        text-align: right; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: right;
        margin-left: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--right {
        margin-right: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--right .daterangepicker__calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.rtl .daterangepicker__calendar.daterangepicker__calendar--left .daterangepicker__calendar-table {
        padding-left: 12px; }
    .daterangepicker.rtl .daterangepicker__ranges, .daterangepicker.rtl .daterangepicker__calendar {
        text-align: right;
        float: right; } }
@media (min-width: 730px) {
    .daterangepicker .daterangepicker__ranges {
        width: auto; }
    .daterangepicker.ltr .daterangepicker__ranges {
        float: left; }
    .daterangepicker.rtl .daterangepicker__ranges {
        float: right; }
    .daterangepicker .daterangepicker__calendar.daterangepicker__calendar--left {
        clear: none !important; } }
