//-------------------------------------------------------------
// Do's and Don'ts
//-------------------------------------------------------------

.dos-and-donts {
  .header {
     margin-bottom: building-units(1);
     font-size: rem(24px);
     line-height: rem(31px);
     padding-bottom: 12px;

    .teleicon {
      display: inline-block;
    }

    .icon--rounded {
      padding-top: 4px;
      padding-left: 4px;
      display: inline-block;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }
  }

  &__do {
     margin-bottom: building-units(3);
    .header .teleicon {
      background-color: $color-success;
      color: $dos-text-color;
    }
  }

  &__dont {
     margin-bottom: building-units(3);
    .header .teleicon {
      background-color: $color-error;
      color: $donts-text-color;
    }
  }
}

.sleeve {
  &--huge {
    border-width: 8px !important;
  }

  &--green {
    border-color: $color-success !important;
  }

  &--red {
    border-color: $color-error !important;
  }

  &--bottom {
    border-bottom: 4px solid $white;
  }

  &--top {
    border-top: 4px solid $white;
  }

  &--right {
    border-right: 4px solid $white;
  }

  &--left {
    border-left: 4px solid $white;
  }
}