@mixin reset-hover {
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        text-decoration: none;
    }    
}

%reset-hover {
    @include reset-hover();
}