//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.flyout {
    position: relative;
    z-index: 10;
    display: block;
    max-height: 0;

    &__headline {
        font-weight: $font-weight-semibold;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        padding: 0 building-units(0.3) 0 building-units(0.5);

        @include reset-hover();

        &:hover, &:active {
            color: $cadfem-cornflower;
            background-color: transparent;
            cursor: pointer;

            .cadfemicon, .flyout__title {
                color: $cadfem-cornflower;
            }
        }

        > .cadfemicon {
            margin-right: building-units(0.5);
            line-height: 40px;
        }
    }

    &__title {
        display: block;
        color: $cadfem-ashgray;
        font-size: rem(13px);
        letter-spacing: 0;
    }

    &__toggle {
        color: $cadfem-ashgray;
        flex: 0 16px;
        min-width: 16px;
        text-decoration: none;
        margin-left: 10px;
        align-content: center;

        &:hover {
            color: $cadfem-cornflower;
        }

        .icon-small-arrow-up1 {
            display: none
        }

        .icon-small-arrow-down {
            display: inline-block
        }
    }

    &__content {
        display: none;
        background-color: $cadfem-purewhite;
    }

    &--rounded > .flyout__content {
        border-radius: building-units(1);
    }

    &--open {
        > .flyout__headline {
            border-bottom: 1px solid $footer-border-color;
        }

        .flyout__title, > .cadfemicon {
            color: $cadfem-cornflower;
        }

        > .flyout__content {
            display: block;
            box-shadow: 0 5px 24px 0 rgba(0,0,0,0.05);
        }

        .flyout__toggle-icon {
            color: $cadfem-cornflower;

            &.icon-small-arrow-up1 {
                display: inline-block;
            }

            &.icon-small-arrow-down {
                display: none;
            }
        }
    }
}
