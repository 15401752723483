//-------------------------------------------------------------
// Page Layout Styles
//-------------------------------------------------------------

// Layout of pages and content wrappers

.content {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}

.content-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;

    &--right-column {
      .main {
        max-width: 100%;
      }
    }

    &--sidebar {
        @media (max-width:1024px) {
            flex-flow: column nowrap;
        }
    }
}

.main {
    flex: 1 1 auto;
    max-width: 100%;
    display: block;
    padding: 30px 0 90px;
}

.centered-main {
  align-self: center;
  margin: 0 auto;
}

.sidebar {
    flex: 0 0 300px;
    margin-right: 24px;

    @media (min-width:1024px) {
        &.is-open + .main {
            flex: 0 0 calc(100% - 324px);
            max-width: calc(100% - 324px)
        }
    }
}

.right-column {
    flex: 0 0 100%;
    margin-left: 0;
    margin-bottom: building-units(2);
}

@media (min-width:1024px) {
  .content-wrapper {
    display: flex;
    flex-flow: row nowrap;

    &--right-column {
      .main {
        flex: 0 0 calc(100% - 324px);
        max-width: calc(100% - 324px);
      }
    }
  }

  .right-column {
    flex: 0 0 300px;
    margin-left: 24px;
  }
}

/********************************
 * PROJECT SPECIFIC STYLES
 ********************************/
body.cms-index-index,
body.typocontent-noroute-index {
    main.main > div:last-child > div.banners,
    main.main > div.section_cf:not(.section_cf--bg-white):last-child,
    main.main > div:last-child > section.section_cf:not(.section_cf--bg-white),
    main.main > div:last-child > div.frame-type-gridelements_pi1:last-child > section.section_cf:not(.section_cf--bg-white) {
        margin-bottom: -90px;
    }

    .product-tile-widget  .product-tile-wrapper {
        padding-top: 0;
    }
}

body:not(.account) .page-title-wrapper {
    display: none;
}

form#translate-inline-form table.table tr:nth-child(n+4) {
    display: none;
}

.search-autocomplete {
    padding: building-units(1) building-units(1) 0 building-units(1);
    border: 1px solid $cadfem-steamgray;
    border-radius: 8px;
    background-color: $cadfem-purewhite;
    @extend %shadow--light;
    @extend %text-small;

    .news,.documents,.pages {
      ul,
      h6 {
        + p {
          @extend %text-small;
        }
      }
    }

    width: ($screen-lg-max - building-units(2)) !important;

    @include media-breakpoint-only(lg) {
        width: ($screen-md-max - building-units(2)) !important;
    }

    @include media-breakpoint-down(md) {
        width: ($screen-sm-max - building-units(2)) !important;

        .news,
        .documents,
        .pages ul > p {
          clear: both;
        }

        .pages ul > li,
        .documents ul > li,
        .news a {
            float: left;
            width: 50%;
        }
    }

    .products {
        .linklist {
            li {
                a {
                    text-transform: lowercase;

                    .link__wrapper {
                        .bold {
                            &:hover {
                              color: cornflowerblue;
                            }
                        }
                    }

                    .link__image {
                        width: auto;
                        height: auto;
                        max-height: inherit;
                        max-width: inherit;
                        min-width: inherit;
                        overflow: inherit;
                    }

                    .linklist_thumbnail__img {
                        width: 150px;
                        height: auto;
                    }

                    .link__wrapper__icon {
                        display: flex;
                        flex-flow: row;
                        margin-bottom: 5px;

                        .cadfemicon {
                            margin-right: 5px;
                            font-size: 16px;
                            line-height: 20px;
                        }

                        .cadfemicon, .text {
                            color: $cadfem-darkgray;
                            font-weight: $font-weight-semibold;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% - 15px) !important;
    }

    @include media-breakpoint-only(xs) {
        .pages ul > li,
        .documents ul > li,
        .news a {
            float: inherit;
            width: 100%;
        }
    }

    .qs-option-name {
        text-transform: lowercase;
    }
}

.search-autocomplete .pages ul.linklist--small > li {
    margin-bottom: 0;
}

body.customer-logged-in .country-switcher .flyout__toggle {
    visibility: hidden;
}

body.customer-logged-in .country-switcher .flyout--open {
    background-color: transparent;
    box-shadow: none;
}

body.catalog-product-view .product-tile-small__top p.text-small + p {
    @extend .text-small;
}

body.catalog-product-view .product-tile-small__top p.text-small:empty {
    margin-bottom: 0;
}

body.wishlist-index-index .product-tile__wishlist {
    display: none;
}

table .table-caption {
    display: none;
}

body.checkout-cart-index .cart-summary {
    .acc_content2__headline {
        border-color: transparent !important;
    }

    .cart-totals .grand .mark,
    .cart-totals .grand .amount {
        border-top-width: 1px;
        border-color: $cadfem-purewhite !important;
    }
}

body.typocontent-noroute-index div.frame-type-mmsmagentoconnect_product_widget > .product-tile-container.product-tile-container--grid {
    justify-content: center;
}

/***
 * Recaptcha
 */
.field-recaptcha {
    margin-bottom: building-units(1);
}


/*******
 * CART
 ******/
body.checkout-cart-index {
    #cart_summary_estimated_shipping {
        display: none;
    }
}

/*******
 * CHECKOUT
 ******/
body.checkout-index-index .checkout-shipping-method {
    .step-title,
    .shipping-policy-block,
    #custom-field,
    #checkout-shipping-method-load,
    #onepage-checkout-shipping-method-additional-load {
        display: none;
    }
}

body.checkout-index-index {
    .payment-option.opc-payment-additional.discount-code,
    #shipping-save-in-address-book-wrapper {
        display: none;
    }

    .product-image-wrapper > img {
        width: 100%;
        height: auto;
    }

    div[name="shippingAddress.region_id"],
    div[name="shippingAddress.region"] {
        display: none !important;
    }
}

body.checkout-index-index .field-select-billing .selectbox-option {
    max-height: inherit !important;
}

body.checkout-index-index fieldset.street legend {
    color: $cadfem-darkblue;
    font-size: $font-size-text-small;
    line-height: $line-height-text-small;
    text-transform: uppercase;
}

.frame-type-mmsmagentoconnect_product_widget {
    width: 100%;
}

.ce-center img {
    margin: 0 auto;
}

.videodetails {
    margin-left: 15px;
    margin-right: 15px;
}

.videodetails .product-tile-wrapper {
    max-width: 100% !important;
    padding: 0;
}

.videodetails img.img_content-box__img,
.videodetails .teaser__img {
    height: fit-content !important;
}

#journal {
    display: block;
    padding-left: 0;
    padding-right: 0;
    background: linear-gradient(0deg, rgba(243,246,249,1) 0%, rgba(255,255,255,1) 100%);

    > .section_cf__content {
        padding-left: 0;
        padding-right: 0;
        max-width: 57%;
        border: 1px solid $cadfem-steamgray;
        background-color: $cadfem-purewhite;
        @extend %shadow--semistrong;

        @include media-breakpoint-only(sm) {
            max-width: 75%;
        }

        @include media-breakpoint-only(xs) {
            max-width: calc(100% - 15px) !important;
        }

        .section_cf {
            padding: 0 building-units(1)!important;
        }
    }
}

#search_autocomplete .linklist li.selected {
    text-decoration: underline;

    > a {
        color: $cadfem-cornflower;
    }
}

#cookie-status {
    display: none;
}

.link.action.my-account {

    &:hover {
        color: inherit;
    }

    .flyout__content {
        border-radius: 10px;
    }

    ul > li {
        min-height: 25px;
    }

    .border--rounded {
        border-radius: 0 0 10px 10px;
    }
}
