//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.modal {
    position: fixed;
    z-index: 100001;
    top: 5%;
    right: 0;
    left: 50%;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-width: 100%;
    max-height: 90%;
    border: 1px solid $footer-border-color;
    background-color: $white;
    display: none;
    padding: building-units(2);
    border-radius: 8px;
    transform:scale(0);
    animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    @include media-breakpoint-only(xl) {
        width: calc((#{$screen-xl} / 12) * 6);
        margin-left: calc((((#{$screen-xl} / 12) * 6) * -1) / 2) !important;
    }

    @include media-breakpoint-only(lg) {
        width: calc((#{$screen-lg} / 12) * 6);
        margin-left: calc((((#{$screen-lg} / 12) * 6) * -1) / 2) !important;
    }

    @include media-breakpoint-only(md) {
        width: calc((#{$screen-md} / 12) * 8);
        margin-left: calc((((#{$screen-md} / 12) * 8) * -1) / 2) !important;
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% - 30px);
        left: 0;
    }

    &__header {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
    }

    &__title {
        width: 100%;
        margin-top: 0;
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
        letter-spacing: 0.8px;
        @extend %overflow-ellipsis;
    }

    &__footer {
        margin-top: building-units(1);
    }

    &__content {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__close {
        cursor: pointer;
        position: relative;
        top: -10px;
        left: 10px;

        .cadfemicon {
            font-size: 30px;
            color: $cadfem-darkblue;
        }
    }

    &--open {
        display: flex;
        margin: 0 auto;
        flex-flow: column;
        transform:scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &--out {
        animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    z-index: 9999;
    overflow-y: auto;
    background-color: rgba($cadfem-darkblue, 0.20);
    width: 100%;
    height: 100%;
    animation: zoomIn;
}

.modals-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
