//-------------------------------------------------------------
// myacc_sdb_menu
//-------------------------------------------------------------
// Variables

.myacc_sdb_menu {
    h6 {
        color: $cadfem-ashgray;
        margin-bottom: 5px;
        margin-top: building-units(2);
    }

    .tabbar-container:before {
        border-bottom: none;
    }

    .tabbar-container, .tabbar__tab {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        .tabbar-content>.tab-pane {
            padding-top: 0;
        }
    }

    .collapse-menu__item:not(.collapse-menu--open),
    .collapse-menu--open > .collapse-menu__headline {
        border-bottom: 1px solid $cadfem-steamgray;
    }

    .collapse-menu_headline-main .collapse-menu__link {
        color: $cadfem-darkblue !important;
        font-weight: $font-weight-semibold !important;
        text-transform: uppercase;
    }

    .collapse-menu--open > .collapse-menu_headline-main {
        border-left: 2px solid $cadfem-cornflower;
        background-color: $cadfem-iceblue;
    }

    .collapse-menu__headline .collapse-menu__link {
        @extend %text-small;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        color: $cadfem-darkblue;

        &:hover {
            color: $cadfem-cornflower;
            background-color: transparent;
        }

        > small {
            line-height: 14px;
            font-weight: $font-weight-base;

            &:hover {
                color: $cadfem-darkblue;
            }
        }
    }

    .collapse-menu__toggle,
    .collapse-menu__toggle:focus {
        color: $cadfem-darkblue;
    }

    .collapse-menu--open > .collapse-menu__headline:not(.collapse-menu_headline-main) .collapse-menu__link,
    .collapse-menu--open > .collapse-menu__headline:not(.collapse-menu_headline-main) .collapse-menu__toggle {
        color: $cadfem-cornflower;
    }

    .collapse-menu__toggle {
        padding: 12px;

        &:hover {
            background-color: transparent;
        }
    }

    .collapse-menu__toggle:before {
        border-left: transparent;
    }

    .collapse-menu__content,
    .collapse-menu--open .collapse-menu__item {
        background-color: transparent;
    }

    .collapse-menu--current .collapse-menu__link {
        color: $cadfem-cornflower;
    }

    .collapse-menu--open .collapse-menu__item .collapse-menu__content {
        padding-left: 0;
    }

    .collapse-menu > .collapse-menu--open .collapse-menu__content .collapse-menu__link {
        padding-left: building-units(1);
    }

    .collapse-menu .collapse-menu > .collapse-menu--open .collapse-menu__content .collapse-menu__link {
        padding-left: building-units(2);
    }

    .collapse-menu .collapse-menu .collapse-menu > .collapse-menu--open .collapse-menu__content .collapse-menu__link {
        padding-left: building-units(3);
        font-weight: $font-weight-base;
    }

    /**
     * Magento customization
     */
    .collapse-menu li.nav.item > a,
    .collapse-menu li.nav.item > strong {
        @extend %text-small;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        color: $cadfem-darkblue;
        padding: building-units(1) 0 building-units(1) building-units(1);
        display: block;
        border-bottom: 1px solid $cadfem-steamgray;

        &:hover {
            background-color: transparent;
            color: $cadfem-cornflower;
        }
    }

    .collapse-menu li.nav.item.current strong {
        color: $cadfem-cornflower;
    }
}
