.prev-next {
    display: flex;
    flex-flow: row nowrap;
    border-top: 1px solid $pre-next-border;
    margin-top: 12px;

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    background-color: $pre-next-background-color;


    &__prev,
    &__next {
        flex: 1;
    }

    @include media-breakpoint-up(md) {
        &__prev {
            margin-right: 112px
        }
    }

    &__link-prev,
    &__link-next {
        display: block;
        position: relative;
        text-decoration: none;
        padding: 24px;
        text-align: center;
        color: $pre-next-link-next-color;
        @include reset-hover();

        &:hover,
        &:focus {
            background: $pre-next-focus-background-color;
        }
        &:active {
            background: $pre-next-active-background-color;
        }
    }

    @include media-breakpoint-up(md) {
        &__link-prev {
            padding-right: 42px;
            text-align: right;
        }
    
        &__link-next {
            padding-left: 42px;
            text-align: left;
        }
        
        &__icon-left,
        &__icon-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 24px;
        }
    
        &__icon-left {
            right: 18px;
        }
    
        &__icon-right {
            left: 18px;
        }
    }

    @include media-breakpoint-up(lg) {
    &__icon-left,
        &__icon-right {
            top: 55px;
        }
    }

    &__link-direction,
    &__link-name {
        display: none;
    }

    &__link-direction {
        color: $pre-next-link-next-direction-color;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.2;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__link-name {
        font-size: 24px;
        line-height: 1.3;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}