@keyframes spin {
    0% { 
        transform: rotate(0); 
    }
    100% { 
        transform: rotate(360deg);
    }
}

.icon--spinning {
    animation: spin 500ms linear infinite;
}