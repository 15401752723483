//-------------------------------------------------------------
// Metanavigation
//-------------------------------------------------------------

.meta-navigation {
    float: right;

    ul {
        @include navigation-list();

        li {
            padding: 0 building-units(1);

            &:last-child { padding-right: 0; }
        }
    }
}
