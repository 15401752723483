//-------------------------------------------------------------
// ScrollToTop Button
//-------------------------------------------------------------
// Variables
$footer-color: $cadfem-purewhite;


//-------------------------------------------------------------
// Scroll To Top + Scroll To Top Free
//-------------------------------------------------------------
.scroll-to-top {
    padding: 0;
    width: 45px;
    height: 45px;
    background-color: $cadfem-purewhite;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
    position: absolute;
    top: -22.5px;
    left: 50%;
    z-index: 12;
    margin-left: -22.5px;
    text-align: center;
    color: $cadfem-cornflower;

    .cadfemicon {
        font-size: 30px;
        line-height: 45px;
        width: auto; //override btn-medium
        height: auto; //override btn-medium
    }

    &:hover {
        color: $cadfem-denimblue;
    }

    &--free {
        position: fixed;
        top: auto;
        left: auto;
        right: 15px;
        bottom: 15px;
        transition: background-color .3s,
        opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;

        &--fixed {
            position: absolute;
        }

        &--show {
            opacity: 1;
            visibility: visible;
            background-color: rgba($cadfem-purewhite, 0.75);
        }

        &:hover {
            background-color: $cadfem-purewhite;
        }
    }
}
