.horizontal-scroller-container {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.horizontal-scroller-wrapper {
    flex: 1 100%;
    overflow: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
}

.horizontal-scroller__inner {
    width: min-content;
    min-width: 100%;
    // Above doesn't work in IE. We fake it with float.
    float: left;
}

.horizontal-scroller {
    display: flex;
    &__nav {
        @extend %horizontal-nav;

        font-size: inherit;
    }

    &__nav-item {
        padding: 0 12px;
    }

    &__link {
        @include reset-hover;

        &--active {
            font-weight: $font-weight-semibold;
        }
    }

    &-container {
      display:       inline-block;
      position:      relative;
      width:         100%;
    }

    &-wrapper {
      overflow:      hidden;
    }
}

$horizontal-scroller-btn-width:        building-units(2.5);
$horizontal-scroller-btn-height:       97%;
$horizontal-scroller-btn-color:        $text-color;
$horizontal-scroller-btn-color-hover:  $magenta;
$horizontal-scroller-btn-bg-color:     $white;

.horizontal-scroller-btn {
    position: absolute;
    background: $horizontal-scroller-btn-bg-color;
    border: 0;
    cursor: not-allowed;
    float: left;
    top: 0;
    width: $horizontal-scroller-btn-width;
    height: $horizontal-scroller-btn-height;
    flex: 1 $horizontal-scroller-btn-width;
    z-index:    2;
    color:      rgba($horizontal-scroller-btn-color, 0.3);
    transition: color .2s ease;

    &:focus,
    &:active {
        border:  0;
        outline: 0;
    }

    &:hover {
        transition: color .2s ease;
    }

    &.active {
        color:      rgba($horizontal-scroller-btn-color, 1);
        cursor:     pointer;
    }
}

.horizontal-scroller-btn-prev,
.horizontal-scroller-btn-next {
    display: none;

    .show-controls & {
        display: block;
    }
}

.horizontal-scroller-btn-prev::before,
.horizontal-scroller-btn-next::after {
    @extend %teleicon;

    font-size:   building-units(2);
    line-height: $horizontal-scroller-btn-height;
    position:    absolute;
    top:         calc(50% - 15px);
}

.horizontal-scroller-btn-prev::after,
.horizontal-scroller-btn-next::before {
    content:   "";
    display:   inline-block;
    height:    $horizontal-scroller-btn-height;
    position:  absolute;
    right:     0;
    top:       0;
    transform: scale(1, 1);
    width:     $horizontal-scroller-btn-height;
}

.horizontal-scroller-btn-prev {
    left: 0;

    &::before {
        content: "\25";
        left:    calc(50% - 6px);
    }

    &::after {
        border: 0;
        right:  0;
    }
}

.horizontal-scroller-btn-next {
    right: 0;

    &::before {
        left: 0;
    }

    &::after {
        content: "\a7";
        right:   calc(50% - 6px);
    }
}
