// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.
$badge-padding-y: 1px;
$badge-padding-x: 3px;
$badge-default-bg: $cadfem-cornflower;
$badge-color: $cadfem-purewhite;
$badge-link-hover-color: $cadfem-purewhite;
$badge-pill-border-radius: 40px;

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-family: 'Neue DIN';
  font-size: rem(13px);
  line-height: rem(18px);
  height: 18px;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $badge-pill-border-radius;
  background-color: $badge-default-bg;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// scss-lint:enable QualifyingElement

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: 6px;
  padding-left: 6px;

}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

.badge-default {
  @include badge-variant($badge-default-bg);
}

.badge-primary {
  @include badge-variant($color-brand);
}

.badge-success {
  @include badge-variant($color-success);
}

.badge-info {
  @include badge-variant($color-info);
}

.badge-warning {
  @include badge-variant($color-warning);
}

.badge-danger {
  @include badge-variant($color-error);
}
