//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.sticky {
    position: fixed;
    z-index: 11;
    left: 0;
    display: none;
}
