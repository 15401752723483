//-------------------------------------------------------------
// section-menu: link
//-------------------------------------------------------------

// Variables
$section_nav-link-font-weight: $font-weight-semibold;

.section_nav-link {
    font-weight:        $section_nav-link-font-weight;
    text-transform:     uppercase;
    white-space:        nowrap;
    color: $cadfem-denimblue;

    @include media-breakpoint-down(md) {
        text-transform: none;
        letter-spacing: 0;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration:     underline;
        color: $cadfem-denimblue;
    }
}
.section_nav.section_nav--noDropdown {
    .section_nav-link {
        white-space: nowrap;
    }
}
