@mixin navbar-link {
    text-decoration: none;
    color: $cadfem-darkblue;

    &:hover,
    &:focus,
    &:active {
        color: $cadfem-cornflower;
        text-decoration: none;
    }
}

%navbar-link {
    @include navbar-link();
}
