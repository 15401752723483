.font-weight-normal {
    font-weight: $font-weight-regular !important;
}

.font-weight-bold,
.bold {
    font-weight: $font-weight-semibold;
}

.font-weight-extrabold,
.extrabold {
    font-weight: $font-weight-bold;
}

.font-weight-black {
    font-weight: $font-weight-bold;
}

.text-transform-none {
    text-transform: none !important;
}

.text-transform-uppercase {
    text-transform: uppercase !important;
}

%overflow-ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-ellipsis {
    @extend %overflow-ellipsis;
}

.ta-center,
.text-align-center {
    text-align: center;
}

.ta-left,
.text-align-left {
    text-align: left;
}

.ta-right,
.text-align-right {
    text-align: right;
}
