//-------------------------------------------------------------
// banner
//-------------------------------------------------------------
// Variables

.banners-content {
    position: relative;
    width: 100%;
    height: 330px;
    background-color: $cadfem-darkblue;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: building-units(2);
    overflow: hidden;

    a.link {
        color: $cadfem-purewhite;
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc00345f', endColorstr='#33abcbee', GradientType=1);
        background: -moz-linear-gradient(90deg, rgba(0, 52, 95, 0.9) 0%, rgba(36, 84, 126, 0.8) 40%, rgba(171, 203, 238, 0.1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0, 52, 95, 0.9) 0%, rgba(36, 84, 126, 0.8) 40%, rgba(171, 203, 238, 0.1) 100%);
        background: linear-gradient(90deg, rgba(0, 52, 95, 0.9) 0%, rgba(36, 84, 126, 0.8) 40%, rgba(171, 203, 238, 0.1) 100%);
    }

    &--bg-silver {
        margin-bottom: 0;

        a.link {
            color: $cadfem-darkblue;
        }

        &:before {
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
            background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.55) 30%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.55) 30%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.55) 30%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @extend %imageTransitionHoverOut;

        @include make-col(12, 12);
    }

    &:hover .banners-content__image {
        @extend %imageTransitionHover;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 25px;

        @include media-breakpoint-only(sm) {
            @include make-col(6, 12);
        }
        // CADFEMEB-1903
        @include media-breakpoint-up(md) {
            @include make-col(7.5, 12);
        }

        @include media-breakpoint-down(xl) {
            @include make-col(4, 12);
        }

        .banners-content__introduction {
            font-size: 16px;
        }
    }

    &__subline {
        @extend %text-small;
        color: $cadfem-purewhite;
        display: block;
        margin-bottom: 5px;

        .banners-content--bg-silver & {
            color: $cadfem-darkblue;
        }
    }

    &__headline {
        margin-bottom: building-units(1);
        color: $cadfem-purewhite;

        .banners-content--bg-silver & {
            color: $cadfem-darkblue;
        }
    }

    &__introduction {
        margin-bottom: 20px;
        color: $cadfem-purewhite;

        .banners-content--bg-silver & {
            color: $cadfem-darkblue;
        }
    }


    @include media-breakpoint-down(xs) {
        height: auto;

        &:before {
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc00345f', endColorstr='#33abcbee', GradientType=1);
            background: -moz-linear-gradient(0deg, #00345f 34%, #00345f 49%, rgba(171, 203, 238, .1) 64%);
            background: -webkit-linear-gradient(0deg, #00345f 34%, #00345f 49%, rgba(171, 203, 238, .1) 64%);
            background: linear-gradient(0deg, #00345f 34%, #00345f 49%, rgba(171, 203, 238, .1) 64%);
        }

        &.banners-content--bg-silver {
            margin-bottom: 0px;
            background-color: $cadfem-iceblue;

            &:before {
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3f6f9", endColorstr="#ffffff", GradientType=1);
                background: -moz-linear-gradient(0deg, rgba(243, 246, 249, 1) 45%, rgba(255, 255, 255, 0.70) 50%, rgba(255, 255, 255, 0) 60%);
                background: -webkit-linear-gradient(0deg, rgba(243, 246, 249, 1) 45%, rgba(255, 255, 255, 0.70) 50%, rgba(255, 255, 255, 0) 60%);
                background: linear-gradient(0deg, rgba(243, 246, 249, 1) 45%, rgba(255, 255, 255, 0.70) 50%, rgba(255, 255, 255, 0) 60%);
            }

            .banners-content__image {
                background-size: cover; //CADFEMEB-2738
            }

        }

        .banners-content__image {
            height: 330px;
            background-size: cover;
            background-position: top center; //CADFEMEB-1625

            // CADFEMEB-1625
            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: -50px;
            }

        }

        .banners-content__content {
            position: inherit !important;
            @include make-col(12, 12);
        }
    }
}
