//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables



.accordion {
  border-top: 1px solid $accordion-border-color;
  display: block;

  &__headline {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    background: $accordion-headline-background-color;
    cursor: pointer;


    padding: building-units(1);
    text-decoration: none;

    @include reset-hover();

    &:hover, &:active {
      color: $accordion-headline-hover-color;
      background-color: $accordion-headline-hover-background-color;
      cursor: pointer;

      .teleicon, .accordion__title {
        color: $accordion-headline-hover-color;
      }
    }

    > .teleicon {
      line-height: $line-height-h3;
      margin-right: building-units(0.5);
    }
  }

  &__title {
    display: block;
    color: $accordion-headline-color;
    font-size: $font-size-h3;
    letter-spacing: 0;
    line-height: $line-height-h3;
    margin-bottom: building-units(0.5);
  }

  &__subline {
    margin-top: building-units(0.5);
  }

  &__toggle {
    flex: 0 32px;
    min-width: 32px;
    text-decoration: none;
    color: $accordion-toggle-color;
    margin-left: auto;
    align-content: center;

    &:hover {
      color: $accordion-toggle-hover-color;
    }

    .icon-small-arrow-up1 {
      display: none
    }

    .icon-small-arrow-down {
      display: inline-block
    }
  }

  &__content {
    padding: building-units(1);
    display: none;
  }

  &--open {
    > .accordion__headline {
      background-color: $accordion-headline-open-background-color;
      color: $accordion-headline-open-color;
    }

    .accordion__title {
      color: $accordion-headline-open-color;
    }

    > .accordion__content {
      display: block;
    }

    > .accordion__headline > .accordion__toggle {
      color: $accordion-toggle-open-color;
      > .icon-small-arrow-up1 {
        display: inline-block;
      }

      > .icon-small-arrow-down {
        display: none;
      }
    }
  }

  &--inactive {
    color: $accordion-inactive-color;

    .accordion__headline,
    .accordion__title,
    .accordion__toggle,
    .accordion__subline {
      color: $accordion-inactive-color;
    }

    .accordion__headline {
      &:hover, &:active {
        color: $accordion-inactive-color;
        cursor: no-drop;
        background-color: transparent;
      }

      .teleicon, .accordion__title {
        color: $accordion-inactive-color;
      }
    }

  }
}
