.chip {
    display:            inline-block;
    height:             building-units(2);
    font-size:          $font-size-text-small;
    color:              $cadfem-darkblue;
    line-height:        building-units(2);
    padding:            0 building-units(1);
    border-radius:      8px;
    background-color:   $cadfem-iceblue;
    margin-top:         building-units(1);
    margin-right:       building-units(0.5);
    cursor:             pointer;

    &:hover {

    }

    > .cadfemicon {
        cursor: pointer;
        float:          right;
        font-size:      building-units(1);
        line-height:    building-units(2);
        padding-left:   8px;
    }
}
