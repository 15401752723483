//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.code-component-demonstrator {
  border-radius: rem(4px);
  border: rem(1px) solid $gray-220;
  margin: rem(0 0 24px);
  overflow: hidden;

  &__technology {
    margin: rem(24px) 0;
  }

  &__copy {
    text-align: right;
  }

  &__content {
    width: 100%;

    .tabbar-list {
      justify-content: flex-start;
    }
  }

  &--hide {
    display: none;
  }

  &--show {
    display: block;
  }

  .infohint {
    margin: 0 rem(12px) rem(18px);
  }

}

.code-demonstrator-header {
  display: flex;
  padding: building-units(1);

  .header-actions {
    margin-left: auto;
  }
}

.code-demonstrator-footer {
  padding: rem(building-units(1));
  text-align: center;
  display: none;
}

.code-demo {
  font-size: 12px;
  margin: 0;
  //overflow: auto;

  @include media-breakpoint-up (md) {
  }

  &__code {
    max-height: 80vh;
    margin-top: 0;
    @include media-breakpoint-up (md) {
      max-height: 60vh;
      font-size: 15px;
    }
  
  }

  &__title {
    padding: rem(6px) rem(12px) rem(6px) rem(12px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-bottom: rem(1px) solid $gray-220;


    .file-name {
      font-size: rem(15px);
      font-weight: bold;
      max-width: calc(100% - 84px);
      word-break: break-word;
      margin-right: auto;
      
      @include media-breakpoint-up (md) {
        font-size: rem(18px);
      }
    }
    
    .btn {
      margin-left: rem(12px);
    }
  }

  &__file-icon {
    margin-right: rem(12px);
  }
}