%shadow--light {
    box-shadow: 0 5px 24px rgba(0,0,0,0.05);
}

%shadow--semistrong {
    box-shadow: 0 5px 24px rgba(0,0,0,0.15);
}

%shadow--strong {
    box-shadow: 0 5px 24px rgba(0,0,0,0.25);
}

.shadow {
    @extend %shadow--light;

    &--semistrong {
        @extend %shadow--semistrong;
    }

    &--strong {
        @extend %shadow--strong;
    }
}