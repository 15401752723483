//-------------------------------------------------------------
// Input Label
//-------------------------------------------------------------

// Variables
$label-margin:          5px;
$label-color:           $cadfem-darkblue;

// Label Styling
label {
    @extend %text-small;
    display:            block;
    margin-bottom:      $label-margin;
    color:              $label-color;

    .mandatory {
        color:          $label-color;
        margin-left:    2px;
    }

    text-transform:     uppercase;
    font-weight:        $font-weight-semibold;
}

.explain-text {
  @extend %text-small;
  margin-bottom: $label-margin;
}
