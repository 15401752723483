//-------------------------------------------------------------
// Input Validation Styling
//-------------------------------------------------------------

@mixin set-input-validation-styling($validation-bg-color, $validation-hover-color, $validation-color) {
    .form-input {
        background-color: $validation-bg-color;
        border-color:     $validation-color;

        &:focus {
            background-color: $input-bg-color;
            border-color:     $validation-color;
            box-shadow:       $input-box-shadow rgba($validation-color, .5);
        }

        &:hover {
            background-color: $validation-hover-color;
        }

        &:active {
            background-color: $input-bg-color;
            border-color:     $validation-color;
            box-shadow:       $input-box-shadow rgba($validation-color, .5);
        }
        &:active{
            &:hover {
                border-color: $validation-color;
            }
        }
    }

    .validation-message {
        @extend %text-small;

        display:     block;
        padding-top: $input-elements-padding;
        color:       $validation-color;
    }
}
