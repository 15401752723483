//-------------------------------------------------------------
// dimmer
//-------------------------------------------------------------
// Variables
$dimmer-background-color: rgba($cadfem-darkblue, 0.20);

.dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dimmer-background-color;
    z-index: 9999;
}
