//-------------------------------------------------------------
// CadfemComplexIcon
//-------------------------------------------------------------
$icon-columns:  4;
$icons-rows:    22;
$icon-width:    50;
$icon-height:   50;
$icon-margin:   16;

$sprite-width:  248;
$sprite-height: 1568;

$complexIcons: (
    payment-bill:0,
    three-p-model:1,
    four-step-model-1-step: 2,
    four-step-model-4-step: 3,
    four-step-model-simulation-strategy: 4,
    development-process: 5,
    development-work: 6,
    process: 7,
    info-event:8,
    academic-offers:9,
    consulting:10,
    elearning:11,
    products-services:12,
    project-partner:13,
    quality-products-services:14,
    references-case-studies:15,
    seminars:16,
    seminars-events:17,
    solution:18,
    speaker:19,
    task:20,
    time-display:21,
    use:22,
    webinar:23
);

$complexIconsStates: (
    active:0,
    default:1,
    hover:2,
    inactive:3
);

@function background-position-for-size($icon, $state, $size) {
    @if map-has-key($complexIcons, $icon) {
        $percent: $size * 100 / $icon-width;

        $width: ($icon-width + $icon-margin) * $percent / 100;
        $row: #{map-get($complexIcons, $icon)};
        $column: #{map-get($complexIconsStates, $state)};

        @return calc((#{$column} * #{$width}) * -1px) calc(((#{$row} * #{$width}) * -1px));
    }
    @else {
        @return $icon $state $size;
    }
}

%cadfemcomplexicon {
    width:      #{$icon-width}px;
    height:     #{$icon-height}px;
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('#{$icon-image-path}/complexicon_sprite.svg');
    background-size: $sprite-width + px $sprite-height + px;
}

.cadfemcomplexicon {
    @extend %cadfemcomplexicon;

    @each $size in $icon-sizes {
        $width-percent: $size * 100 / $icon-width;
        $background-width: $sprite-width * $width-percent / 100;
        $background-height: $sprite-height * $width-percent / 100;

        &.icon-#{$size} {
            min-width: $size + px;
            width: $size + px;
            height: $size + px;
            background-size: calc(#{$background-width} * 1px) calc(#{$background-height} * 1px) !important;
        }
    }

    @each $complexIcon in $complexIcons {
        $complexIconKey: nth($complexIcon, 1);
        $complexIconValue: nth($complexIcon, 2);
        &.icon_#{$complexIconKey} {
            @each $complexIconsState in $complexIconsStates {
                $complexIconsStateKey: nth($complexIconsState, 1);
                $complexIconsStateValue: nth($complexIconsState, 2);
                &_#{$complexIconsStateKey} {
                    @each $size in $icon-sizes {
                        &.icon-#{$size} {
                            background-position: background-position-for-size($complexIconKey, $complexIconsStateKey, $size) !important;
                        }
                    }
                }
            }
        }
    }
}

/**
 * Switch icon on hover for specific used components
 */
/**************************************
 * eLearning
 **************************************/
@each $complexIcon in $complexIcons {
    $complexIconKey: nth($complexIcon, 1);

    .tabbar__tab .cadfemcomplexicon.icon_#{$complexIconKey} {
        @extend .icon_#{$complexIconKey}_default;
    }

    .tabbar__tab.inactive .cadfemcomplexicon.icon_#{$complexIconKey} {
        @extend .icon_#{$complexIconKey}_inactive;
    }

    .tabbar__tab:not(.inactive):hover .cadfemcomplexicon.icon_#{$complexIconKey} {
        @extend .icon_#{$complexIconKey}_hover;
    }

    .tabbar__tab.active .cadfemcomplexicon.icon_#{$complexIconKey} {
        @extend .icon_#{$complexIconKey}_active;
    }
}

.cadfemcomplexicon.icon-level-basicknowledge,
.cadfemcomplexicon.icon-level-1 {
    background-position: left center;
    background-size: contain;
    background-image: url('#{$icon-image-path}/level-1.svg');
}
.cadfemcomplexicon.icon-level-intermediateknowledge,
.cadfemcomplexicon.icon-level-2 {
    background-position: left center;
    background-size: contain;
    background-image: url('#{$icon-image-path}/level-2.svg');
}
.cadfemcomplexicon.icon-level-expertknowledge,
.cadfemcomplexicon.icon-level-3 {
    background-position: left center;
    background-size: contain;
    background-image: url('#{$icon-image-path}/level-3.svg');
}
