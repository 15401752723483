//-------------------------------------------------------------
//Action Items
//-------------------------------------------------------------

// Variables

// General
.action-items {
    &__icon:not(:first-child) {
        margin-left: 25px;
    }

    .cadfemicon {
        font-size: rem(24px);
        color: $cadfem-cornflower;

        &:hover {
            color: $cadfem-denimblue;
        }
    }
}