//-------------------------------------------------------------
// Styles Input-Addon Border
//-------------------------------------------------------------

@mixin reset-border-radius-direction($direction) {
    @if $direction == "right" {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @else if $direction == "left" {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
