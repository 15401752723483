.loading-mask {
    position: relative;
}

/*
Because we set .loading-mask relative, we can span our ::before
element over the whole parent element
*/
.loading-mask::before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

/*
Spin animation for .loading-mask::after
*/
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/*
The loading throbber is a single spinning element with three
visible borders and a border-radius of 50%.
Instead of a border we could also use a font-icon or any
image using the content attribute.
*/
.loading-mask::after {
    display: none;
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: 2s linear 0s normal none infinite running spin;
    filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
}


body.ajax-loading .loading-mask::after,
body.ajax-loading .loading-mask::before {
    display: block;
}

#search_autocomplete {
    .products {
        .linklist {
            li {
                @include media-breakpoint-only(md) {
                    @include make-col(6, 12);
                    float: left;
                }
            }
        }
    }
}

body.catalogsearch-result-index .navbar-search-cf__form input.has-error {
    border: 1px solid $cadfem-chesnut;
}

body.catalogsearch-result-index,
body.typocontent-noroute-index {
    .navbar-search-cf__form span.has-error.validation-message {
        position: absolute;
        top: 50px;
    }
}

body.catalogsearch-result-index .research_module-pagination {
    .pagination__item {
        display: none;
    }

    .pagination__item.pagination__prev,
    .pagination__item.pagination__next,
    .pagination__item.active,
    .pagination__item.show {
        display: block;
    }
}

body.catalogsearch-result-index {
    .research_module-search {
        padding: 0;
    }

    .tabbar {
        @extend .col-12;
        @extend  .col-lg-9;

        padding-left: building-units(0.5);
        margin-left: auto;
        margin-right: 0;

        &-list {
            justify-content: flex-start;
        }

        &-container:before {
            border-bottom-width: 1px !important;
            border-bottom-color: $cadfem-darkgray;
        }

        &__tab {
            margin-right: 10px;

            .tabbar-link {
                background-color: $cadfem-silkwhite;
                color: $cadfem-darkgray;
                padding: 0 building-units(1);
                height: 50px;
                line-height: 50px;
                border-radius: 8px 8px 0 0;
                flex-flow: row;

                > span {
                    line-height: 30px;
                    text-transform: inherit;
                    padding-left: 5px;
                }
            }

            &.active {
                .tabbar-link {
                    background-color: $cadfem-denimblue;
                    color: $cadfem-purewhite;
                    height: 55px;
                    line-height: 55px;

                    > span {
                        line-height: 30px;
                    }
                }

                &:before {
                    border-bottom-color: $cadfem-darkgray;
                }
            }

            &:before,
            &:hover:before {
                border-bottom-width: 1px !important;
                border-bottom-color: $cadfem-darkgray !important;
            }

        }
    }

    .toolbar-wrapper--top {
        .pagination,
        .product-switch {
            display: none;
        }
    }

    .toolbar-wrapper--bottom {
        .product-switch {
            display: none;
        }
    }

    .cms-filter-badge {
        @extend .text-small;

        display: inline-block;
        background-color: $cadfem-cornflower;
        color: $cadfem-purewhite;
        font-weight: $font-weight-bold;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: building-units(1);

        @include media-breakpoint-down(sm) {
            display: block;
            width: fit-content;
        }
    }

    .cms-tab {
        .research_module-pagetext {
            margin-bottom: 19px;
        }

        .acc_content2 .acc_content2:not(:first-child) > .acc_content2__headline {
            margin-top: 1px
        }

        .acc_content2 .acc_content2:not(:last-child) > .acc_content2__headline {
            border-bottom-width: 0;
        }

        .acc_content2 .acc_content2.acc_content2--open:last-child > .acc_content2__content {
            border-bottom: 1px solid rgba($cadfem-steamgray, 0.75) !important
        }

        .research_module-results .row a p {
            margin-bottom: 20px;
        }
    }

    #show_in_cms_sidebar-type li.item .form-checkbox-set {
        margin-top: 1px;
    }
}

div[data-type="pageContentByCountryCode"] .cms-filter-skyline {
    display: none;
}
