//-------------------------------------------------------------
// Cookiebot
//-------------------------------------------------------------

// Variables
// General
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: $cadfem-cornflower !important;
    border: 1px solid $cadfem-cornflower !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: $cadfem-darkblue !important;
    border: 1px solid $cadfem-darkblue !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
    background-color: $cadfem-purewhite !important;
    border: 1px solid $cadfem-purewhite !important;
    color: $cadfem-ashgray !important;
}

#CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialogBodyContentControls, #CybotCookiebotDialogBodyContentTitle {
    font-family: 'Neue DIN', 'Segoe UI', Arial, Helvetica, Verdana, sans-serif !important;
}

#CybotCookiebotDialog {
    border: 4px solid #2a2a2a !important;
}

.CookieDeclaration {
    display: none;
}
