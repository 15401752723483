//-------------------------------------------------------------
// Footer Navigation
//-------------------------------------------------------------

.footer-navigation {
    margin: building-units(1) 0;
    text-align: center;
    font-size: $font-size-text-small;

    ul {
        @extend %horizontal-nav;
        justify-content: center;
        margin: 0 auto;

        @media all and (max-width: $screen-sm) {
            flex-wrap: wrap;
        }

        li {
            padding: 0 building-units(1);

            @media all and (max-width: $screen-sm) {
                padding: 0 21px;
                line-height: 22px;
            }
        }
    }
}

