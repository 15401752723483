//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.section_nav {
    &-list {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        list-style: none;
        text-transform: none;
        padding: 0 25px 20px 0;
        margin-bottom: 0;
        transition: all .15s ease;
        white-space: unset;
        z-index: 1;
        min-height: 70px;
        width: 100%;
        align-items: center;

        @include media-breakpoint-only(md) {
            padding: 0 15px 20px 0;
        }

        @include media-breakpoint-only(xs) {
            flex-direction: column;
            padding-right: 0;
        }

        >li {
            position: relative;
            margin-top: 20px;
            margin-right: 20px;

            &:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-only(xs) {
                text-align: center;
                margin-right: 0;
            }
        }
    }

    &--noDropdown {

        .section_nav-list {
            justify-content: center;

            @include media-breakpoint-down(xs) {
                padding: 15px 15px 0;
                flex-direction: column;

                li {
                    margin-bottom: 20px;
                }
            }

            >li:last-child {
                margin-left: inherit;
            }
        }
    }
}