//-------------------------------------------------------------
// THEME COLORS AND DEFINITIONS
//-------------------------------------------------------------
$cadfem-darkblue: #010332;
$cadfem-navyblue: $cadfem-darkblue;
$cadfem-denimblue: #4B73AB;
$cadfem-cornflower: #7198CF;
$cadfem-lightblue: #ABCBEE;
$cadfem-iceblue: #f3f6f9;

$cadfem-darkgray: #6b6b6b;
$cadfem-ashgray: #9B9B9B;
$cadfem-pearlgray: #D2D2D2;
$cadfem-steamgray: #E6E6E6;

$cadfem-silkwhite: #F0F0F0;
$cadfem-purewhite: #FFFFFF;

$cadfem-chesnut: #EC595B;
$cadfem-apricot: #FD9B41;
$cadfem-mustard: #FEEF6D;
$cadfem-pinegreen: #6CCE8F;
$footer-border-color: #DCDCDC;

$white: $cadfem-purewhite;
$black: #000;

$cadfem-specialistfield-acoustics: #554280;
$cadfem-specialistfield-biomechanics: #012C60;
$cadfem-specialistfield-electromagnetics: #008752;
$cadfem-specialistfield-multiphysics: #008C99;
$cadfem-specialistfield-embeddedsoftware: #cd871e;
$cadfem-specialistfield-scalabilityofsimulations: #c3811f;
$cadfem-specialistfield-fluidmechanics: #0079c1;
$cadfem-specialistfield-structuralmechanics: #6d276a;
$cadfem-specialistfield-discreteparticlesimulations: #a01414;
$cadfem-specialistfield-temperaturefields: #e46226;
$cadfem-specialistfield-heattransfer: #e46226;
$cadfem-specialistfield-optics: #cf9b1d;
$cadfem-specialistfield-systemsimulation: #51c6d9;
$cadfem-specialistfield-simulationandmanagement: #012C60;
$cadfem-specialistfield-overviewofsimulation: #012C60;
$cadfem-specialistfield-conceptdesignphasesimulations: #553F81;

$theme-colors: (
        chesnut: $cadfem-chesnut,
        red: $cadfem-chesnut,
        apricot: $cadfem-apricot,
        yellow: $cadfem-apricot,
        mustard: $cadfem-mustard,
        pinegreen: $cadfem-pinegreen,
        green: $cadfem-pinegreen,
        darkblue: $cadfem-darkblue,
        navyblue: $cadfem-navyblue,
        denimblue: $cadfem-denimblue,
        cornflower: $cadfem-cornflower,
        lightblue: $cadfem-lightblue,
        iceblue: $cadfem-iceblue,
        footer: $footer-border-color,
        ashgray: $cadfem-ashgray,
        steamgray: $cadfem-steamgray,
        pearlgray: $cadfem-pearlgray,
        black: $black,
        silkwhite: $cadfem-silkwhite,
        white: $white,
        acoustics: $cadfem-specialistfield-acoustics,
        electromagnetics: $cadfem-specialistfield-electromagnetics,
        embeddedsoftware: $cadfem-specialistfield-embeddedsoftware,
        scalabilityofsimulations: $cadfem-specialistfield-scalabilityofsimulations,
        fluidmechanics: $cadfem-specialistfield-fluidmechanics,
        structuralmechanics: $cadfem-specialistfield-structuralmechanics,
        discreteparticlesimulations: $cadfem-specialistfield-discreteparticlesimulations,
        temperaturefields: $cadfem-specialistfield-temperaturefields,
        heattransfer: $cadfem-specialistfield-heattransfer,
        optics: $cadfem-specialistfield-optics,
        systemsimulation: $cadfem-specialistfield-systemsimulation,
        conceptdesignphasesimulations: $cadfem-specialistfield-conceptdesignphasesimulations,
        biomechanics: $cadfem-specialistfield-biomechanics,
        multiphysics: $cadfem-specialistfield-multiphysics
) !default;


//-------------------------------------------------------------
// Color system
//-------------------------------------------------------------

// Basic Colors

$gray-248: $cadfem-silkwhite;
$gray-237: darken($cadfem-silkwhite, 7%);
$gray-220: darken($cadfem-silkwhite, 14%);
$gray-208: darken($cadfem-silkwhite, 18%);
$gray-199: darken($cadfem-silkwhite, 22%);
$gray-194: darken($cadfem-silkwhite, 24%);
$gray-184: darken($cadfem-silkwhite, 28%);
$gray-178: darken($cadfem-silkwhite, 31%);
$gray-164: darken($cadfem-silkwhite, 35%);
$gray-151: darken($cadfem-silkwhite, 41%);
$gray-117: darken($cadfem-silkwhite, 44%);
$gray-56: darken($cadfem-silkwhite, 79%);
$gray-38: darken($cadfem-silkwhite, 85%);
$black: #000;

$magenta: $cadfem-navyblue;
$magenta-227: lighten($cadfem-navyblue, (227/255) * 100);
$magenta-208: lighten($cadfem-navyblue, (208/255) * 100);
$magenta-188: lighten($cadfem-navyblue, (188/255) * 100);
$magenta-160: lighten($cadfem-navyblue, (160/255) * 100);
$magenta-140: lighten($cadfem-navyblue, (140/255) * 100);

$blue: $cadfem-darkblue;
$blue-175: lighten($cadfem-darkblue, (175/255) * 100);
$blue-121: lighten($cadfem-darkblue, (121/255) * 100);

$blue-rgba-50: rgba($blue, .5);

// Function Colors
$red: $cadfem-chesnut;
$red-208: lighten($cadfem-chesnut, (208/255) * 100);
$red-253: lighten($cadfem-chesnut, (253/255) * 100);
$red-251: lighten($cadfem-chesnut, (251/255) * 100);
$green: $cadfem-pinegreen;
$green-240: lighten($cadfem-pinegreen, (240/255) * 100);
$green-248: lighten($cadfem-pinegreen, (248/255) * 100);

$green-rgba-50: rgba($cadfem-pinegreen, .5);
$magenta-rgba-50: rgba($cadfem-chesnut, .5);

//-------------------------------------------------------------
// Theming
//-------------------------------------------------------------

// Color Definition
$color-brand: $cadfem-darkblue;
$color-text: $cadfem-darkblue;
$text-color: $color-text;
$color-subtext: #6b6b6b;
$color-area: $gray-237;
$color-background: $cadfem-purewhite;
$color-area-hover: $gray-220;
$color-link: $cadfem-darkblue;
$color-success: $cadfem-pinegreen;
$color-error: $cadfem-chesnut;
$color-alert: $cadfem-chesnut;
$color-warning: $cadfem-apricot;
$color-hint: $cadfem-denimblue;
$color-info: $cadfem-darkblue;
$color-tip: $cadfem-darkblue;

// Global
$text-color: $color-text;
$site-background-color: $white;
$link-color: $blue-175;
$link-color-hover: $link-color;

// Component colors
// Accordion
$accordion-border-color: $gray-220;
$accordion-headline-color: $text-color;
$accordion-headline-background-color: transparent;
$accordion-headline-hover-color: $color-brand;
$accordion-headline-hover-background-color: $gray-237;
$accordion-toggle-color: $gray-56;
$accordion-toggle-hover-color: $accordion-headline-hover-color;
$accordion-headline-open-color: $color-brand;
$accordion-headline-open-background-color: $gray-237;
$accordion-toggle-open-color: $accordion-headline-open-color;
$accordion-inactive-color: $gray-184;

// Blockquote
$blockquote-text-link-color: $gray-56;
$blockquote-text-link-hover-color: $blue-175;
$blockquote-card-text-color: $white;

// Brand Bar
$brandbar-background-color: $magenta;
$brandbar-text-color: $white;

// Breadcrumbs
$breadcrumb-current-link-color: $text-color;
$breadcrumb-link-color: $gray-117;

// Briefing Components
$briefing-background-color: #f4f6f8;
$briefing-subline-text-color: #eca859;
$briefing-component-border-color: $gray-194;
$briefing-component-content-light-background-color: $white;
$briefing-component-content-dark-background-color: $gray-56;
$briefing-component-content-dark-text-color: $white;
$briefing-component-image-link-color: $gray-56;
$briefing-component-image-link-dark-color: $white;
$briefing-component-image-link-hover-color: $color-brand;

// Theme Switcher
$theme-switcher-checkbox-border: #ccc;
$theme-switcher-light-background: $white;
$theme-switcher-dark-background: $gray-56;
$theme-switcher-dark-color: $gray-56;


// Toolbar
$toolbar-background-color: $cadfem-darkblue;
$toolbar-text-color: $white;
$toolbar-text-hover-color: $gray-220;

// Brand Bar
$brandbar-background-color: $cadfem-purewhite;
$brandbar-text-color: $white;

// Navbar
$navbar-background-color: $cadfem-purewhite;
$navbar-border-color: $gray-220;
$navbar-link-color: $cadfem-cornflower;
$navbar-link-hover-color: $cadfem-cornflower;
$navbar-link-background-color-hover: $cadfem-silkwhite;
$navbar-link-active-color: $cadfem-cornflower;
$navbar-link-active-background-color: $cadfem-silkwhite;
$navbar-item-hover-border-color: $navbar-link-hover-color;
$megamenu-background-color: $navbar-background-color;
$search-background-color: $navbar-background-color;

// Footer
$footer-background-color: $cadfem-silkwhite;
$footer-text-color: $cadfem-purewhite;
$footer-link-color: $white;
$footer-link-hover-color: $white;

// Buttons
// PRIMARY
$button-primary-color: $white !default;
$button-primary-background-color: $cadfem-cornflower !default;
$button-primary-border-color: $cadfem-darkblue !default;
$button-primary-hover-color: $white !default;
$button-primary-hover-background-color: $cadfem-denimblue !default;
$button-primary-hover-border-color: $cadfem-cornflower !default;
$button-primary-active-color: $white !default;
$button-primary-active-background-color: $cadfem-darkblue !default;
$button-primary-active-border-color: $cadfem-darkblue !default;
$button-primary-disabled-color: $cadfem-ashgray !default;
$button-primary-disabled-background-color: $cadfem-silkwhite !default;
$button-primary-disabled-border-color: $cadfem-steamgray !default;
$button-primary-ghost-border-color: $white !default;
$button-primary-ghost-text-color: $white !default;
$button-primary-ghost-focus-color: $white !default;

// SECONDARY
$button-secondary-color: $cadfem-purewhite;
$button-secondary-background-color: $cadfem-cornflower;
$button-secondary-border-color: $cadfem-cornflower;
$button-secondary-hover-color: $cadfem-purewhite;
$button-secondary-hover-background-color: $cadfem-cornflower;
$button-secondary-hover-border-color: $cadfem-cornflower;
$button-secondary-active-color: $cadfem-purewhite;
$button-secondary-active-background-color: $cadfem-cornflower;
$button-secondary-active-border-color: $cadfem-cornflower;
$button-secondary-disabled-color: $cadfem-ashgray;
$button-secondary-disabled-background-color: $cadfem-steamgray;
$button-secondary-disabled-border-color: $cadfem-steamgray;

// OUTLINE
$button-outline-color: $cadfem-cornflower;
$button-outline-background-color: transparent;
$button-outline-border-color: $cadfem-cornflower;
$button-outline-hover-color: $cadfem-denimblue;
$button-outline-hover-background-color: transparent;
$button-outline-hover-border-color: $cadfem-denimblue;
$button-outline-active-color: $cadfem-purewhite;
$button-outline-active-background-color: $cadfem-darkblue;
$button-outline-active-border-color: $cadfem-darkblue;
$button-outline-disabled-color: $cadfem-ashgray;
$button-outline-disabled-background-color: transparent;
$button-outline-disabled-border-color: $cadfem-steamgray;

// BÙTTON LINK
$button-link-color: $text-color;
$button-link-background-color: transparent;
$button-link-border-color: $gray-178;
$button-link-hover-color: $color-brand;
$button-link-hover-background-color: $gray-237;
$button-link-hover-border-color: $gray-178;
$button-link-active-color: $gray-56;
$button-link-active-background-color: $gray-220;
$button-link-active-border-color: $gray-117;
$button-link-disabled-color: $gray-184;
$button-link-disabled-background-color: $white;
$button-link-disabled-border-color: $gray-220;

// Cards
$card-primary-background-color: $color-brand;
$card-primary-text-color: $white;
$card-primary-border-color: $gray-194;
$card-primary-teleicon-color: $white;

$card-kicker-text-color: $text-color;
$card-title-text-color: $text-color;
$card-default-background-color: $white;
$card-default-text-color: $gray-56;
$card-default-border-color: $gray-194;

$card-dark-grey-text-color: $white;
$card-dark-grey-background-color: $gray-56;
$card-dark-grey-border-color: $gray-56;
$card-dark-grey-icon-color: $white;
$card-footer-border-color: $gray-248;
$card-footer-timestamp-color: $text-color;
$card-footer-teleicon-color: $text-color;

// Checkbox
$form-elements-box-shadow: none;
$checkbox-background-color: $gray-237;
$checkbox-icon-color: $color-text;
$checkbox-border-color: $gray-178;
$checkbox-selected-icon-color: $color-text;
$checkbox-hover-background-color: $gray-220;
$checkbox-active-background-color: $gray-199;
$checkbox-focus-border-color: $blue;
$checkbox-focus-shadow-color: $blue;
$checkbox-icon-color: $green;
$checkbox-error-focus-border-color: $gray-178;

$checkbox-disabled-background-color: $gray-248;
$checkbox-disabled-border-color: $gray-220;
$checkbox-disabled-icon-color: $green-rgba-50;

$checkbox-error-background-color: $red-253;
$checkbox-error-border-color: $red;
$checkbox-error-icon-color: $red;
$checkbox-error-hover-icon-color: $red-251;
$checkbox-error-focus-border-color: $red;
$checkbox-error-focus-shadow-color: $red;

// Collapse-menu
$collapse-menu-item-backgroung-color: $white;
$collapse-menu-border-bottom-color: $gray-220;
$collapse-menu-toggle-color: $gray-56;
$collapse-menu-content-background-color: $gray-248;
$collapse-menu-quicklinks-border-top-color: $gray-220;
$collapse-menu-quicklinks-title-color: $gray-117;

// Info Hints
$info-hint-info-color: $color-info;
$info-hint-warning-color: $color-warning;
$info-hint-alert-color: $color-alert;
$info-hint-hint-color: $color-hint;
$info-hint-tip-color: $color-tip;

// Section Menu
$section-menu-link-color: $blue;
$section-menu-link-hover-color: $section-menu-link-color;

// Comment
$comment-date-color: $gray-117;

// Component-Demonstrator
$component-demonstrator-after-color: $blue;

// Cookie-Banner
$cookie-banner-dark-text-color: $white;
$cookie-banner-dark-link-color: $white;
$cookie-banner-light-background-color: $white;
$cookie-banner-dark-background-color: $gray-56;

// Dos & Donts
$dos-text-color: $white;
$donts-text-color: $white;

// Dropdown
$optionlist-border-color: $gray-178;
$optionlist-background: $white;
$optionlist-color: $gray-56;
$optionlist-hover-background-color: #d0d0d0;
$optionlist-select-background-color: $gray-220;
$optionlist-select-hover-background-color: $gray-199;
$optionlist-select-focus-background-color: $gray-220;
$optionlist-item-dropdown-link-inactive-color: $gray-184;
$optionlist-item-dropdown-toggle-open-color: $gray-199;
$optionlist-item-dropdown-alert-border-color: $red;
$optionlist-item-dropdown-alert-background-color: $red-253;
$optionlist-item-dropdown-alert-focus-color: $red-251;
$optionlist-dropdown-toggle-dark-color: $white;
$optionlist-dropdown-toggle-inactive-color: $gray-184;
$optionlist-dropdown-toggle-inactive-background-color: $white;
$optionlist-dropdown-toggle-inactive-border-color: $gray-220;

// Tabs
$tabs-border-color: $gray-220;
$tabbar-list-color: $gray-117;
$tabbar-list-hover-color: $text-color;
$tabbar-list-active-color: $color-brand;
$tabbar-list-inactive-color: $gray-184;
$tabbar-list-border-color: transparent;
$tabbar-list-hover-border-color: $gray-237;
$tabbar-list-active-border-color: $color-brand;

// Sidebar
$sidebar-link-background-color: $white;
$sidebar-link-headline-color: $color-brand;
$sidebar-link-text-color: $gray-56;
$sidebar-link-hover-background-color: $gray-237;
$sidebar-link-hover-text-color: $color-brand;
$sidebar-link-hover-icon-color: $color-brand;
$sidebar-link-hover-icon-background-color: $gray-237;
$sidebar-link-active-border-left: none;
$sidebar-link-active-background-color-hover: transparent;
$sidebar-link-active-background-color-hover-border-left: none;
$sidebar-title-text-color: $gray-117;
$sidebar-footer-icon-color: $gray-56;
$sidebar-mobile-isopen-background-color: $white;
$sidebar-collapse-button-hover-color: $magenta;

// Hero Image
$hero-image-background-color: $magenta;
$hero-image-text-color: $white;
$hero-image-link-color: $white;
$hero-image-link-hover-color: $blue;

// Horizontal Scroller
$horizontal-scroller-btn-color: $text-color;
$horizontal-scroller-btn-hover-color: $magenta;
$horizontal-scroller-btn-background-color: $white;

// Icon Collection
$icon-collection-tile-border: #efefef;
$icon-collection-header-background-color: $gray-237;
$icon-collection-header-color: $black;
$icon-collection-header-dark-background: $gray-117;
$icon-collection-name-color: $gray-117;
$icon-collection-download-color: #383838;
$icon-search-before-color: $gray-56;

// Images
$images-caption-color: $gray-56;

// Info Hints
$info-hint-info-color: $color-info;
$info-hint-warning-color: $color-warning;
$info-hint-alert-color: $color-alert;
$info-hint-hint-color: $color-hint;
$info-hint-tip-color: $color-tip;

// Input
$input-background-color: $white;
$input-border-color: $gray-178;
$input-color: $gray-56;
$input-hover-background-color: $gray-237;
$input-focus-border-color: $blue;
$input-active-background-color: $gray-220;
$input-disabled-border-color: $gray-220;
$input-disabled-color: $gray-184;
$input-focus-box-shadow-color: $blue-rgba-50;
$input-icon-color: $blue;

// Input with Addon
$addon-input-background-color: $gray-237;
$addon-input-color: $gray-56;
$addon-border-color: $gray-178;

// Input Validation Styling
$input-error-background-color: $red-253;
$input-error-hover-background-color: $red-251;
$input-error-color: $red;

$input-success-background-color: $green-248;
$input-success-hover-background-color: $green-240;
$input-success-color: $green;

// Label
$label-color: $gray-56;
$label-mandatory-color: $blue-175;

// Links
$link-color: $blue-175;
$link-hover-color: $blue-121;

// Link icon
$link-icon-color: $text-color;
$link-icon-hover-color: $text-color;

// Link List Container
$link-list-headline-border-color: $gray-220;
$link-list-link-color: $white;

// Radio Buttons
$radiobutton-background-color: $gray-237;
$radiobutton-border-color: $gray-178;
$radiobutton-hover-background-color: $gray-220;
$radiobutton-active-background-color: $gray-199;
$radiobutton-focus-border-color: $blue;
$radiobutton-focus-shadow-color: $blue;
$radiobutton-icon-color: $magenta;

$radiobutton-disabled-background-color: $gray-248;
$radiobutton-disabled-border-color: $gray-220;
$radiobutton-disabled-icon-color: $magenta-rgba-50;

$radiobutton-error-background-color: $red-253;
$radiobutton-error-border-color: $red;
$radiobutton-error-hover-background-color: $red-251;
$radiobutton-error-focus-border-color: $red;
$radiobutton-error-focus-shadow-color: $red;
$radiobutton-error-icon-color: $red;

$radio-button-deselected-background-color: $gray-237;
$radio-button-deselected-background-color-border: $gray-178;
$radio-button-deselected-text-color: $gray-56;
$radio-button-selected-background-color: $color-brand;

// Living Example Demonstrator
$living-example-demonstrator-background-color: #f4f6f8;
$living-example-demonstrator-top-background-color: $gray-248;
$living-example-demonstrator-top-border-bottom-color: $gray-220;
$living-example-demonstrator-button-background-color: $gray-248;
$living-example-demonstrator-button-icon-color: $gray-56;
$living-example-demonstrator-selected-background-color: #727272;
$living-example-demonstrator-medium-screen-background-color: #e5e5e5;
$living-example-demonstrator-large-screen-background-color: #d9d9d9;
$living-example-demonstrator-xlarge-screen-background-color: #cccccc;
$living-example-demonstrator-notch-color: #757575;

// Navbar
$navbar-background-color: $white;
$navbar-border-color: $gray-220;
$navbar-link-color: $text-color;
$navbar-link-hover-color: $magenta;
$navbar-link-active-color: $text-color;
$navbar-link-active-background-color: $gray-199;
$navbar-item-hover-border-color: $navbar-link-hover-color;
$megamenu-background-color: $navbar-background-color;
$megamenu-link-active-color: $text-color;
$megamenu-submenu-link-active-color: $magenta;
$search-background-color: $navbar-background-color;
$navbar-close-button-color: $gray-56;
$navbar-close-button-hover-color: $magenta;
$navbar-mobile-menu-background-color: $white;
$navbar-mobile-menu-title-color: $magenta;

// Pagination
$pagination-text-color: $text-color;
$pagination-item-background-color: $white;
$pagination-item-border-color: $gray-178;
$pagination-item-hover-text-color: $text-color;
$pagination-item-hover-background-color: $gray-237;
$pagination-item-hover-border-color: $gray-178;
$pagination-item-active-text-color: $gray-56;
$pagination-item-active-background-color: $gray-220;
$pagination-item-active-border-color: $gray-117;
$pagination-item-disabled-text-color: $gray-184;
$pagination-item-disabled-background-color: $white;
$pagination-item-disabled-border-color: $gray-220;

// Placeholder
$placeholder-color: $gray-117;
$placeholder-disabled-color: $gray-184;

// Prev - Next
$pre-next-background-color: $white;
$pre-next-border: $gray-220;
$pre-next-focus-background-color: #ededed;
$pre-next-active-background-color: #d0d0d0;
$pre-next-link-next-color: #383838;
$pre-next-link-next-direction-color: #007faf;

// Teaser
$teaser-primary-text-color: $white;
$teaser-primary-background-color: $color-brand;
$teaser-primary-hover-background-color: $magenta;
$teaser-grey-text-color: $white;
$teaser-grey-background-color: $gray-56;
$teaser-grey-hover-background-color: $black;

$active-menu-color: $color-brand;

$button-text-color: $cadfem-purewhite;
$button-background-color: $color-area;

// Search Result
$search-result-breadcrumb-color: $gray-117;

// Section Menu
$section-menu-link-color: $blue;
$section-menu-link-hover-color: $section-menu-link-color;

// Sections
$section-primary-border-color: $magenta;
$section-primary-background-color: $magenta;
$section-primary-text-color: $white;
$section-secondary-border-color: #4a4a4a;
$section-secondary-background-color: #4a4a4a;
$section-secondary-text-color: $white;
$section-light-white-background-color: $white;
$section-light-grey-border-color: $gray-248;
$section-light-grey-background-color: $gray-248;
$section-image-color: $white;
$section-text-color: $black;
$section-border-bottom-color: $gray-151;
$section-dimmer-white-background-color: rgba(255, 255, 255, 0.5);
$section-dimmer-black-background-color: rgba(0, 0, 0, 0.5);
$section-dimmer-black-text-color: $white;

// Selectbox
$optionlist-border-color: $gray-178;
$optionlist-background: $white;
$optionlist-color: $gray-56;
$optionlist-hover-background-color: $gray-237;
$optionlist-select-background-color: $gray-220;
$optionlist-select-hover-background-color: $gray-199;
$optionlist-inactive-text-color: $gray-184;
$optionlist-selectbox-label-inactive-text-color: $gray-184;
$optionlist-selectbox-label-inactive-background-color: $white;
$optionlist-selectbox-label-inactive-border-color: $gray-220;
$optionlist-selectbox-label-alert-border-color: $red;
$optionlist-selectbox-label-alert-background-color: $red-253;
$optionlist-selectbox-label-alert-focus-background-color: $red-253;
$optionlist-selectbox-label-dark-text-color: $white;

// Sidebar
$sidebar-link-background-color: $white;
$sidebar-link-headline-color: $color-brand;
$sidebar-link-text-color: $gray-56;
$sidebar-link-hover-background-color: $gray-237;
$sidebar-link-hover-text-color: $color-brand;
$sidebar-link-hover-icon-color: $color-brand;
$sidebar-link-hover-icon-background-color: $gray-237;
$sidebar-link-active-border-left: none;
$sidebar-link-active-background-color-hover: transparent;
$sidebar-link-active-background-color-hover-border-left: none;
$sidebar-title-text-color: $gray-117;
$sidebar-footer-icon-color: $gray-56;
$sidebar-mobile-isopen-background-color: $white;
$sidebar-collapse-button-hover-color: $magenta;

// Slider
$scheme-dark: #4a4a4a;
$slider-content-border: #dbdbdb;
$slider-bullet-hover: #9b9b9b;
$slider-content-background-color: rgba($white, 0.9);
$slider-button-prev-next-hover-background-color: $gray-237;
$slider-scheme-dark-text-color: $white;
$slider-scheme-dark-swiper-slide-content-border-color: #9b9b9b;
$slider-bg-white-swiper-slide-image-background-color: $white;
$slider-bg-magenta-swiper-slide-image-background-color: $magenta;
$slider-bg-secondary-swiper-slide-image-background-color: $gray-56;
$slider-swiper-button-prev-next-after-color: $white;
$slider-swiper-button-prev-next-after-text-shadow-color: $black;
$slider-swiper-button-prev-next-hover-background-color: rgba($gray-237, 0.75);

// Switch
$switch-background-color: $gray-237;
$switch-toggle-background-color: $white;
$switch-border-color: $gray-178;
$switch-checked-border-color: $green;
$switch-checked-hover-border-color: darken($green, 2%);
$switch-hover-background-color: $gray-220;
$switch-active-background-color: $gray-199;
$switch-checked-background-color: $green;
$switch-checked-hover-background-color: darken($green, 2%);
$switch-focus-border-color: $blue;
$switch-focus-shadow-color: $blue;
$switch-icon-color: $green;

$switch-disabled-background-color: $gray-248;
$switch-disabled-border-color: $gray-220;

// Syntax Highlight
$syntax-pre-text-color: $black;
$syntax-code-token-comment-text-color: slategray;
$syntax-code-token-punctuation-color: #999;
$syntax-code-token-property-color: #905;
$syntax-code-token-selector-color: #690;
$syntax-code-token-operator-color: #9a6e3a;
$syntax-code-token-operator-background-color: hsla(0, 0%, 100%, 0.5);
$syntax-code-token-atrule-color: #07a;
$syntax-code-token-function-color: #dd4a68;
$syntax-code-token-regex-color: #e90;
$syntax-line-numbers-rows-color: #999;

// Table
$table-border-color: $gray-220;
$table-sorting-icon-color: $gray-178;
$table-sorting-active-icon-color: $gray-184;

// Tabs
$tabs-border-color: $gray-220;
$tabbar-list-color: $gray-117;
$tabbar-list-hover-color: $text-color;
$tabbar-list-active-color: $color-brand;
$tabbar-list-inactive-color: $gray-184;
$tabbar-list-border-color: transparent;
$tabbar-list-hover-border-color: $gray-237;
$tabbar-list-active-border-color: $color-brand;

// Teaser
$teaser-primary-text-color: $white;
$teaser-primary-background-color: $color-brand;
$teaser-primary-hover-background-color: $magenta;
$teaser-grey-text-color: $white;
$teaser-grey-background-color: $gray-56;
$teaser-grey-hover-background-color: $black;
$teaser-header-link-color: $white;
$teaser-text-link-color: $white;
$teaser-link-label-text-color: $white;
$teaser-magenta-background-color: rgba($magenta, 0.7);
$teaser-grey-background-color: rgba($gray-56, 0.7);
$teaser-rey-content-text-color: $white;

// Tile
$tile-background-color: $white;
$tile-border-color: $gray-164;
$tile-text-color: $gray-38;
$tile-header-background-color: $gray-237;

// Design Tokens Typography

// Toolbar
$toolbar-background-color: $color-background;
$toolbar-text-color: $white;
$toolbar-text-hover-color: $gray-220;

//shadow
$shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);


//-------------------------------------------------------------
// Fonts
//-------------------------------------------------------------

// General
$font-family-sans-serif: "Neue DIN", sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-icon: "TeleIcon";

$font-size-base: 16px;
$line-height-base: 26px;

$font-weight-light: 200; //extralight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600; //semibold
$font-weight-bold: 700; //bold
$font-weight-extrabold: 800; //extrabold
$font-weight-ultra: 900; //black (mhm no, do not use: ultra == extra == 800)
$font-weight-black: 900; //black

$font-weight-base: $font-weight-regular;


//-------------------------------------------------------------
// Base Unit Definitions
//-------------------------------------------------------------

$building-unit: 15px;
$rem-baseline: $building-unit;

//-------------------------------------------------------------
// Media queries - Breakpoints
//-------------------------------------------------------------

// Small screen / tablet
$screen-xs: 300px;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1401px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// extra large screen / wide desktop
$screen-xl: 1601px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1401px,
        xl: 1601px
) !default;


//-------------------------------------------------------------
// Container Widths
//-------------------------------------------------------------

// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        md: 1024px,
        lg: 1400px,
        xl: 1600px
) !default;

//-------------------------------------------------------------
// Grid Column Definitions
//-------------------------------------------------------------

// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

//-------------------------------------------------------------
// Copy Text
//-------------------------------------------------------------

$font-size-default: 16px;
$line-height-default: 26px;

$font-size-text-x-large: 60px;
$line-height-text-x-large: 80px / $font-size-text-x-large;

$font-size-text-x-large-md: 30px;
$line-height-text-x-large-md: 45px / $font-size-text-x-large-md;

$font-size-text-large: 18px;
$line-height-text-large: 27px / $font-size-text-large;

$font-size-text-small: 13px;
$line-height-text-small: 20px;

$font-size-text-x-small: 12px;
$line-height-text-x-small: 14px / $font-size-text-x-small;

//-------------------------------------------------------------
// Headings
//-------------------------------------------------------------

$font-size-h1-l: 32px;
$line-height-h1-l: 40px;

$font-size-h1-sm: 22px;
$line-height-h1-sm: 30px;

$font-size-h2-l: 28px;
$line-height-h2-l: 36px;
$font-size-h2-sm: 18px;
$line-height-h2-sm: 26px;

$font-size-h3: 24px;
$line-height-h3: 32px;

$font-size-h3-l: 24px;
$line-height-h3-l: 32px;

$font-size-h3-sm: 16px;
$line-height-h3-sm: 24px;

$font-size-h4: 20px;
$line-height-h4: 25px;

$font-size-h5: 16px;
$line-height-h5: 24px;

$font-size-h6: 13px;
$line-height-h6: 21px;

$font-size-display: 60px;
$line-height-display: 72px / $font-size-display;

$margin-headings: 15px 0;
//-------------------------------------------------------------
// Icons
//-------------------------------------------------------------
$project-icon-font-path: "../assets/fonts/cadfemicon";
$icon-font-path: "../assets/fonts/teleicon";
$icon-image-path: "../assets/images/icons";

$icon-solid-font-weight: $font-weight-semibold;

$icon-sizes: (
        12,
        15,
        18,
        20,
        24,
        32,
        36,
        48,
        50,
        64
) !default;

$sizes: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
) !default;
