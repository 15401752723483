//-------------------------------------------------------------
// Tooltip
//-------------------------------------------------------------

// Variables
$tooltip-max-width: 200px;

// General
.tooltip {
  // max-width: $tooltip-max-width;
  padding: building-units(1);
  border-radius: rem(6px);
  border: 1px solid $gray-178;
  text-align: left;
  background: $white;
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 20000;

  &--shown {
    display: block;
  }
}
