//-------------------------------------------------------------
// Switch
//-------------------------------------------------------------

// Variables
$switch-height: building-units(2);
$switch-width: building-units(4);
$switch-border-radius: $switch-height / 2;
$switch-shadow: $form-elements-box-shadow;

.switch {
    line-height: $switch-height;
    margin: building-units(1) 0;
    min-height: $switch-height;
    padding-right: $switch-width;
    position: relative;

    
}

.switch-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    height: $switch-height;
    width: $switch-width;
    cursor: pointer;

    + label {
        @extend %text-default;

        display: inline-block;
        padding-right: building-units(1);
        position: relative;
        vertical-align: middle;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            border: 1px solid $switch-border-color;
            border-radius: $switch-border-radius;
            margin-right: -#{$switch-width};
            transition: opacity .2s ease-in-out;
            height: calc(#{$switch-height} - 2px);
            top: 0;
        }
        
        &::before {
            right: 0;
            width: calc(#{$switch-width} - 2px);
            background: $switch-background-color;
        }
        
        &::after {
            right: $switch-height;
            width: calc(#{$switch-height} - 2px);
            line-height: calc(#{$switch-height} - 2px);
            background: $switch-toggle-background-color;
            transition: right 40ms ease-in-out;
        }            

    }

    &:checked {
        + label {
            &::before {
                background: $switch-checked-background-color;
                border: 1px solid $switch-checked-border-color;
            }
            &::after {
                right: 0;
            }
        }
    }

    &:hover {
        + label {
            &::before {
                background: $switch-hover-background-color;
            }    
        }

        &:checked {
            + label {
                &::before {
                    background: $switch-checked-hover-background-color;
                    border: 1px solid $switch-checked-hover-border-color;
                }
            }
        }
    }

    &:active {
        outline: 0;

        + label {
            &::before {
                background: $switch-active-background-color;
            }
        }
    }

    &:focus:not(:active) {
        outline: 0;

        + label {
            &::before {
                border-color: $switch-focus-border-color;
                box-shadow:   $switch-shadow $switch-focus-shadow-color;
            }
        }
    }

    // Disabled

    &:disabled {
        cursor: not-allowed;

        + label {
            cursor: not-allowed;

            &::before,
            &::after {
                border-color: $switch-disabled-border-color;
                background:   $switch-disabled-background-color;
            }
        }
    }
}