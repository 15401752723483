//-------------------------------------------------------------
// SVG Icons
//-------------------------------------------------------------

//Variables
$icon-image-path: "../assets/images/icons";

.svgicon {
    position: relative;
    bottom: 1px;
    vertical-align: middle;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;

    @each $size in $icon-sizes {
        &.icon-#{$size} {
            height: rem($size+px);
            width: rem($size+px);
        }
    }
    
    &.icon-in-progress {
        background-image: url('#{$icon-image-path}/progress-icon.svg');
    }

    &.icon-twitter {
        background-image: url('#{$icon-image-path}/twitter_icon.svg');
    }

    &.icon-instagram {
        background-image: url('#{$icon-image-path}/instagram_icon.svg');
    }

    &.icon-facebook {
        background-image: url('#{$icon-image-path}/facebook_icon.svg');
    }

    &.icon-sidebar {
        background-image: url('#{$icon-image-path}/icon-sidebar.svg');
    }
}
