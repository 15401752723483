//-------------------------------------------------------------
// Section
//-------------------------------------------------------------

.section {
  border-top: 1px solid $gray-151;
  border-bottom: 1px solid $section-border-bottom-color;
  color: $section-text-color;

  overflow: hidden;
  width: 100%;
  position: relative;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &--fullwidth {
    margin-left: -12px;
    margin-right: -12px;

    @include media-breakpoint-up(md) {
      width: 100vw;
      position: relative;
      margin-left: -50vw;
      margin-right: 0;
      left: 50%;
    }
  }

  //PADDING
  &--padding-huge {
    .section__content {
      padding-top: building-units(5);
      padding-bottom: building-units(5);
    }
  }

  &--offset-huge {
    .section__content {
      padding-right: building-units(7) + 1;
      padding-left: building-units(7) + 1;
    }
  }

  //BACKGROUND COLORS
  &--bg-white {
    background-color: $section-light-white-background-color;
  }

  &--bg-light-grey {
    border-color: $section-light-grey-border-color;
    background-color: $section-light-grey-background-color;
  }

  &--bg-primary {
    border-color: $section-primary-border-color;
    background-color: $section-primary-background-color;
    color: $section-primary-text-color;
  }

  &--bg-secondary {
    border-color: $section-secondary-border-color;
    background-color: $section-secondary-background-color;
    color: $section-secondary-text-color;
  }

  &--image {
    color: $section-image-color;
  }

  //DIMMER
  &--dimmer-black {
    background-color: $section-dimmer-black-background-color;

    ~ .section__content {
      color: $section-dimmer-black-text-color;
    }
  }

  &--dimmer-white {
    background-color: $section-dimmer-white-background-color;

    ~ .section__content {
      color: $text-color;
    }
  }

  /**
   * Elements
   */
  &__dimmer {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    z-index: 1;
  }

  &__content {
    @extend .container;
    position: relative;
    z-index: 2; //to lay over the dimmer container
    padding: building-units(3) 0; //default padding

    .section--fullwidth & {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

// browserhack edge
_:-ms-lang(x),
_:-webkit-full-screen,
.section--fullwidth {
  width: calc(100vw - 1em);
  margin-left: calc(-50vw + (1em / 2));
  -ms-overflow-style: none;
}
