//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$color-tile-border: #efefef;

.color-tile {
  display: flex;
  flex-direction: column;
  padding-bottom: building-units(2);
  border: 1px solid $color-tile-border;

  &__name {
    display: block;
    font-weight: 600;
    font-size: $font-size-h4;
    line-height: $line-height-h4;
    letter-spacing: 0;
    text-transform: uppercase;
    padding-left: building-units(1);
  }

  &__hex {
    font-weight: 600;
    padding-left: building-units(1);
  }

  &__variable {
    padding-left: building-units(1);
  }

  &__color {
    height: 132px;
    //padding: building-units(1);
    border-bottom: 1px solid $color-tile-border;
    margin-bottom: building-units(2);

    .color-link {
      display: block;
      height: 100%;

      &:hover {
        background: rgba(0,0,0,0.35)
      }
    }
  }
}

.color-tile-inline {
  display: inline-block;
  text-transform: uppercase;

  &__color {
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 1px solid $color-tile-border;
  }
}