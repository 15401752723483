//-------------------------------------------------------------
//Reference Detail Added Value Trio
//-------------------------------------------------------------

// Variables

// General
.addedvaluetrio {

    &__content {
        text-align: center;
        margin-bottom: 4px;

        @include media-breakpoint-down(sm) {
            display: flex;
            margin-bottom: building-units(1);
        }
    }

    &__icon {
        @include media-breakpoint-down(sm) {
            margin-right: building-units(1);
        }
    }

    &__headline {
        margin: auto 0;
    }

    .card_cf {
        height: 100%;
        display: block;
        padding-bottom: 0;
        margin-bottom: 20px;
    }

    @include media-breakpoint-down(xs) {
        .section_cf__content {
            padding: building-units(1);
        }
    }
}

@include media-breakpoint-down(sm) {
    .cadfemcomplexicon.addedvaluetrio__icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        background-size: 158.72px 1003.52px !important
    }
    .cadfemcomplexicon.addedvaluetrio__icon.icon_task_active {
        background-position: background-position-for-size('task', 'active', 32) !important;
    }
    .cadfemcomplexicon.addedvaluetrio__icon.icon_solution_active {
        background-position: background-position-for-size('solution', 'active', 32) !important;
    }
    .cadfemcomplexicon.addedvaluetrio__icon.icon_use_active {
        background-position: background-position-for-size('use', 'active', 32) !important;
    }
}
