//-------------------------------------------------------------
// Storytelling Module
//-------------------------------------------------------------

// Variables

.storytelling-module {
    position: relative;
    max-width: 960px;
    counter-reset: count 0;

    @include media-breakpoint-up(lg) {
        max-width: 1067px;
    }

    &__container {
        margin: 0 auto;
        position: relative;
        list-style: none;
        padding: 0;

        @include media-breakpoint-up(md) {
            z-index: 1;

            &--horizontal {
                padding-bottom: 20px;
            }
        }
    }

    &__wrapper {
        position: relative;

        @include media-breakpoint-up(md) {
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: -webkit-transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
            transform: translate3d(0px, 0, 0);
        }
    }

    &__slide {
        counter-increment: count;
        position: relative;

        @include media-breakpoint-up(md) {
            flex-shrink: 0;
            width: 100%;
            transition-property: -webkit-transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
        }
    }

    &__image {
        overflow: hidden;
        display: inline-block;
        width: 100%;

        >img {
            width: 100%;
        }
    }

    &__slide-content {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    &__slide-control {
        display: none;

        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 50px; // TODO offset< positioning
            right: 15px;
            z-index: 3;

            .storytelling-module__slide--offset & {
                bottom: 50px;
            }
        }
    }

    &__scrollbar {
        border-radius: 3px;
        position: relative;
        touch-action: none;
        background: $cadfem-silkwhite;
        display: none;
        cursor: pointer;

        .storytelling-module__container--horizontal>& {
            display: inherit;
            position: absolute;
            left: 0;
            z-index: 50;
            height: 5px;
            width: 100%;
            top: 555px;

            @include media-breakpoint-up(lg) {
                top:615px;
            }
        }

        .storytelling-module__container--vertical>& {
            display: inherit;
            position: absolute;
            right: 12px;
            top: 0;
            z-index: 50;
            width: 5px;
            height: 100%;
        }
    }

    &__scrollbar-drag {
        height: 20px;
        width: 20px;
        position: relative;
        background: $cadfem-cornflower;
        border-radius: 50%;
        left: 0;
        top: -8px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4) // cursor: grab;
    }

    &__scrollbar-cursor-drag {
        cursor: move;
    }

    &__scrollbar-lock {
        display: none;
    }

    &__slideshow-expander {
        border-radius: 0;
        height: 75px;
        max-height: none;
        border: none;

        .storytelling-module--expanded & {
            display: none;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }

        .cadfemicon {
            width: 100%;
            display: block;
        }
    }
}

.storytelling-slide {
    h2.topline.text-center {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 1.4px;
        margin-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
        display: none;

        &--title-slide {
            display: block;

            .storytelling-slide__secondary {
                display: none;
            }
        }

        .storytelling-module--expanded & {
            display: block;

            &--title-slide .storytelling-slide__secondary {
                display: block;
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: block;
    }

    &__main {
        display: flex;
        flex-flow: column;
        overflow: hidden;
        position: relative;
        background: $cadfem-iceblue;
        width: 100%;

        @include media-breakpoint-down(sm) {
            &+.storytelling-slide__secondary {
                background: $cadfem-iceblue;
            }
        }

        @include media-breakpoint-up(md) {
            flex-flow: row;
            height: 540px;
        }

        @include media-breakpoint-up(lg) {
            height: 600px;
        }

        @include media-breakpoint-down(sm) {
            @include media-breakpoint-up(lg) {
                height: 600px;
            }
        }

        &--bg-white {
            border: 1px solid $cadfem-iceblue;
            background: $cadfem-purewhite;

            @include media-breakpoint-down(sm) {
                border-bottom: none;

                &+.storytelling-slide__secondary {
                    border: 1px solid $cadfem-iceblue;
                    border-top: none;
                    background: $cadfem-purewhite;
                }
            }
        }

        &--bg-lightblue {
            background: $cadfem-lightblue;

            @include media-breakpoint-down(sm) {
                &+.storytelling-slide__secondary {
                    background: $cadfem-lightblue;
                }
            }
        }

        &--layout-1-1-2 {
            .storytelling-slide__title {
                padding-top: 30px;
            }

            @include media-breakpoint-up(md) {
                flex-flow: row wrap;

                .storytelling-slide__title {
                    flex: 1 1 auto;
                    width: 100%;
                    height: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0;

                    h2 {
                        margin: 0;
                    }
                }

                .storytelling-slide__content,
                .storytelling-slide__image {
                    height: 75%;
                }
            }
        }
    }

    &__content {
        padding: 30px 15px;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(md) {
            padding: 45px;
        }

        p {
            font-size: rem($font-size-text-small);
            line-height: $line-height-text-small;

            &.text-small {
                font-size: rem($font-size-text-small);
                line-height: $line-height-text-small;
                letter-spacing: 0px;
            }
        }

        h2:first-child {
            margin-top: 0;
        }

        .h1:first-child {
            font-size: 2.13333rem;
            font-weight: $font-weight-bold;
            line-height: 40px;
            letter-spacing: 2.4px;
            margin: 30px 0;

            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 30px;
                letter-spacing: 1.6px;
                font-weight: $font-weight-bold;
            }
        }

        h2.topline {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 1.4px;
            margin-bottom: 30px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__logo {
        // Bugfix: CADFEMEB-1819
        max-width: 170px;
        max-height: 40px;
        width: auto;
        height: auto;
    }

    &__image {
        position: relative;

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: auto;
            object-position: center;
            object-fit: cover;
        }
    }

    &__background-image {
        order: 0;

        img {
            display: block;
            margin: 0 auto;
            object-position: center;
            object-fit: cover;

            @include media-breakpoint-down(md) {
                /*width: 100%;*/
                height: auto;
                max-width: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }

    &__card {
        padding: rem(15px);

        p {
            font-size: rem($font-size-text-small);
            line-height: $line-height-text-small;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            z-index: 2;
            border-radius: rem(8px);
            padding: rem(25px);
            bottom: 60px;
            width: calc(50% - 60px);
            background: rgba(255, 255, 255, .75);
            box-shadow: 0 5px 24px 0 rgba(0, 0, 0, .05);

            .storytelling-slide__main--card-left & {
                left: 60px;
            }

            .storytelling-slide__main--card-right & {
                right: 60px;
            }
        }
    }

    &__secondary {
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(sm) {
            &--expandable {
                height: 75px;
                overflow: hidden;
                transition: height 0.4s;
                padding-bottom: 75px;
            }

            &.expanded {
                height: auto;
            }
        }

        @include media-breakpoint-up(md) {
            flex-flow: row;
            height: auto;
            min-height: 80px;
            background: transparent;
            padding-top: 20px;
        }
    }

    &__secondary-content {
        flex: 1 1 auto;
        padding: 15px;
        position: relative;
        z-index: 2;
        font-size: rem($font-size-text-small);
        line-height: $line-height-text-small;

        @include media-breakpoint-up(md) {
            max-width: 100% * 8/12;
            min-width: 50%;
        }

        @include media-breakpoint-up(lg) {
            max-width: 100% * 8/12;
        }

        p {
            margin-bottom: 20px;
            font-size: rem($font-size-text-small);
            line-height: $line-height-text-small;
        }
    }

    &__secondary-expander {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $cadfem-iceblue;
        z-index: 3;
        color: #7198cf;
        border-radius: 0;
        height: 75px;
        max-height: none;
        border: none;

        &:hover,
        &:focus,
        &:active {
            background: $cadfem-iceblue;
        }

        .storytelling-slide__main--bg-lightblue+.storytelling-slide__secondary & {
            background: $cadfem-lightblue;
        }

        .storytelling-slide__main--bg-white+.storytelling-slide__secondary & {
            background: $cadfem-purewhite;
        }


        @include media-breakpoint-up(md) {
            display: none;
        }

        .cadfemicon {
            display: block;
            width: 100%;
        }
    }

    &__secondary-expander-expand {
        .expanded & {
            display: none;
        }
    }

    &__secondary-expander-collapse {
        display: none;

        .expanded & {
            display: block;
        }
    }

    &__slide-number {
        display: none;
        position: absolute;
        top: 25px;
        right: 15px;
        color: $cadfem-silkwhite;
        font-weight: $font-weight-semibold;
        font-size: rem($font-size-text-x-large);
        line-height: $line-height-text-x-large;
        z-index: 1;


        @include media-breakpoint-up(md) {
            display: inherit;
        }

        &::before {
            content: counters(count,"",decimal-leading-zero);
        }
    }

    &--title-slide {
        .storytelling-slide__main {
            @include media-breakpoint-up(md) {
                align-items: flex-end;
                justify-content: flex-end;

                p {
                    font-size: rem($font-size-default);
                    line-height: $line-height-default;
                }
            }
        }

        .storytelling-slide__content {
            order: 1;

            @include media-breakpoint-up(md) {
                width: 50%;
                margin-bottom: 50px;
            }
        }

        .storytelling-module__slideshow-expander {
            order: 1;
        }

        .storytelling-slide__background-image {
            order: 0;
        }

    }

    &__background-slideshow {
        @include media-breakpoint-down(sm) {
            position: relative;
        }

        $image-count: 4;
        $presentation-time: 2;
        $crossfade-time: 3;
        $total-animation-duration: ($presentation-time + $crossfade-time) * $image-count;

        @keyframes titleSlideshow {
            0% {
                opacity: 1;
            }

            #{ (($presentation-time / $total-animation-duration) * 100%) } {
                opacity: 1;
            }

            #{ (($presentation-time + $crossfade-time) / $total-animation-duration * 100%) } {
                opacity: 0;
            }

            #{ 100% - ($crossfade-time/$total-animation-duration * 100%) } {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        >img,
        >picture {
            animation-name: titleSlideshow;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: $total-animation-duration * 1s;
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            @include media-breakpoint-down(md) {
                &:first-child {
                    position: relative;
                    top: auto;
                    left: auto;
                }
            }

            @for $i from 1 through $image-count {
                &:nth-of-type(#{$i}) {
                    z-index: $image-count + 1 - $i;
                    animation-delay: ($i - 1) * ($presentation-time + $crossfade-time) * 1s;
                }
            }
        }
    }

    &--call-to-action-slide {
        .storytelling-slide__main {
            text-align: center;
            color: $cadfem-purewhite;
            background: linear-gradient(135deg, $cadfem-darkblue 0%, #006498 100%);
            justify-content: center;
            align-items: center;
        }


        .storytelling-slide__content {
            max-width: 100%;
            @include media-breakpoint-up(md) {
                width: 9/12*100%;
            }
        }

        p {
            font-size: rem($font-size-text-large);
            line-height: $line-height-text-large;

            @include media-breakpoint-up(md) {
                font-size: rem(30px);
                line-height: rem(45px);
                font-weight: $font-weight-light;
            }
        }

        .storytelling-module__slide-control {
            color: $cadfem-purewhite;
        }
    }

    &--text-image-slide {

        //Cadfem-1797
        .storytelling-slide__main--layout-1-2 {
            .slide-next {
                right: (2/3*100%);
            }
        }

        //Cadfem-1797
        .storytelling-slide__main--layout-1-1 {
            .slide-next {
                right: 50%;
            }
        }

        @include media-breakpoint-up(md) {
            .storytelling-slide__main--layout-1-2 {
                .storytelling-slide__image {
                    flex: 1 1 auto;
                    width: (8 / 12 * 100%);
                }

                .storytelling-slide__content {
                    flex: 1 1 auto;
                    width: (4 / 12 * 100%);
                }
            }

            .storytelling-slide__main--layout-2-1 {
                .storytelling-slide__image {
                    flex: 1 1 auto;
                    width: (8 / 12 * 100%);
                }

                .storytelling-slide__content {
                    flex: 1 1 auto;
                    width: (4 / 12 * 100%);
                }
            }

            .storytelling-slide__main--layout-1-1,
            .storytelling-slide__main--layout-1-1-2 {

                .storytelling-slide__image,
                .storytelling-slide__content {
                    flex: 1  1 auto;
                    width: (6 / 12 * 100%);
                }
            }

            .storytelling-slide__main--layout-1-1-vertical {
                flex-flow: column nowrap;

                .storytelling-slide__image,
                .storytelling-slide__content {
                    flex: 1 1 auto;
                    width: 100%;

                    //Cadfem-1794
                    p.text-small {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0px;
                    }
                }

                .storytelling-slide__image {
                    display: flex;
                    flex-flow: row nowrap;

                    >picture,
                    >img {
                        flex: 1 1 auto;
                        width: 50%;
                    }
                }
            }

            .storytelling-slide__main--text-left {
                .storytelling-slide__content {
                    order: 1;
                }

                .storytelling-slide__image {
                    order: 2;
                }
            }
        }
    }

    &--testimonial-slide {
        .storytelling-slide__main {
            &:not(.storytelling-slide__main--big-picture) {
                background: linear-gradient(to bottom, $cadfem-iceblue 0%, $cadfem-purewhite 100%);
            }

            //Cadfem-1797
            &--layout-2-1 {

                .storytelling-slide__content>p {

                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0;
                    margin-bottom: 20px;
                }

                .slide-next {
                    right: (4/12*100%);
                }
            }

            // Cadfem-1797
            @include media-breakpoint-down(sm) {
                display: -ms-flex;
                flex-direction: column;
            }

            .storytelling-slide__content {
                @include media-breakpoint-down(sm) {
                    order: 2;
                }

                p {
                    text-align: center;
                }
            }

            .storytelling-slide__image {
                @include media-breakpoint-down(sm) {
                    order: 1;
                }
            }


            &--big-picture {

                @include media-breakpoint-up(md) {
                    .storytelling-slide__image {
                        flex: 1 1 auto;
                        width: (4 / 12 * 100%);
                        display: flex;
                        flex-flow: column nowrap;

                        >picture,
                        >img {
                            flex: 1 1 auto;
                            min-height: 50%;
                        }
                    }

                    .storytelling-slide__content {
                        flex: 1 1 auto;
                        width: (8 / 12 * 100%);
                    }
                }
            }
        }

        .quote__content {
            max-width: 100%;

            p {
                font-size: rem($font-size-default);
                line-height: $line-height-default;
                margin-bottom: 45px;
                text-align: center;

                @include media-breakpoint-up(md) {
                    font-size: rem($font-size-text-large);
                    line-height: $line-height-text-large;
                }
            }
        }
    }

    &--background-image-slide {
        .storytelling-slide__content {
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
    }

    &--video-slide {
        .storytelling-slide__content {
            order: 0;

            @include media-breakpoint-up(md) {
                width: 100%;
                z-index: inherit;
            }
        }

        h2 {
            position: relative;
            z-index: 3;
        }

        .storytelling-module__slide-control {
            z-index: 3;
        }

        .storytelling-slide__image {
            order: 1;
            position: relative;

            @include media-breakpoint-up(md) {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .storytelling-module__wrapper .storytelling-module__slide:not(:last-child) .storytelling-module__slide-control.slide-next {
        display: block;
    }

    .storytelling-module__wrapper .storytelling-module__slide:not(:first-child) .storytelling-module__slide-control.slide-next {
        color: transparent;

        .icon-navigation-arrow {
            color: $cadfem-cornflower;
        }
    }

    //hide "return to start slide" button for all slides except last
    .storytelling-module__wrapper .storytelling-module__slide:last-child .storytelling-module__slide-control.slide-start {
        display: block;
    }
}
