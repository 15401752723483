//-------------------------------------------------------------
// Breadcrumb
//-------------------------------------------------------------

// Variables
$breadcrumb-current-link-color: $cadfem-ashgray;
$breadcrumb-link-color: $cadfem-cornflower;

// General

.breadcrumbs-wrapper {
    margin-block-start: 1em;
    margin-block-end: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }

    .breadcrumbs {
        flex: 1 1 auto;
        align-self: auto;
        order: 0;
        width: 100%;
        min-width: fit-content !important;
    }

    #mana_ajax_wrapper_breadcrumbs {
        width: 100%;
    }

    .shariff {
        flex: 0 1 auto;
        align-self: auto;
        width: 100%;
        max-width: 100%;
        text-align: right;
        order: 1;
        margin-top: 10px;

        @include media-breakpoint-only(xs) {
            max-width: 100%;
            text-align: left;
        }

        .orientation-vertical li {
            display: inline-block;
            width: auto;
            margin: 0 7.5px;
            border-radius: 50%;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        li,
        li a {
            height: 30px;
            width: 30px;
        }
    }
}

.breadcrumbs {
    &__list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        margin-block-start: 0;
        margin-block-end: 0;

        &--ellipsis-open {

            .breadcrumbs__item--ellipsis {
                display: none;
            }

            .breadcrumbs__item--hidden {
                display: block;
            }
        }
    }

    &__item {
        position: relative;
        padding: 0 20px 0 0;

        &--ellipsis > .breadcrumbs__link {
            border-radius: 50%;
            margin-top: 5px;
            text-align: center;
            font-weight: $font-weight-semibold;
            color: $breadcrumb-link-color;
            background-color: transparent;
            height: 19px;
            width: 19px;

            &:hover {
                text-decoration: none;
                background-color: $cadfem-silkwhite;
                color: $cadfem-denimblue;
            }
        }

        &:not(:first-child):before {
            position: absolute;
            top: 6.5px;
            left: -10px;
            content: " ";
            height: 12px;
            border-right: 1px solid $cadfem-ashgray;
        }
    }

    /**
     * hide items, on click they will appear and the ellipsis will be hidden
     */
    &__item--hidden {
        display: none;
    }

    &__link {
        @extend %text-small;

        margin: 0;
        color: $breadcrumb-link-color;
        text-decoration: none;
        font-weight: $font-weight-semibold;

        &:hover {
            text-decoration: underline;
        }

        &--current {
            @extend %text-small;

            border-bottom: none;
            font-weight: $font-weight-semibold;
            color: $breadcrumb-current-link-color;

            &:after {
                display: none;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

body.socialshare_hide .shariff { display: none; opacity: 0; }
