//-------------------------------------------------------------
// FakePager
//-------------------------------------------------------------

/*****************************************
 * fakepage
 *****************************************/
.fakepage__container {
    > div {
      &:nth-child(n+5) {
        display: none;
      }

      &.fakepage--show {
        display: flex;
      }
    }

    .fakepage_button {
        display: flex !important;
        justify-content: center;

        &.hidden {
            display: none !important;
        }
    }
}
