//-------------------------------------------------------------
// Neue DIN
//-------------------------------------------------------------

$font-path: "../assets/fonts/neuedin";

/* light | 200 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: 200;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-Light'),
    url('#{$font-path}/NeueDIN-Light.woff2') format('woff2');
}
/* normal | 400 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: normal;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-Regular'),
    url('#{$font-path}/NeueDIN-Regular.woff2') format('woff2');
}
/* medium | 500 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: 500;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-Medium'),
    url('#{$font-path}/NeueDIN-Medium.woff2') format('woff2');
}
/* semi-bold | 600 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: 600;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-SemiBold'),
    url('#{$font-path}/NeueDIN-SemiBold.woff2') format('woff2');
}
/* bold | 700 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: bold;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-Bold'),
    url('#{$font-path}/NeueDIN-Bold.woff2') format('woff2');
}
/* extra-bold | 800 */
@font-face {
  font-family: 'Neue DIN';
  font-weight: 800;
  font-display: block;
  src:
    local('Neue DIN'),
    local('NeueDIN-XBold'),
    url('#{$font-path}/NeueDIN-XBold.woff2') format('woff2');
}
