//-------------------------------------------------------------
// PushMenu
//-------------------------------------------------------------

.push-menu {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100%;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.35s;
    overflow-y: auto;
    overflow-x: hidden;

    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
    }

    &__header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 10px building-units(1);

        .cadfemicon {
            width: 30px;
            height: 30px;
            font-size: 28px;
            color: $black;

            &:hover {
                color: $cadfem-cornflower;
            }
        }
    }

    &__close {
        color: $black;

        .cadfemicon {
            font-size: 28px;
        }
    }

    /**
     * QUICKLINKS
     */
    &__directlinks {
        border-bottom: none !important;
    }

    &__quicklinks {
        margin-top: building-units(1);

        h6 {
            @extend %text-small;

            font-weight: $font-weight-semibold;
            color: $cadfem-ashgray;
            margin-bottom: 5px;

            .cadfemicon {
                width: 16px;
                height: 16px;
                font-size: 16px;
            }
        }
    }

    &__quicklinks-link {
        @extend %text-small;

        font-weight: $font-weight-base;
        line-height: rem(28px);
        color: $cadfem-darkblue;

        float: left;
        padding-right: building-units(1);
        margin-bottom: 0;
    }

    /************************************
     * TEASER
     ************************************/
    &__teaser {
        display: flex;
        flex-flow: row;
        border-bottom: 1px solid $cadfem-steamgray;
        width: 100%;
        padding: 18px 7.5px;

        @include media-breakpoint-down(md) {
            flex-flow: column;
        }
        &:hover {
            color: $cadfem-darkblue; //1066
            .push-menu__teaser-description {
              //  color: $cadfem-darkblue;
            }
        }
    }

    &__teaser-image {
        border-radius: 8px;
        width: 100%;
        max-width: 304px;
        margin-right: building-units(1);

        @include media-breakpoint-down(sm) {
            margin-bottom: building-units(1);
        }

        @include media-breakpoint-only(xs) {
            max-width: 100%;
        }
    }

    &__teaser-content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        max-width: 50%;
    }

    &__teaser-title {
        font-weight: $font-weight-bold;
        font-size: $font-size-text-small;
        color: $cadfem-darkblue;
        letter-spacing: 0;
        line-height: 1;
        display: block;
        margin-bottom: building-units(1);
    }

    &__teaser-description {
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    /************************************
     * REST
     ************************************/
    &__toggle {
        width: 100%;
        height: 30px;
        display: block;
        margin-bottom: 0;
        text-align: center;

        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.7px;
        color: $cadfem-cornflower;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        cursor: pointer;

        &:hover {
            color: $cadfem-cornflower;
        }
    }

    &__list {
        list-style: none;
        padding: 0 building-units(1);
    }

    &__listitem {
        display: flex;
        flex-flow: row;
        align-items: center;

        min-height: 50px;
        border-bottom: 1px solid #e6e6e6;
    }

    &__listitem--teaser {
        flex-flow: column;
    }

    &__label {
        width: 100%;
        margin-bottom: 0;
        justify-content: space-between;

        > a {
            padding: 18px 0;
            width: 100%;
        }
    }

    &__icon {
        position: inherit !important;
        cursor: pointer;
        width: 30px !important;
        margin-bottom: 0;

        &:hover {
            color: $cadfem-cornflower;
        }

        &:before {
            top: 39%;
            position: relative;
        }
    }

    &__link,
    &__label {
        display: flex;
        text-align: left;
        padding: 0 building-units(0.5);
        text-decoration: none;
        text-transform: none;

        font-weight: $font-weight-bold;
        font-size: $font-size-text-small;
        color: $cadfem-darkblue;
        letter-spacing: 0;
        line-height: 18px;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover {
            color: $cadfem-cornflower;
        }
    }

    &__checkbox {
        display: none;
    }

    /* fade in checked menu */
    &__checkbox:checked + .push-menu {
        transform: translate3d(0, 0, 0);
    }
}

/**
 * level 1
 */
.push-menu .push-menu__label {
    text-transform: uppercase;
}

/**
 * level 2
 */
.push-menu .push-menu .push-menu__label {
    text-transform: none;
}

/**
 * level 3
 */
.push-menu .push-menu .push-menu .push-menu__label {
    font-size: $font-size-text-small;
    font-weight: $font-weight-regular;
}
