//-------------------------------------------------------------
// Input
//-------------------------------------------------------------
// Variables
$input-bg-color: $cadfem-purewhite;
$input-br-color: $cadfem-steamgray;
$input-color: $cadfem-darkblue;

$input-br-color-state: $cadfem-denimblue;
$input-bg-color-active: $cadfem-purewhite;
$input-box-shadow: $form-elements-box-shadow;
$input-box-shadow-color-focus: $blue-rgba-50;
$input-placeholder-color: $cadfem-ashgray;
$input-icon-color: $blue;

$input-br-radius: 8px;
$input-height: building-units(3);
$input-padding: 12.5px 15px;
$input-elements-padding: 3px;
$input-icon-size: building-units(1.5);
$input-margin: building-units(1.34);

$file-upload-default: $cadfem-cornflower;
$file-upload-br-default: $cadfem-steamgray;
$file-upload-hover: $cadfem-denimblue;
$file-upload-active: $cadfem-darkblue;
$file-upload-error: $cadfem-chesnut;
$file-upload-bg-error: rgba($color-error, .05);
$file-upload-disabled: $cadfem-silkwhite;
$file-upload-icon-disabled: $cadfem-ashgray;

.field-error {
    @extend %text-small;

    display:     block;
    padding-top: $input-elements-padding;
    color:       $file-upload-error;
}

// Global Styles
.form-input-set {
    width: 100%;
    margin-bottom: building-units(1.34);

    input.form-input {
        height: $input-height;
    }

    input::placeholder {
        color: $input-placeholder-color;
        font-size: $font-size-text-small;
    }

    // hide spinner in webkit browsers
    [type=number] {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    %icon-lock {
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        left: 15px;
        margin-right: 10px;
    }

    /************************************
     * INPUT GROUP // WRAPPER
     ************************************/
    .input-group {
        display: block;
        margin-bottom: $input-margin;
    }

    .input-group.has-error {
        position: relative;
    }

    /************************************
     * STANDARD INPUT
     ************************************/
    .form-input {
        display: block;
        width: 100%;
        padding: $input-padding;
        background-color: $input-bg-color;
        border-radius: $input-br-radius;
        border: 1px solid $input-br-color;
        font-size: $font-size-text-small;
        color: $input-color;

        &:hover {
            border-color: $input-br-color-state;
        }

        &:focus {
            outline: 0;
            border-color: $input-br-color-state;
        }

        &:active {
            background-color: $input-bg-color-active;
            border-color: $input-br-color-state;
            box-shadow: none;

            &:hover {
                border-color: $input-color;
            }
        }

        &:disabled {
            color: $input-placeholder-color;
            background-color: $input-br-color;
            cursor: not-allowed;

            &:hover {
                background-color: $input-br-color;
                border-color: $input-br-color;
            }

            &:active {
                background-color: $input-br-color;
            }
        }
    }

    /************************************
     * ICON INPUT - LSG
     ************************************/
    .icon-input {
        position: relative;

        .form-input {
            padding-right: building-units(3);

            &:disabled {
                ~ .teleicon {
                    opacity: .3;
                    cursor:  default;
                }
            }
        }

        .teleicon {
            cursor:   pointer;
            position: absolute;
            bottom:   8px;
            right:    building-units(1);

            &::before {
                font-size: $input-icon-size;
                color:     $input-icon-color;
            }

            &.icon-search::before {
                color:     $gray-56;
            }
        }
    }

     /************************************
     * FILE INPUT
     ************************************/
    .file-upload {
        position: relative;

        //hidden input field
        &__input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            cursor: pointer;
        }

        //label, which is now the "placeholder"
        &__label {
            display: flex;
            flex-flow: row;
            height: 45px;
            cursor: pointer;

            &:hover,
            &:focus,
            &.has-focus {
                border-color: $file-upload-hover;

                .file-upload__button {
                    background-color: $file-upload-hover;
                }
            }

            &:active {
                border-color: $file-upload-active;

                .file-upload__button {
                    background-color: $file-upload-active;
                }
            }
        }

        &__button {
            width: 86px;
            height: building-units(3);
            border: 1px solid $file-upload-default;
            color: $cadfem-purewhite;
            background-color: $file-upload-default;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;

            display: inline-flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;

            > .icon-upload {
                font-size: 24px;
            }
        }

        .validation-message {
            display: none;
        }

        &__placeholder {
            color: $cadfem-ashgray;
            font-size: 13px;
            line-height: 22px;
            text-transform: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: top;
            font-weight: $font-weight-regular;
            padding: 10.5px 15px;

            width: calc(100% - 91px);
            height: 100%;
            border: 1px solid $file-upload-br-default;
            border-radius: 10px 0 0 10px;
        }

        /// Error
        &.has-error {
            .file-upload__placeholder {
                color: $file-upload-error;
                border: 1px solid $file-upload-error;
                background-color: $file-upload-bg-error;
                padding-right: building-units(2);

                &:hover,
                &:focus,
                &.has-focus {
                    border-color: $file-upload-error;
                }

                &:active {
                    border-color: $file-upload-error;
                }

                .icon-upload {
                    background-color: $file-upload-error;
                }
            }

            .file-upload__button {
                background-color: $cadfem-chesnut;
                border-color: $cadfem-chesnut;

                > .cadfemicon:before {
                    color: $cadfem-purewhite;
                }
            }

            .validation-message {
                display: block;
            }

            &:after {
                top: 38px !important;
                margin-right: 90px;
            }
        }

        /// Disabled
        &--disabled {
            .file-upload__label {
                cursor: not-allowed;

                &:hover,
                &:focus,
                &.has-focus,
                &:active {
                    border-color: $file-upload-disabled;
                }

                .icon-upload {
                    color: $file-upload-icon-disabled;
                }
            }

            .file-upload__placeholder {
                border-color: $file-upload-disabled;
            }

            .file-upload__button {
                border-color: $file-upload-disabled;
                background-color: $file-upload-disabled !important;
            }

            .validation-message {
                display: none;
            }
        }
    }

    /************************************
     * ERROR VALIDATION
     ************************************/
    .validation-message {
        display: none;
    }

    .has-error,
    ._error {
        .validation-message {
            display: block;
        }

        .cadfemicon {
            color: $color-error;
        }

        label.label {
            color: $color-error;
        }

        .form-input {
            background-color: rgba($color-error, .05);
            color: $color-error;

            &:active:focus:hover,
            &:focus,
            &:hover,
            &:active,
            &:visited {
                border-color: $color-error;
                background-color: rgba($color-error, .05);
                color: $color-error;

                ~ .icon-lock {
                    color: $color-error;
                }
            }
        }

        &.input-group {
            position: relative;

            &:after {
                @extend %cadfemicon;
                content: '\7a';
                position: absolute !important;
                top: 37px;
                right: 12px;
                height: 18px;
                width: 18px;
                color: $color-error;
            }

            &.select:after {
                right: -23px;
            }
        }
    }
}

fieldset .input-group._error:after {
    top: 13px !important;
}


@import "input-validation";
@import "input-addon";
@import "input-link";
