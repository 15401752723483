//-------------------------------------------------------------
// Buttons
//-------------------------------------------------------------

// Variables
$button-padding: building-units(1) building-units(1);
$button-icon-indent: building-units(1);
$button-icon-font-size: building-units(1.6);
$button-border-radius: 26px;

button:focus {
    outline: 0 !important;
}

// Mixin
@mixin button() {
    cursor: pointer;
    color: $cadfem-purewhite;
    border: 1px solid transparent;
    padding: $button-padding;
    font-weight: $font-weight-semibold;
    background: none;
    text-decoration: none;
    letter-spacing: 1.6px;
    font-size: 13px; //$font-size-base;
    text-transform: uppercase;
    border-radius: 20px;
    white-space: normal;

    display: inline-flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    height: building-units(3);
    max-height: building-units(3);

}

%button {
    @include button();
}

.btn {
    @extend %button;
    @extend %reset-hover;

    >.cadfemicon {
        margin-right: rem(15px);
        font-size: rem($button-icon-font-size);
    }

    >.icon--right {
        padding-left: rem(5px);
        padding-right: 0;
        margin: 15px;
    }

    &:focus-within,
    &:focus,
    &:focus {
        color: $text-color;
    }

    .link,
    a {
        color: inherit !important;
    }
}

//-------------------------------------------------------------
// Button primary
//-------------------------------------------------------------

// Variables
$button-text-color-primary: $white;
$button-bg-color-primary: $cadfem-cornflower;
$button-border-color-primary: $cadfem-darkblue;

$button-text-color-primary-hover: $white;
$button-bg-color-primary-hover: $cadfem-denimblue;
$button-border-color-primary-hover: $cadfem-cornflower;

$button-text-color-primary-active: $white;
$button-bg-color-primary-active: $cadfem-darkblue;
$button-border-color-primary-active: $cadfem-darkblue;

$button-text-color-primary-disabled: $cadfem-ashgray;
$button-bg-color-primary-disabled: $cadfem-silkwhite;
$button-border-color-primary-disabled: $cadfem-steamgray;

.btn--primary {
    color: $button-text-color-primary !important;
    background-color: $button-bg-color-primary;

    &:focus-within,
    &:focus {
        color: $button-text-color-primary-hover;
        background-color: $button-bg-color-primary-hover;
    }

    //separate because of IE
    &:hover {
        background-color: $button-bg-color-primary-hover;
    }

    &:active {
        color: $button-text-color-primary-active;
        background-color: $button-bg-color-primary-active;
    }
    &.btn--disabled,
    &[disabled] {
        cursor: not-allowed;
        color: $button-text-color-primary-disabled !important;
        background: $button-bg-color-primary-disabled;
    }

    &.btn--ghost {
        color: $white;
        background-color: transparent;

        &:focus-within,
        &:focus,
        &:hover,
        &:active {
            color: $white;
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

.wrapper-information-top {
  .btn--primary {
    margin: auto !important;
  }
}

.product-details__top__wrapper {
  .btn--primary {
    margin: auto !important;
  }
}


//-------------------------------------------------------------
// Button primary-alternative
//-------------------------------------------------------------

// Variables
$button-text-color-primary-alternative: $white;
$button-bg-color-primary-alternative: $cadfem-darkblue;
$button-border-color-primary-alternative: $cadfem-darkblue;

$button-text-color-primary-alternative-hover: $white;
$button-bg-color-primary-alternative-hover: $cadfem-denimblue;
$button-border-color-primary-alternative-hover: $cadfem-cornflower;

$button-text-color-primary-alternative-active: $white;
$button-bg-color-primary-alternative-active: $cadfem-cornflower;
$button-border-color-primary-alternative-active: $cadfem-darkblue;

$button-text-color-primary-alternative-disabled: $cadfem-ashgray;
$button-bg-color-primary-alternative-disabled: $cadfem-silkwhite;
$button-border-color-primary-alternative-disabled: $cadfem-steamgray;

.btn--primary-alternative {
    color: $button-text-color-primary-alternative !important;
    background-color: $button-bg-color-primary-alternative;

    &:focus-within,
    &:focus {
        color: $button-text-color-primary-alternative-hover;
        background-color: $button-bg-color-primary-alternative-hover;
    }

    //separate because of IE
    &:hover {
        background-color: $button-bg-color-primary-alternative-hover;
    }

    &:active {
        color: $button-text-color-primary-alternative-active;
        background-color: $button-bg-color-primary-alternative-active;
    }
    &.btn--disabled,
    &[disabled] {
        cursor: not-allowed;
        color: $button-text-color-primary-alternative-disabled !important;
        background: $button-bg-color-primary-alternative-disabled;
    }

    &.btn--ghost {
        color: $white;
        background-color: transparent;

        &:focus-within,
        &:focus,
        &:hover,
        &:active {
            color: $white;
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

//-------------------------------------------------------------
// Button secondary
//-------------------------------------------------------------

// Variables
$button-text-color-secondary: $cadfem-purewhite;
$button-bg-color-secondary: $cadfem-cornflower;
$button-border-color-secondary: $cadfem-cornflower;

$button-text-color-secondary-hover: $cadfem-purewhite;
$button-bg-color-secondary-hover: $cadfem-cornflower;
$button-border-color-secondary-hover: $cadfem-cornflower;

$button-text-color-secondary-active: $cadfem-purewhite;
$button-bg-color-secondary-active: $cadfem-cornflower;
$button-border-color-secondary-active: $cadfem-cornflower;

$button-text-color-secondary-disabled: $cadfem-ashgray;
$button-bg-color-secondary-disabled: $cadfem-steamgray;
$button-border-color-secondary-disabled: $cadfem-steamgray;

.btn--secondary {
    color: $button-text-color-secondary;
    background-color: $button-bg-color-secondary;
    border-color: $button-border-color-secondary;

    &:focus-within,
    &:focus,
    &:hover {
        color: $button-text-color-secondary-hover;
        background-color: $button-bg-color-secondary-hover;
        border-color: $button-border-color-secondary-hover;
    }

    &:active {
        color: $button-text-color-secondary-active;
        background-color: $button-bg-color-secondary-active;
        border-color: $button-border-color-secondary-active;
    }

    &[disabled] {
        cursor: not-allowed;
        background: $button-bg-color-secondary-disabled;
        border-color: $button-border-color-secondary-disabled;
        color: $button-text-color-secondary-disabled;
    }


}

//-------------------------------------------------------------
// Button outline
//-------------------------------------------------------------

// Variables
$button-text-color-outline: $cadfem-cornflower;
$button-bg-color-outline: transparent;
$button-border-color-outline: $cadfem-cornflower;

$button-text-color-outline-hover: $cadfem-denimblue;
$button-bg-color-outline-hover: transparent;
$button-border-color-outline-hover: $cadfem-denimblue;

$button-text-color-outline-active: $cadfem-purewhite;
$button-bg-color-outline-active: $cadfem-darkblue;
$button-border-color-outline-active: $cadfem-darkblue;

$button-text-color-outline-disabled: $cadfem-ashgray;
$button-bg-color-outline-disabled: transparent;
$button-border-color-outline-disabled: $cadfem-steamgray;

.btn--outline {
    color: $button-text-color-outline;
    background-color: $button-bg-color-outline;
    border: 2px solid $button-border-color-outline;

    &:focus-within,
    &:focus {
        color: $button-text-color-outline-hover;
        background-color: $button-bg-color-outline-hover;
        border: 2px solid $button-border-color-outline-hover;
    }

    //separate because of IE
    &:hover {
        color: $button-text-color-outline-hover;
        background-color: $button-bg-color-outline-hover;
        border: 2px solid $button-border-color-outline-hover;
    }

    &:active {
        color: $button-text-color-outline-active;
        background-color: $button-bg-color-outline-active;
        border: 2px solid $button-border-color-outline-active;
    }

    &[disabled] {
        cursor: not-allowed;
        background: $button-bg-color-outline-disabled;
        border: 2px solid $button-border-color-outline-disabled;
        color: $button-text-color-outline-disabled;
    }
}

//-------------------------------------------------------------
// Button transparent
//-------------------------------------------------------------

// Variables
$button-text-color-transparent: $cadfem-cornflower;
$button-bg-color-transparent: transparent;
$button-text-color-transparent-hover: $cadfem-denimblue;
$button-text-color-transparent-active: $cadfem-darkblue;
$button-text-color-transparent-disabled: $cadfem-ashgray;

.btn--transparent {
    //white-space: unset;
    white-space: inherit;
    color: $button-text-color-transparent;
    background-color: $button-bg-color-transparent;
    line-height: building-units(1.3);

    &:focus-within,
    &:focus {
        color: $button-text-color-transparent-hover;
        background-color: $button-bg-color-transparent;
    }

    //separate because of IE
    &:hover {
        color: $button-text-color-transparent-hover;
        background-color: $button-bg-color-transparent;
    }

    &:active {
        color: $button-text-color-transparent-active;
        background-color: $button-bg-color-transparent;
    }

    &[disabled] {
        cursor: not-allowed;
        color: $button-text-color-transparent-disabled;
    }

    >.cadfemicon {
        margin: 0;
    }
}

.btn--icon {
    border-radius: 100%;
    height: building-units(3);
    max-height: building-units(3);
    width: building-units(3);
    max-width: building-units(3);
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    /////////////////////////////////////////////
    color: $cadfem-cornflower;
    background-color: $cadfem-steamgray;

    &.btn--small,
    &.btn--medium,
    &.btn--large {
        padding: 0;
    }

    &.btn--small {
        width: 35px;
        max-width: 35px;
    }

    &.btn--large {
        width: 55px;
        max-width: 55px;
    }

    &:focus-within,
    &:focus {
        color: $cadfem-denimblue;
        background-color: $cadfem-pearlgray;
    }

    //separate because of IE
    &:hover {
        color: $cadfem-denimblue;
        background-color: $cadfem-pearlgray;
    }

    &:active {
        color: $cadfem-purewhite;
        background-color: $cadfem-darkblue;
    }

    &[disabled] {
        cursor: not-allowed;
        color: $cadfem-ashgray;
        background-color: $cadfem-silkwhite;
    }

    ///////////////////////////////////////////
    >.cadfemicon {
        margin-right: 0 !important;
    }
}

/*************************************
 * BUTTON OVAL
 *************************************/
.btn--oval {
    border-radius: building-units(2);
    border: 2px solid $cadfem-cornflower;
    background-color: transparent;

    >.cadfemicon {
        margin: 0 !important;
    }

    &.btn--primary .cadfemicon{
        color: $cadfem-cornflower;
    }

    &:focus-within,
    &:focus {
        border-color: $cadfem-denimblue;
        background-color: transparent;
    }

    //separate because of IE
    &:hover {
        border-color: $cadfem-denimblue;
        background-color: transparent;

        .cadfemicon {
            color: $cadfem-denimblue;
        }
    }

    &:active {
        color: $cadfem-purewhite;
        background-color: $cadfem-darkblue;
        border-color: $cadfem-darkblue;

        .cadfemicon {
            color: $cadfem-purewhite;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        border-color: $cadfem-silkwhite;

        &:hover,
        &:active,
        &:focus {
            cursor: not-allowed;
            border-color: $cadfem-silkwhite;
            background-color: $cadfem-silkwhite;
        }

        span.cadfemicon {
            color: $cadfem-ashgray;
        }
    }
}

/*************************************
 * BUTTON SIZES
 *************************************/
.btn--small {
    height: 35px;
    max-height: 35px;
    padding: 0 15px;

    >.cadfemicon {
        font-size: rem(16px);
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(5px);
    }

    &.btn--round {
        >.cadfemicon {
            margin: 0;
            position: relative;
            left: 1px;
        }
    }
}

.btn--medium {
    height: building-units(3);
    max-height: building-units(3);
    min-height: building-units(3);
    padding: 0 building-units(1);
    border-radius: 25px;

    >.cadfemicon {
        font-size: rem(24px);
        width: rem(24px);
        height: rem(24px);
        margin-right: rem(15px);
    }

    &.btn--round {
        >.cadfemicon {
            margin: 0;
            position: relative;
            left: 1px;
        }
    }
}

.btn--large {
    height: 55px;
    max-height: 55px;
    padding: 0 building-units(2);
    border-radius: building-units(2);

    >.cadfemicon {
        font-size: rem(32px);
        width: rem(32px);
        height: rem(32px);
        margin-right: rem(15px);
    }

    &.btn--round {
        >.cadfemicon {
            margin: 0;
            position: relative;
            left: 1px;
        }
    }
}

// CADFEMEB-1672
.btn--small,
.btn--medium,
.btn--large {
    line-height: 15px;
}

.btn--brandbar {
  padding: 4px 20px;
  border-radius: 20px;
  background-color: $cadfem-cornflower;
  color: $cadfem-purewhite !important;

  >.cadfemicon {
    color: $cadfem-purewhite;
    margin: 0 !important;
  }

  //separate because of IE
  &:hover {
    background-color: $cadfem-denimblue;
    color: $cadfem-purewhite !important;
  }

  &:active {
    background-color: $cadfem-darkblue;
    color: $cadfem-purewhite !important;
  }
}

//-------------------------------------------------------------
// Button Link
//-------------------------------------------------------------

$button-text-color-link: $text-color;
$button-bg-color-link: transparent;
$button-border-color-link: $gray-178;
$button-link-text-color-hover: $magenta;
$button-bg-color-link-hover: $gray-237;
$button-border-color-link-hover: $gray-178;
$button-text-color-link-active: $gray-56;
$button-bg-color-link-active: $gray-220;
$button-border-color-link-active: $gray-117;
$button-text-color-link-disabled: $gray-184;
$button-bg-color-link-disabled: $white;
$button-border-color-link-disabled: $gray-220;

.btn--link {

    &:hover,
    &:focus,
    &:active {
        color: $button-link-text-color-hover;
    }
}

.btn--shadow {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.btn--shadow-left {
    box-shadow: -4px 0 5px -3px rgba(0, 0, 0, 0.10);
}

.btn--block {
    display: flex !important;
}

.btn--no-round {
    border-radius: 0;
}

// Button Groups
.btn-group {
    display: flex;
    flex-flow: row nowrap;

    // For radio and checkbox button groups
    >.radio-btn,
    >.checkbox-btn {
        position: relative;
    }

    input[type="radio"],
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;

        +label.btn {
            position: relative;
        }
    }
}

.btn-brandbar-addMenu {
  padding-bottom: 0px;
  padding-top: 7px;
  border-radius: 8px;

  >.cadfemicon{
    top: -5px;
  }
}

.hero_img__content {
  .btn--medium {
    width: max-content;
  }
}
