// Variables
$pagination-text-color:                     $cadfem-darkblue;
$pagination-item-hover-text-color:          $text-color;
$pagination-item-active-text-color:         $cadfem-cornflower;
$pagination-item-disabled-text-color:       $gray-184;

.pagination {
    width: 100%;

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right
    }

    &__list {
        list-style: none;
        display: inline-flex;
        flex-flow: row wrap;
        text-align: center;
        padding-left: 1px;
        margin-bottom: 0;
    }

    &__item {
        cursor: pointer;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &:hover,
        &:focus-within {
            position: relative;
            z-index: 1;
        }

        &.active {
            .pagination__link {
                color: $pagination-item-active-text-color;
                cursor: default;
            }
            z-index: 2
        }

        > .pagination__link {
            cursor: pointer;
        }

        &.disabled > .pagination__link {
            cursor: no-drop;
            color: $pagination-item-disabled-text-color;
        }
    }

    .cadfemicon {
        font-size: rem(13px);
        line-height: 16px;
        color: $pagination-text-color;

        &:hover {
            color: $pagination-item-hover-text-color;
        }
    }

    &__link,
    &__ellipsis {
        display: block;
        padding: rem(7px 10.2px);
        color: $pagination-text-color;
        font-size: $font-size-base;
        line-height: $font-size-base;
        font-weight: $font-weight-semibold;
        text-decoration: none;
        @include reset-hover();

        &:hover {
            color: $cadfem-cornflower;

            @include media-breakpoint-down(md) {
                background-color: $cadfem-silkwhite;
            }

        }
    }

    &__ellipsis {
        color:          $cadfem-ashgray;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

@include media-breakpoint-only(xs) {
    .pagination {
        &__item {
            display: none;
            &.active,
            &.pagination__prev,
            &:last-of-type {
                display: initial;
            }
        }
    }
}
