//-------------------------------------------------------------
// Navbar
//-------------------------------------------------------------

$navbar-link-font-size-lg: 18px;
$navbar-link-font-size-xl: 21px;

.navbar {
  background: $navbar-background-color;
  border-bottom: 1px solid $navbar-border-color;
  padding: building-units(1) 0;
  position: relative;
  min-height: rem(63px);

  @include media-breakpoint-up(lg) {
    padding: 0;
    min-height: rem(69px);
  }

  &__container {
    @extend %flex-container;
    align-items: center;
    @include media-breakpoint-up(lg) {
      min-height: rem(69px);
    }
  }
}

.home-link {
  @extend %navbar-link;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__icon {
    margin-right: 6px;
  }

  &__text {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;

    span {
      display: block;
      white-space: nowrap;
    }
  }
}

.main-navigation {
  flex: auto;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &__list {
    @extend %horizontal-nav;
  }

  &__item {
    margin: 2px building-units(0.25) -1px;
    padding: rem(18px 4px);
    border-bottom: 2px solid transparent;

    @include media-breakpoint-up(xl) {
      padding: rem(18px 4px);
    }

    &--active,
    &:hover {
      border-bottom-color: $navbar-item-hover-border-color;
    }
  }

  &__link {
    @extend %navbar-link;

    display: block;
    font-size: $navbar-link-font-size-lg;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    padding: building-units(0.5) building-units(1);

    @include media-breakpoint-up(xl) {
      font-size: $navbar-link-font-size-xl;
    }

    &:active {
      color: $navbar-link-active-color;
      background: $navbar-link-active-background-color;
    }

    // Styles when list-item is active
    &--active {
      color: $navbar-link-active-color;

      &:hover,
      &:focus,
      &:active {
        color: $navbar-link-active-color;
      }
    }
  }
}

.megamenu {
  position: absolute;
  z-index: 999;
  top: 100%;
  width: 100%;
  left: 0;
  background: $megamenu-background-color;
  box-shadow: 0 10px 15px -10px rgba(0, 0, 0, 0.25);
  padding: 24px 0;
  display: none;
  margin-top: 1px;

  &--open,
    //temp
    .main-navigation__item:hover &,
    .main-navigation__link:focus + & {
    display: block;
  }

  &__top {
    margin-bottom: 36px;
  }

  &__overview-link {
    @extend %h4;
    @extend %navbar-link;

    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
  }

  &__menu-list {
    @extend %horizontal-nav;
    display: flex;
    flex-wrap: wrap;
    margin-right: rem(-4px);
    margin-left: rem(-4px);
  }

  &__menu-item {
    @include make-col(2);
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-left: rem(4px);
    padding-right: rem(4px);
    padding-bottom: 24px;
  }

  &__menu-link {
    @extend %navbar-link;
    font-weight: 900;
    margin-bottom: 12px;

    &--active {
      color: $megamenu-link-active-color;
    }
  }

  &__link-icon {
    margin-right: 6px;
  }

  &__image-box {
    margin-top: 12px;
  }

  &__item-image {
    max-width: 100%;
  }

  &__submenu-list {
    @extend %navigation-list;
    padding-top: 12px;
  }

  &__submenu-item {
    margin-bottom: 6px;
  }

  &__submenu-link {
    @extend %navbar-link;
    font-size: 15px;

    &--active {
      color: $megamenu-submenu-link-active-color;
    }
  }

  &__quicklinks {
    border-top: 1px solid $gray-220;
    padding-top: 12px;
  }

  &__quicklinks-list {
    @extend %horizontal-nav;
  }

  &__quicklinks-item {
    margin-right: 48px;
  }

  &__quicklinks-link {
    @extend %navbar-link;
  }
}

.meta-navigation {
  margin-left: auto;

  &__menu {
    @extend %horizontal-nav;
  }

  &__item {
    padding: 0 building-units(0.5);

    &:last-child {
      padding-right: 0;
    }
  }

  &__link {
    display: block;
  }
}

.login-dropdown {
  &__role {
    font-size: 18px;
    margin-bottom: 24px;
  }
}

.navbar-link-icon {
  @extend %navbar-link;

  &,
  &.dropdown__toggle {
    padding: rem(6px);
  }

  .teleicon {
    padding: 0;
  }
}

.navbar-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $navbar-background-color;
  display: none;

  &--open {
    display: block;
  }

  &__container {
    max-height: 100%;
  }

  &__form {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 0;

    @include media-breakpoint-up(lg) {
      padding: 16px 0;
    }
  }

  &__input {
    border: none;
    flex: 1;
    font-size: 21px;
    margin-right: 12px;
    min-width: 0;

    &:focus {
      outline-style: none;
      box-shadow: none;
    }
  }

  &__close-button {
    align-self: center;
    margin-left: 24px;
    color: $navbar-close-button-color;

    &:hover {
      color: $navbar-close-button-hover-color;
    }
  }
}

.navbar-mobile-menu {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  overflow-y: auto;
  background-color: $navbar-mobile-menu-background-color;
  padding: 0 12px 12px 12px;

  .navbar__container {
    justify-content: flex-end;
  }

  &__navbar-title {
    font-weight: 600;
    color: $navbar-mobile-menu-title-color;
  }
}

body.mobile-menu--open {
  overflow: hidden;
}
