//-------------------------------------------------------------
// Definition Lists
//-------------------------------------------------------------

dl {
    padding-left: rem(20px);
}

dt {
    display: list-item;
    list-style-type: disc;
    font-weight: $font-weight-semibold;
}

dd {
    margin: 0 0 rem(5px);
}
