//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.repeatable-container {

    fieldset {
        padding: 0;
    }

    .acc_content2__headline,
    .acc_content2__content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .acc_content2__toggle {
        margin-left: inherit !important;
        flex: 0 28px;
        min-width: 28px;
    }

    .acc_content2__title {
        @extend h4;
        line-height: 26px;
        margin-bottom: 0;
    }

    .acc_content2__toggle-icon {
        font-size: 28px;
    }

    .acc_content2__headline > .btn.btn--transparent {
        margin-left: auto;
    }
}
