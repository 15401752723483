/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
  color: $syntax-pre-text-color;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #fff;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.code-token.code--comment,
.code-token.code-prolog,
.code-token.code-doctype,
.code-token.code-cdata {
  color: $syntax-code-token-comment-text-color;
}

.code-token.code-punctuation {
  color: $syntax-code-token-punctuation-color;
}

.namespace {
  opacity: 0.7;
}

.code-token.code-property,
.code-token.code-tag,
.code-token.code-boolean,
.code-token.code-number,
.code-token.code-constant,
.code-token.code-symbol,
.code-token.code-deleted {
  color: $syntax-code-token-property-color;
}

.code-token.code-selector,
.code-token.code-attr-name,
.code-token.code-string,
.code-token.code-char,
.code-token.code-builtin,
.code-token.code-inserted {
  color: $syntax-code-token-selector-color;
}

.code-token.code-operator,
.code-token.code-entity,
.code-token.code-url,
.language-css .code-token.code-string,
.style .code-token.code-string {
  color: $syntax-code-token-operator-color;
  background: $syntax-code-token-operator-background-color;
}

.code-token.code-atrule,
.code-token.code-attr-value,
.code-token.code-keyword {
  color: $syntax-code-token-atrule-color;
}

.code-token.code-function,
.code-token.code-class-name {
  color: $syntax-code-token-function-color;
}

.code-token.code-regex,
.code-token.code-important,
.code-token.code-variable {
  color: $syntax-code-token-regex-color;
}

.code-token.code-important,
.code-token.code-bold {
  font-weight: bold;
}

.code-token.code-italic {
  font-style: italic;
}

.code-token.code-entity {
  cursor: help;
}

pre[class*="language-"].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre[class*="language-"].line-numbers > code {
  position: relative;
  white-space: inherit;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: $syntax-line-numbers-rows-color;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}
