//-------------------------------------------------------------
// Ordered Lists
//-------------------------------------------------------------

ol {
    padding-left: 1.2rem;

    &.list--large {
        padding-left: 1.4rem;
    }
}
