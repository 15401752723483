//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
.hero_img {
    display: flex;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;

    max-width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;

    // CADFEMEB-1419
    @media (min-width: 1900px) {
        background-position: right;
        max-width: 1900px;
        margin: 0 auto;
        left: 0;
    }

    /**
     * Elements
     */
    &__wrapper {
        @extend .container;

        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 building-units(3);
        position: relative;
        z-index: 3;

        @include media-breakpoint-down(md) {
            flex-flow: wrap;
            padding: 0 15px;
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: stretch;
        height: 400px;

        padding: building-units(4) building-units(1);

        @include media-breakpoint-only(xl) {
            width: percentage(5 / 12);
        }

        @include media-breakpoint-only(lg) {
            width: percentage(6 / 12);
        }

        @include media-breakpoint-only(md) {
            width: percentage(8 / 12);
        }

        @include media-breakpoint-only(sm) {
            width: percentage(8 / 12);
        }

        @include media-breakpoint-only(xs) {
            width: percentage(12 / 12);
        }
    }

    &__company {
        margin: 0 0 building-units(3) 0;
        display: flex;
        align-items: center;
    }

    &__company-logo {
        max-width: building-units(8);
        max-height: building-units(4);
        height: auto;
        width: auto;
        margin: 0 building-units(2) 0 0;
    }

    &__company-label {
        align-items: center;
        display: flex;
        // extends small text
        font-size: $font-size-default;
        line-height: $line-height-default;

        @include media-breakpoint-only('xs') {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
        }
    }


    &__company-label-img {
        width: 32px;
        height: 32px;
        font-size: 32px;
        display: inline-block;
        margin-right: 10px;
        color: $cadfem-darkblue;

        @include media-breakpoint-only('xs') {
            width: 24px;
            height: 24px;
            font-size: 24px;
        }
    }

    &__title {
        margin-bottom: 0;
        text-transform: none;
    }

    &__card {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        padding: building-units(4) building-units(1);
        min-width: percentage(4 / 12);
        max-width: percentage(5 / 12);

        @include media-breakpoint-down(md) {
            height: auto;
            margin-top: building-units(1);
            padding: 0 0 building-units(2);

            min-width: percentage(8 / 12);
            max-width: percentage(8 / 12);
        }

        @include media-breakpoint-only(sm) {
            min-width: percentage(10 / 12);
            max-width: percentage(10 / 12);
        }

        @include media-breakpoint-only(xs) {
            min-width: percentage(12 / 12);
            max-width: percentage(12 / 12);
        }

        .card_cf {
            margin-bottom: 0;
            color: $cadfem-darkblue;
        }
    }

    /**
     * MODIFIER
     */
    &--dimmer-light:after,
    &--dimmer-light-gradient:after,
    &--dimmer-dark:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .6;
    }

    &--dimmer-plain-dark {
        color: $cadfem-purewhite;
        background-color: transparent;
    }

    &--dimmer-light:after {
        background-color: $cadfem-purewhite;
    }

    &--dimmer-dark {
        color: $cadfem-purewhite;

        &:after {
            opacity: 0.7;
            background-image: linear-gradient(270deg, rgba(0,52,95,0.00) 0%, #00345F 61%);
        }
    }

    &--dimmer-light-gradient:after {
        background: -moz-linear-gradient(left, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.85) 25%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.85) 25%, rgba(255,255,255,0) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.85) 25%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    }

    &--with-card {
        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }

        @include media-breakpoint-only(xs) {
            margin-bottom: 0;
        }
    }

    &--large {
        height: 800px;
        .hero_img__content {
            height: 600px;
        }

        @include media-breakpoint-down(lg) {
            height: 600px;

            .hero_img__content {
                height: 600px;
            }
        }

        @include media-breakpoint-only(xs) {
            height: 400px;

            .hero_img__content {
                height: 400px;
            }
        }
    }

    &--small {
        min-height: 200px;
        height: 200px;
        background-size: cover;

        .hero_img__content {
            height: 200px;
        }

        .hero_img_text {
            display: none;
        }
    }
}
