//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$slider-content-border: #dbdbdb;
$slider-bullet-hover: #9b9b9b;

.swiper {
    position: relative;

    &-container {
        margin: 0 auto;
        width: calc(100% - 188px);
        position: relative;
        overflow-x: hidden;
        overflow-y: -webkit-paged-y;
        list-style: none;
        padding: 0;
        z-index: 1;
    }

    &-wrapper {
        position: relative;
        width: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;

        -webkit-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }

    &-slide {
        flex: 0 0 auto;
        justify-self: stretch;
        display: flex;
    }

    &-slide__image {
        overflow: hidden;
        max-height: 452px;
        display: inline-block;
        width: 100%;

        >img {
            width: 100%;
        }
    }

    &-slide__content {
        display: flex;
        position: absolute;
        overflow: hidden;
        bottom: calc(((225px * 0.48) + 36px) * -1);
        left: 20%;
        width: 60%;
        height: 100%;
        max-width: 760px;
        min-height: 201px;
        max-height: 249px;
        align-self: flex-end;
        border: 1px solid $slider-content-border;
        z-index: 9;

        > h1 {
            font-weight: $font-weight-light;
            margin: 24px 0 15px 0;
        }

        > button {
            margin: 24px 0 36px 0;
        }

        .product-tile-wrapper {
            padding: 0 building-units(1);
        }
    }

    &-button-prev,
    &-button-next {
        position: absolute;
        top: 50%;
        border-radius: 8px;
        width: 82px;
        height: 235px;
        margin-top: calc((235px / 2) * -1);
        z-index: 10;
        cursor: pointer;
        background-size: 82px 235px;
        background-position: center;
        background-repeat: no-repeat;

        @extend .teleicon;
        font-size: $font-size-h1-l;

        &:after {
            position: relative;
            top: calc((235px / 2) - (48px / 2));
            left: 0; //calc((82px / 2) - (24px / 2));
        }

        &:hover {
            background-color: $gray-237;
        }
    }

    &-button-next {

        &:after {
            position: relative;
            top: calc((235px / 2) - (48px / 2));
            // CADFEMEB-1982
            // right: 0; //calc((82px / 2) - (24px / 2));
            float: right;
            // CADFEMEB-1982
            @include media-breakpoint-down(md) {
                float: none;
            }
        }

    }

    &-button-prev {
        float: left;

        &:after {
            content: "\25";
        }
    }

    &-button-next {
        right: 0;

        &:after {
            content: "\a7"
        }
    }

    &-pagination {
        order: 3;
        position: absolute;
        text-align: center;
        -webkit-transition: 300ms opacity;
        -o-transition: 300ms opacity;
        transition: 300ms opacity;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: 10;
        width: 100% !important;

        &-bullets-dynamic {
            .swiper-pagination-bullet {
                display: none;
            }

            .swiper-pagination-bullet-active,
            .swiper-pagination-bullet-active-main,
            .swiper-pagination-bullet-active-next,
            .swiper-pagination-bullet-active-prev {
                display: inline-block;
            }

            .swiper-pagination-bullet-active.swiper-pagination-bullet:first-child ~ .swiper-pagination-bullet-active-next-next,
            .swiper-pagination-bullet-active.swiper-pagination-bullet:last-child {
                display: inline-block;
            }
        }
    }

    &-pagination-bullets {
        bottom: -40px;
        left: 0;
        width: 100%;
    }

    &-pagination-bullet {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 100%;
        background: $gray-237;

        &:hover {
            background: $slider-bullet-hover;
        }
    }


    &-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
        font-size: 10px;
        line-height: 16px;
    }

    &-pagination-bullet-active {
        opacity: 1;
        background: $cadfem-darkblue;
        color: $cadfem-purewhite;
    }

    &--scheme-light {
        color: $scheme-dark;

        .swiper-slide__content {
            background-color: rgba($white, 0.9);
        }
    }

    &--scheme-dark {
        color: $white;

        .swiper-slide__content {
            background-color: rgba($scheme-dark, 0.9);
            border-color: #9b9b9b;
        }
    }

    &-bg--white {
        background-color: $white;
    }

    &-bg--primary {
        background-color: $magenta;
    }

    &-bg--secondary {
        background-color: $gray-56;
    }
    &-has-badges {
      .product-tile__content {
        margin-top: 30px;
      }
    }
}


@include media-breakpoint-down(lg) {
    .swiper-slide__image {
        max-height: 350px;
    }
}

//viewport sm
@include media-breakpoint-down(md) {
    .swiper-button-prev,
    .swiper-button-next {
        width: 60px;
        height: 140px;
        margin-top: calc((140px / 2) * -1);

        &:after {
            position: relative;
            top: calc((140px / 2) - (48px / 2));
            left: calc((60px / 2) - (24px / 2));
            color: $white;
            text-shadow: 0 0 4px $black;
        }

        &:hover {
            background-color: rgba($gray-237, 0.75);
        }
    }

    .swiper-button-prev {
        left: 12px;
        right: auto;

        &:after {
            content: "\25"
        }
    }

    .swiper-button-next {
        right: 0;
        left: auto;

        &:after {
            content: "\a7"
        }
    }

    .swiper-slide__image {
        max-height: 222px;
    }

    .swiper-slide__content {
        position: inherit;
        border: none;
        width: 100%;
        min-width: 100%;
        left: 0;
        bottom: 0;
        margin-bottom: 0;
        min-height: auto;
        max-height: 100%;
    }

    .swiper--scheme-dark {
        color: $text-color;

        .swiper-slide__content {
            background-color: rgba($white, 0.9);
        }
    }
}

@include media-breakpoint-down(xs) {
    .swiper-container {
        width: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 49px;
        height: 73px;
        margin-top: calc((73px / 2) * -1);

        &:after {
            position: relative;
            top: calc((73px / 2) - (48px / 2));
            left: calc((49px / 2) - (24px / 2));
        }
    }

    .swiper-slide__image {
        max-height: 113px;
    }
}
