//-------------------------------------------------------------
// maps_google
//-------------------------------------------------------------
// Variables

.maps_google {

    &__checkbox_wrapper {
        display: flex;
        flex-flow: row;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }
    }

    &__map {
        height: 524px;
        width: 100%;
        background-image: url("../assets/images/maps/WorldMap.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        @include media-breakpoint-only(xs) {
            height: 280px;
        }

        button.gm-ui-hover-effect {
            right: 25px !important;
            top: 15px !important;

            > img {
                width: building-units(2) !important;
                height: building-units(2) !important;
            }
        }
    }

    &__infowindow {
        min-width: 390px;
        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-base;
        color: $cadfem-darkblue;
        padding: building-units(1);
    }

    &__card {
        margin-top: building-units(1);
    }
}
