#mana_ajax_wrapper_main_mana_layered_nav .filter-content > div > .acc_content2 {
    &.acc_content2--open {
        > .acc_content2__headline {
            color: $cadfem-cornflower;
            border-bottom-width: 1px !important;
        }

        > .acc_content2__headline .acc_content2__title {
            color: $cadfem-cornflower;
        }
    }

    .acc_content2__content .acc_content2:not(.acc_content2--open) .acc_content2__headline {
        border-bottom-width: 1px !important;
    }

    .acc_content2__content .acc_content2.acc_content2--open .acc_content2__headline {
        border-bottom-width: 0 !important;
    }

    .acc_content2__title {
        color: $cadfem-ashgray;
        font-size: rem($font-size-text-small) !important;
    }

    .acc_content2__content {
        .acc_content2__title {
            color: $cadfem-darkblue;
        }

        .form-checkbox-set label > span {
            font-size: rem($font-size-text-small) !important;
        }
    }

    .acc_content2__headline:hover .acc_content2__toggle {
        background-color: $cadfem-purewhite !important;
    }
}

.manadev-show-more {
    position: relative;
    margin-top: 25px;
    margin-bottom: building-units(1);
    background-color: $cadfem-purewhite;
    padding-left: building-units(1);
    padding-top: 5px;
}

.manadev-show-more > a {
    max-height: 24px;
    color: $cadfem-cornflower;
    text-transform: uppercase;
}

.manadev-show-more > a:hover {
    color: $cadfem-denimblue;
    cursor: pointer;
}

.manadev-show-more > a:active {
    color: $cadfem-darkblue;
}

.manadev-expandable-filter.outer-container {
    overflow:hidden;
    transition-property: height;
}

.mana-filter-checkboxes .item:not(:first-child) {
    margin-top: 25px;
}

@include media-breakpoint-only(xs) {
    .mana-filter-block .filter-current {
        border-width: 0;
    }
}

.mana-no-match {
    display: none;
}

.mana-overlay {
    position: absolute;
    background: white;
    filter: alpha(opacity=0.5); /* percent */
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    z-index: 10000;
}

#mana-please-wait {
    position: absolute;
    color: #d85909;
    font-size: 1.1em;
    text-align: center;
    z-index: 20000;

    p {
        position: fixed;
        top: 45%;
        left: 50%;
        width: 120px;
        margin-left: -120px;
        padding: 15px 60px;
        background: rgba(255, 255, 255, 0.9);
        border: 2px solid #3399cc;
        color: #3399cc;
        text-align: center;
        z-index: 20100;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -ms-box-sizing: content-box;

        img {
            margin: 10px auto 0;
        }
    }
}

.category_product_list_banner {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .banners-content {
        margin-bottom: 0;
    }
}

/**
 * CADFEMEB-4508
 */
.catalog-category-view .section_cf {
    .banners-content--bg-blue {
        h3.banners-content__headline,
        .banners-content__introduction > p {
            color: $cadfem-purewhite;
        }
    }
}
