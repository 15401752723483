//-------------------------------------------------------------
//Reference Company Box
//-------------------------------------------------------------

// Variables

// General
.companybox {
    border-bottom: 1px solid $cadfem-steamgray;
    border-top: 1px solid $cadfem-steamgray;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-flow: row;

    @include media-breakpoint-up(sm) {
        padding: 25px calc(((100% / 12) / 2));
    }

    @include media-breakpoint-only(xs) {
        flex-flow: column;
        justify-content: center;
    }

    &__image {
        display: flex;
        flex-flow: row;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        width: 225px;
        margin-right: building-units(3);

        @include media-breakpoint-down(lg) {
            width: 150px;
            height: 150px;
            margin-top: auto;
            margin-bottom: auto;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
            margin-right: 0;
        }

        &:empty {
            position: relative;
            height: auto;
            &:after {
                content: "";
                background-image: url(../../assets/images/icons/hand-shake-icon.svg);
                background-position: center center;
                width: 64px;
                height: 64px;
            }
        }
    }


    &__img {
        width: 100%;
        height: auto;

        @include media-breakpoint-only(xs) {
            margin-right: 0;
            width: 225px;
        }
    }

    &__content {
        display: block;
        margin: 0 auto;
        flex-basis: fit-content;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        font-size: $font-size-text-small;
        line-height: $line-height-text-small;
        color: $cadfem-darkblue;
    }

    &__title {
        margin-bottom: 0;
    }

    &__categories {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: building-units(1);

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }
    }

    &:hover {
        .companybox__title {
            color: $cadfem-denimblue;
        }

        .companybox__morelink:after {
            @extend %internalLinkHover;
        }
    }
}
