//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.section-menu {
  background: $white;
  box-shadow: 0 10px 15px -10px rgba($black, 0.25);

  .horizontal-scroller-btn {
    color: rgba($section-menu-link-color, 0.3);

    &.active {
      color: $section-menu-link-color;
    }

    .teleicon {
      font-size: rem(18px);
    }
  }
}

.section-menu-list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  position: relative;
  transition: all 0.15s ease;
  white-space: nowrap;
  margin: 0;
  z-index: 1;
  padding: 10px 0;

  li {
    display: block;
    float: left;
    padding: 0 building-units(1);
    position: relative;
    border-left: 1px solid $gray-220;

    &:first-child {
      border-left: none;
    }
  }
}

$section-menu-link-font-size: $font-size-base;
$section-menu-link-line-height: 24px;

.section-menu-link {
  display: block;
  text-decoration: none;
  color: $section-menu-link-color;
  font-size: $section-menu-link-font-size;
  line-height: $section-menu-link-line-height;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: $section-menu-link-hover-color;
  }
}
