//-------------------------------------------------------------
// Metanavigation
//-------------------------------------------------------------

.meta-navigation-cf {
    @extend %flex-container;

    width: auto;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }


    &__menu {
        width: 100%;
        @include navigation-list();

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__item {
        float: left;

        &--left {
            float: left;
        }

        &--right {
            float: right;
        }

        .cadfemicon {
            font-size: 18px;
            height: 18px;
            width: 18px;
            position: relative;
        }

        .badge {
            position: absolute;
            left: 26px;
            top: -14px;
        }
    }

    &__link {
        display: block;

        &.minicart,
        &.wishlist {
            position: relative;
        }
    }
}
