//-------------------------------------------------------------
// Checkbox
//-------------------------------------------------------------

// Variables
$checkbox-size: 22px;
$checkbox-br-radius: 4px;
$checkbox-icon-size: building-units(1.5);
$checkbox-shadow: $form-elements-box-shadow;

$checkbox-bg-color: $gray-237;
$checkbox-br-color: $gray-178;
$checkbox-bg-color-hover: $cadfem-denimblue;
$checkbox-bg-color-active: $gray-199;
$checkbox-br-color-focus: $blue;
$checkbox-shadow-focus: $checkbox-shadow $blue;
$checkbox-icon-color: $cadfem-purewhite;

$checkbox-bg-color-disabled: $gray-248;
$checkbox-br-color-disabled: $cadfem-ashgray;
$checkbox-icon-color-disabled: $green-rgba-50;

$checkbox-bg-color-error: rgba($color-error, .05);
$checkbox-br-color-error: $red;
$checkbox-bg-color-error-hover: $red-251;
$checkbox-br-color-error-focus: $red;
$checkbox-shadow-error-focus: $checkbox-shadow $red;
$checkbox-icon-color-error: $red;

// General styles
.form-checkbox-set {
    margin-bottom: 25px;
    position: relative; // prevent scrolling on click at label

    label {
        line-height: calc(#{$checkbox-size} + 2px); //add border
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.has-error {
        .validation-message {
            padding-left: building-units(2);
            font-weight: $font-weight-semibold;
        }
    }
}

.form-checkbox-group {
    margin: building-units(2) 0;

    &.has-error .validation-message {
        font-weight: $font-weight-semibold;
        margin-bottom: 10px;
    }
}

.form-checkbox {
    position: absolute;
    top:      0;
    left:     0;
    z-index:  1;
    opacity:  0;

    + label {
        font-size:      $font-size-text-small;
        line-height:    $line-height-default;
        font-weight:    $font-weight-base;
        display:        inline-flex;
        min-height:     0;
        margin-bottom:  0;
        position:       relative;
        left:           5px;
        text-transform: none;

        > span {
            padding-left: 25px;
        }

        &:hover {
            color: $checkbox-bg-color-hover;
            &::before {
                border-color: $checkbox-bg-color-hover;
            }
        }

        &::before {
            @extend %cadfemicon;
            position:      absolute !important;
            top:           -5px;
            left:          -5px;
            height:        calc(#{$checkbox-size} - 2px);
            width:         calc(#{$checkbox-size} - 2px);
            border:        1px solid $checkbox-br-color-focus;
            font-size:     $checkbox-size;
            color:         $checkbox-icon-color;
            text-align:    center;
            transition:    opacity .3s ease-in-out;
            transform:     translateY(20%);
        }

        &::before {
            content: "";
        }

        &:hover {
            cursor: pointer;
        }
    }

    &:checked {
        + label {
            &::before {
                background-color: $checkbox-bg-color-hover;
                border-color: $checkbox-bg-color-hover;
                content: "\39"
            }
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:active {
        outline: 0;
    }

    &:focus:not(:active) {
        outline: 0;

        + label {
            &::before {
                border-color: $checkbox-bg-color-hover;
            }
        }
    }

    // Disabled
    &:disabled:checked {
        cursor: not-allowed;

        + label {
            cursor: not-allowed;
            color: $checkbox-br-color-disabled;

                &::before {
                    border-color: transparent;
                    background: $checkbox-bg-color-disabled;
                    color: $checkbox-br-color-disabled;
                }
        }
    }
    &:disabled:not(:checked) {
        +label {
            cursor: not-allowed;
            color:  $checkbox-br-color-disabled;

            &::before {
                border-color: $checkbox-br-color-disabled;
                background-color: transparent;
                color: $checkbox-br-color-disabled;
            }
        }
    }

    // Error
    .has-error & {
        + label {
            color:  $checkbox-icon-color-error;
            &::before {
                border-color: $checkbox-br-color-error;
                background:   $checkbox-bg-color-error;
                color:        $checkbox-icon-color-error;
            }
        }

        &:hover {
            + label {
                &::before {
                    background: $checkbox-bg-color-error-hover;
                }
            }
        }

        &:focus:not(:active) {
            outline: 0;

            + label {
                &::before {
                    border-color: $checkbox-br-color-error-focus;
                }
            }
        }
    }
}
