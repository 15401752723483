//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------

.briefing-component {
  border-radius: 4px;
  border: 1px solid $briefing-component-border-color;

  &__header {
    display: flex;
    padding: building-units(2);
    align-items: center;
  }

  &__icon {
    display: flex;
    margin-right: building-units(1);
    align-items: center;
    justify-content: center;

    > .svgicon {
      height: building-units(2.5);
      width: building-units(2.5);
    }
  }

  &__headline {
    line-height: 1;
  }
  &__title {
    @extend h4;
    font-weight: 500;
    display: block;
    margin: 0;
    line-height: 1;
  }

  &__subtitle {
    @extend %text-x-small;
    font-weight: 600;
    color: $briefing-subline-text-color;
    display: block;
  }

  &__header,
  &__footer {
    background-color: $briefing-background-color;
  }

  &__switcher {
    margin-left: auto;
  }

  &__main {
    border-top: 1px solid $briefing-component-border-color;
    border-bottom: 1px solid $briefing-component-border-color;
  }

  &__content {
    overflow: auto;
    padding: building-units(2);
  }

  &__image-grid {
    margin: 0 building-units(-1) building-units(-2);

    &--floating,
    &--3-column,
    &--2-column {
      display: flex;
      flex-flow: row wrap;
    }

    &--3-column {
      .briefing-component__image {
        flex: 1 1 calc(100% / 3 - 24px);
        width: calc(100% / 3);

        img {
          max-width: 100%;
        }
      }
    }

    &--2-column {
      .briefing-component__image {
        flex: 1 1 calc(100% / 2 - 24px);
        width: calc(100% / 2);

        img {
          max-width: 100%;
        }
      }
    }
  }

  &__image {
    margin: 0;
    padding: 0 building-units(1) building-units(2);

    img {
      display: block;
      width: auto;
      height: auto;
    }

    figcaption {
      font-size: building-units(1.25);
      margin-top: building-units(0.5);
    }

    &--scheme-bright {
      .briefing-component--dark & {
        display: none;
      }
    }

    &--scheme-dark {
      .briefing-component--light & {
        display: none;
      }
    }
  }

  &__image-link {
    position: relative;
    display: inline-block;
    color: $briefing-component-image-link-color;

    .briefing-component--dark & {
      color: $briefing-component-image-link-dark-color;
    }

    &:hover {
      color: $briefing-component-image-link-hover-color;
    }

    &::after {
      position: absolute;
      bottom: rem(12px);
      right: rem(12px);
      vertical-align: middle;
      display: inline-block;
      font-family: "TeleIcon";
      font-size: rem(24px);
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      text-transform: none !important; // if icon is used in uppercase context
      content: "\dc";
    }
  }

  &__footer {
    padding: building-units(1) building-units(2);
  }
}
