.search-result {
  border: 1px solid $gray-194;
  overflow: hidden;
  margin-bottom: rem(12px);
  display: flex;
  flex-flow: column nowrap;

  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
    border: none;
    border-bottom: 1px solid $gray-194;
  }

  &__image-container {
    @include media-breakpoint-up(md) {
      flex: 0 33.33%;
      order: 2;
      padding: rem(16px) 0;
    }

    a {
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      flex: 1 100%;
      padding-left: 0;
      padding-right: 0;

      .search-result__image-container ~ & {
        max-width: 66.66%;
        padding-right: rem(16px);
      }
    }

    @include media-breakpoint-up(xl) {
      max-width: 66.66%;
    }

    padding: rem(16px);
  }

  &__kicker {
    display: block;
    font-size: rem(15px);
    font-weight: bold;
  }

  &__breadcrumbs {
    display: inline;
    flex-flow: row wrap;
  }

  &__breadcrumb {
    color: $search-result-breadcrumb-color;
    font-weight: normal;
    display: inline-block;

    &::after {
      @extend %teleicon;

      content: "\a7";
      font-size: building-units(1);
      padding: 0 rem(12px);
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  &__title {
    margin: 0.5rem 0;
  }

  &__link {
    .teleicon {
      margin-right: rem(12px);
    }
  }
}
