//-------------------------------------------------------------
// Dropdown
//-------------------------------------------------------------

// Variables
$optionlist-br-color: $cadfem-steamgray;
$optionlist-bg: $white;
$optionlist-color-primary: $cadfem-darkblue;
$optionlist-bg-hover: $cadfem-silkwhite;
$optionlist-bg-select: $gray-220;
$optionlist-bg-hover-select: $gray-199;

// Dropdown stylings
.dropdown {
    position:   relative;
    display:    inline-block;
    width:      100%;

    &__toggle {
        @extend %text-small;

        position:       relative;
        max-height:     45px;
        color:          $optionlist-color-primary;
        padding:        rem(12.5px) rem(15px);
        background-color: $cadfem-purewhite;
        border:         1px solid $cadfem-steamgray;
        border-radius:  rem(8px);
        line-height:    rem(20px);
        width:          100%;
        margin-bottom:  0;

        @include reset-hover();

        &--no-arrow {
            &:after {
                display: none !important;
            }
        }

        &:focus,
        &.active,
        &:focus-within {
          color: $optionlist-color-primary;
          background-color: $cadfem-silkwhite;
        }

        //separate because of IE
        &:hover {
            color: $optionlist-color-primary;
            background-color: $cadfem-silkwhite;
        }

        &:after {
            @extend %cadfemicon;
            content:        "\38";

            position:       absolute !important;
            top:            50%;
            right:          building-units(0.75);
            transform:      translateY(-50%);
            height:         16px;
            color:          $cadfem-darkblue;
        }
    }

    &__content {
        display:            none;
        position:           absolute;
        top:                building-units(3) + 4px;
        left:               0;
        min-width:          building-units(15);
        z-index:            10;
        border:             1px solid $optionlist-br-color;
        border-radius:      building-units(.5);
        background-color:   $optionlist-bg;
        padding:            building-units(1);
        overflow:           hidden;
        font-size:          inherit;
    }

    &__list {
        list-style-type: none;
        margin:          0;
        padding:         0;
        background:      $optionlist-bg;
    }

    &__link {
        @extend %text-small;
        color:           $optionlist-color-primary;
        text-decoration: none;
        padding:         rem(12.5px) rem(15px);
        display:         block;
        margin-bottom:   0;

        @include reset-hover();

        &:focus,
        &:focus-within {
            background: $optionlist-bg-hover;
            color: $cadfem-darkblue;
        }

        &:hover {
            background: $optionlist-bg-hover;
            color: $cadfem-darkblue;
        }

        &--active {
            color: $cadfem-cornflower;
        }
    }

    &__item--inactive {
      .dropdown__link {
        color: $gray-184;
        cursor: no-drop;
      }
    }

    &--open {
      .dropdown__toggle {
        color: $optionlist-color-primary; // add for IE
        background-color: $cadfem-silkwhite;

        &:after {
          content: "\37"
        }
      }

      .dropdown__content {
        display: block;
          width: 100%;
      }
    }

    &--open-left {
        .dropdown__content {
            left: auto;
            right: 0
        }
    }

    &--open-center {
        .dropdown__content {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--open-top {
        .dropdown__content {
            top: auto;
            bottom: calc(100% + #{building-units(.5)});
        }
    }

    &--plain {
        width: auto;
        .dropdown__toggle {
            background-color: transparent;
            border-color: transparent;
            padding-left: 0;
            padding-right: 40px;
        }

        &:hover .dropdown__toggle,
        &:hover .dropdown__toggle:after {
            color: $cadfem-cornflower;
        }
    }

    &--cozy {
        .dropdown__toggle {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &--dark {
        .dropdown__toggle {
            color: #fff;
        }
    }

    &--inactive {
        .dropdown__toggle {
          color: $cadfem-ashgray;
          background-color: #fff;
          border-color: $cadfem-ashgray;
          cursor: no-drop;
            &:after {
            color: $cadfem-ashgray;
        }
        }
    }

    &--alert {
        .dropdown__toggle {
          background-color: rgba($red, .05);
          border-color: $red;
          color: $red;
            &:after {
                color: $red
            }

          &:hover,
          &:focus,
          &:focus-within {
            background-color: rgba($red, .05);
          }
        }
    }
}
.validation-message {
    @extend %text-small;

    color: $red;
    font-weight: $font-weight-semibold;
}
