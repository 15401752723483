.gradient {
    position: relative;

    &:before {
        position: absolute;
        z-index: 5;
        content: " ";
        height: 100%;
        width: 100%;
    }
}

.pure-white-dimmer:before {
    background: rgba(255,255,255,0.6);
}

.navy-blue-dimmer:before {
    background: rgba(0,52,95,0.7);
}

%dusk-white-gradient {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 30%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0.9) 100%);
}

.dusk-white-gradient:before {
    @extend %dusk-white-gradient;
}

%dawn-white-gradient {
    background: linear-gradient(315deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 40%, rgba(255,255,255,0.7) 80%, rgba(255,255,255,1) 100%);
}

.dawn-white-gradient:before {
    @extend %dawn-white-gradient;
}

.bright-white-gradient:before {
    background: linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.85) 30%, rgba(255,255,255,0) 100%);
}

.morning-blue-gradient:before {
    background: linear-gradient(0deg, rgba(243,246,249,1) 0%, rgba(255,255,255,1) 100%);
}

.morning-blue-gradient-reverse:before {
    background: linear-gradient(180deg, rgba(243,246,249,1) 0%, rgba(255,255,255,1) 100%);
}

.evening-blue-gradient:before {
    background: linear-gradient(90deg, rgba(0,52,95,0.8) 0%, rgba(36,84,126,0.7) 40%, rgba(171,203,238,0.1) 100%);
}

.midnight-blue-gradient:before {
    background: linear-gradient(90deg, rgba(0,52,95,0.7) 0%, rgba(0,52,95,0.68) 42%, rgba(0,52,95,0.2) 100%);
}