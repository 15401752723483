//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.acc_content2 {
    display: block;
    overflow: hidden;
    width: 100%;

    &__headline {
        display:          flex;
        flex-direction:   row;
        flex-wrap:        nowrap;
        justify-content:  flex-start;
        align-items:      center;

        width:            100%;
        cursor:           pointer;
        text-decoration:  none;
        min-height:       building-units(2);
        padding:          building-units(1);

        border-top:    1px solid rgba($cadfem-steamgray, 0.75);
        border-bottom:    1px solid rgba($cadfem-steamgray, 0.75);

        margin-top: -1px;

        @include reset-hover();

        > .cadfemicon {
            line-height: $line-height-h3;
            margin-right: building-units(0.5);
        }
    }

    &__title {
        display:        block;
        color:          $cadfem-darkblue;
        letter-spacing: 0;
        font-size:      rem(13px);
        line-height:    rem(20px);
        font-weight:    $font-weight-semibold;
        margin-bottom:  0;
        padding:        0;

        &:hover  {
            color: $cadfem-cornflower;
        }

        @include media-breakpoint-down(sm) {
            font-size: $font-size-text-small;
        }
    }

    &__subline {
        margin-top: building-units(0.5);
    }

    &__toggle {
        width:              64px;
        text-decoration:    none;
        color:              $cadfem-darkblue;
        margin-left:        auto;
        align-content:      center;
        display:            flex;
        justify-content:    center;
        align-items:        center;
        flex:               0 16px;
        min-width:          16px;
        line-height:        rem(20px);

        .icon-small-arrow-up,
        .icon-minus {
            display: none
        }

        .icon-small-arrow-down,
        .icon-plus {
            display: inline-block
        }
    }

    &__content {
        font-size:          $font-size-default;
        padding:            0 building-units(1);
        max-height:         0;
        overflow:           hidden;

        p:last-child {
            margin-bottom: 0;
        }

        @extend %accordionOpen;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-text-small;
        }
    }

    &--open {
        > .acc_content2__headline {
            border-bottom-width: 1px !important;
        }

        .acc_content2__title {
            color: $magenta;
        }

        > .acc_content2__content {
            @extend %accordionOpen;
            padding: building-units(1) building-units(1);
            max-height: 99999px;
        }

        > .acc_content2__headline > .acc_content2__toggle {

            > .icon-small-arrow-up,
            > .icon-minus {
                display: inline-block;
            }

            > .icon-small-arrow-down,
            > .icon-plus {
                display: none;
            }
        }
    }

    &--inactive {
        color: $gray-184;

        .acc_content2__headline,
        .acc_content2__title,
        .acc_content2__toggle,
        .acc_content2__subline {
            color: $gray-184;
        }

        .acc_content2__headline,
        .acc_content2__toggle:hover {
            cursor: no-drop;
            color: $gray-184;

            .cadfemicon, .acc_content2__title {
                color: $gray-184;
            }
        }
    }

    &:not(:last-child) .acc_content2__headline {
        border-bottom-width: 0;
    }
}
