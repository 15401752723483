//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.lazyload {
    &__banner {
        background-color: rgba(0, 0, 0, 0.3);
        padding: building-units(2);
    }

    &__banner_text {
        color: $cadfem-purewhite;
        margin-bottom: 25px;
    }
}

.maps_google {
    .lazyload__banner {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .lazyload--play {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;

        &:after {
            position: absolute;
            top: calc(50% - 27.5px);
            left: calc(50% - 27.5px);
            z-index: 100;
            width: 55px;
            height: 55px;
            font-family: 'CadfemIcon', serif;
            font-size: 55px;
            line-height: 55px;
            content: '\6f';
            color: $cadfem-darkblue;
            background-color: $cadfem-purewhite;
            border-radius: 50%;
        }
    }
}

.video-player {
    .lazyload__banner {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    .lazyload--play {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;

        &:after {
            position: absolute;
            top: calc(50% - 27.5px);
            left: calc(50% - 27.5px);
            z-index: 100;
            width: 55px;
            height: 55px;
            font-family: 'CadfemIcon', serif;
            font-size: 55px;
            line-height: 55px;
            content: '\b3';
            color: $cadfem-darkblue;
            background-color: $cadfem-purewhite;
            border-radius: 50%;
        }
    }

    &.js-lazyLoad > figure.video > .video-embed {
        padding-bottom: 0;
    }
}

.storytelling-slide__image {
    .lazyload__banner {
        position: absolute;
        z-index: 200;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: center;
    }

    .lazyload--play {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;

        &:after {
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            z-index: 100;
            width: 80px;
            height: 80px;
            font-family: 'CadfemIcon', serif;
            font-size: rem(80px);
            line-height: 1;
            content: '\b3';
            color: $cadfem-darkblue;
            background-color: $cadfem-purewhite;
            border-radius: 50%;
        }
    }
}

.fotorama:not(.fotorama--fullscreen) {
    .fotorama-video-container .lazyload__banner {
        position: relative;
        z-index: 999;
        text-align: left;
        width: 100%;
        height: 100%;
        max-height: 83%;
        border-radius: 8px;
        overflow-y: auto;

        .lazyload--play {
            height: 100%;
            width: 100%;
        }
    }
}

.fotorama.fotorama--fullscreen {
    .fotorama-video-container .lazyload__banner {
        position: relative;
        z-index: 999;
        text-align: left;
        height: 96%; //to avoid overlap thumbnails
        padding: 0 29%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

        .lazyload--play {
            height: 100%;
            width: 100%;
        }
    }
}
