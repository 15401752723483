.infobox {
    border: 1px solid $cadfem-steamgray;
    border-radius: 4px 8px 8px 4px;
    padding: building-units(1);
    margin-bottom: building-units(1);
    color: $cadfem-darkblue;
    background-color: $cadfem-purewhite;

    display: flex;
    flex-flow: row;
    justify-content: flex-start;

    &--notice,
    &--info {
        border-left: 4px solid $cadfem-cornflower;

        .cadfemicon {
            color: $cadfem-cornflower;
        }

        &.infobox--emphasized {
            background-color: rgba($cadfem-cornflower, 0.15);
        }
    }

    &--warning {
        border-left: 4px solid $cadfem-apricot;

        .cadfemicon {
            color: $cadfem-apricot;
        }

        &.infobox--emphasized {
            background-color: rgba($cadfem-apricot, 0.15);
        }
    }


    &--error,
    &--alert {
        border-left: 4px solid $cadfem-chesnut;

        .cadfemicon {
            color: $cadfem-chesnut;
        }

        &.infobox--emphasized {
            background-color: rgba($cadfem-chesnut, 0.15);
        }
    }

    &--success {
        border-left: 4px solid $cadfem-pinegreen;

        .cadfemicon {
            color: $cadfem-pinegreen;
        }

        &.infobox--emphasized {
            background-color: rgba($cadfem-pinegreen, 0.15);
        }
    }

    .cadfemicon {
        margin-right: building-units(1);
        top: 5px;
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
    }

    &__title {
        font-weight: $font-weight-semibold;
        line-height: 26px;
    }

    &__text {
        line-height: 26px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
    }
}
