//-------------------------------------------------------------
// banner
//-------------------------------------------------------------
// Variables

.banners-premium {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.05);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;

    @include media-breakpoint-down(sm) {
        background-size: 0;
    }

    &__top {
        position: relative;
        z-index: 3;
        border-radius: 4px 4px 0 0;

        &:before {
            content: " ";
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.55) 30%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.55) 30%, rgba(255,255,255,0) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.55) 30%, rgba(255,255,255,0) 100%);

            @include media-breakpoint-down(sm) {
                overflow: inherit;
                height: 150px;
            }
        }

        @include media-breakpoint-down(sm) {
            height: auto;
            overflow: inherit;
        }
    }

    &__bottom {
        position: relative;
        z-index: 2;
        background-color: $cadfem-purewhite;
        border-top: 1px solid $cadfem-steamgray;
        padding: 20px 30px 20px 30px;
        @extend %text-small;

        .list {
            margin: 0;

            > li {
                margin-bottom: 20px;
                font-size: 13px;
                line-height: 18px;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            > li:before {
                color: $cadfem-cornflower;
            }
        }

        @include media-breakpoint-down(sm) {
            margin: 0 20px;
        }
    }

    &__image {
        border-radius: 4px 4px 0 0;
        width: 100%;
        height: 100%;
        background-size: 0;

        @include media-breakpoint-down(sm) {
            background-size: cover;
        }

        @include make-col(12, 12);
    }

    &__content {
        position: relative;
        z-index: 3;
        padding: 20px 0 20px 30px;

        @include media-breakpoint-down(sm) {
            padding: 20px;
            @include make-col(12, 12);
        }

        @include media-breakpoint-down(xl) {
            @include make-col(8, 12);
        }
    }

    &__subline {
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight-semibold;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }
    }

    &__headline, &__introduction {
        margin-bottom: building-units(1);
    }

    &__introduction {
        font-size: 16px;
        line-height: 24px;
    }

    &__benefit {
        &-headline {
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(sm) {
                font-weight: $font-weight-regular;
            }
        }

        &-description {
            display: block;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: auto;

        .banners-premium__image {
            overflow: inherit;
            height: 150px;
            background-size: cover;
            background-position: top center; //CADFEMEB-1625

            // CADFEMEB-1625
            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: -50px;
            }
        }

        .banners-premium__content {
            position: inherit !important;
            @include make-col(12, 12);
        }
    }
}


@include media-breakpoint-up(lg) {
    .row .col-xl-4 .banners-premium .banners-premium__content,
    .row .col-xl-5 .banners-premium .banners-premium__content {
        @include make-col(12, 12);
    }

    .row .col-xl-4 .banners-premium .banners-premium__content,
    .row .col-xl-5 .banners-premium .banners-premium__content {
        > .banners-premium__subline,
        > .banners-premium__headline,
        > .banners-premium__introduction {
            @include make-col(8, 12);
        }
    }
}
