//-------------------------------------------------------------
// research_module
//-------------------------------------------------------------
// Variables
$dropdown_count: 6;
$dropdown_height: $dropdown_count * 45px;

.research_module {

    &-search {
        border-top: 1px solid $cadfem-steamgray;
        border-bottom: 1px solid $cadfem-steamgray;
        padding: building-units(2) 0 0 0;
        margin-bottom: building-units(2);

        span.cadfemicon.icon-filter {
            margin-top: 8px;
        }
    }

    &-filter {
        .dropdown__item label {
            font-size: $font-size-text-small;
            line-height: $line-height-text-small;
            padding: 12.5px building-units(1);
            width: 100%;
        }

        .dropdown__item label:before {
            font-size: 1rem;
            height: building-units(1);
            width: building-units(1);
            left: 10px;
            top: 9px;
        }

        .dropdown__list {
            max-height: $dropdown_height;
            overflow-y: auto;
        }
    }

    &-chips {
        > .row > .col {
            flex-flow: row;
        }

    }

    &-chips-container {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-page {
        & > div:not(#product_options) {
            display: none;
        }
    }

    &-results {
        .research_module-pagination {
            display: none;

            ul {
                margin: 0 auto;
            }
        }

        .row {
            a {
                p {
                    color: $cadfem-darkblue !important;
                }
            }
        }
    }

    &-teaser--show {
        display: flex !important;
    }

    &-teaser--hide {
        display: none !important;
    }

    &-results--show-more {
        .research_module-pagination {
            display: flex;
        }
    }
}

.research_module-search .input-addon {
    max-width: 105px;
    min-width: auto;
}

.research_module2 {
    &-pagetext {
        color: $cadfem-ashgray !important;

        &:has(.research_module-page-start:empty),
        &:has(.research_module-page-end:empty) {
          display: none;
        }
    }

    &-page .swiper-slide .teaser {
        height: 100%;
    }
}
