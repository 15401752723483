//-------------------------------------------------------------
//Contact Box
//-------------------------------------------------------------

// Variables

// General
.contact_box {
    &__label {
        @extend %text-small;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        color: $cadfem-ashgray;
        margin-bottom: building-units(0.666);
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
    }

    &__image {
        min-width: building-units(6);
        width: building-units(6);
        border-radius: 8px;
        margin: 0 building-units(1) building-units(1) 0;
    }

    &__position {
        @extend %text-small;
    }

    &__link {
        @extend %text-small;
        margin-bottom: building-units(0.5);
    }

    &__subheading {
        @extend %text-small;
        color: $cadfem-ashgray;
        margin-bottom: building-units(1);
        font-weight: $font-weight-semibold;
    }

    .img_content-box {
        margin-bottom: 0;
    }

    // Contact_box extern
    &--extern {
        margin-top: 85px;

        .contact_box__image {
            width: 150px;
            margin-top: -90px;
            margin-bottom: building-units(2);
            @extend %shadow--light;
        }

        .acc_content--simple {
            border-top:    1px solid $cadfem-steamgray;
            border-bottom: 1px solid $cadfem-steamgray;
            margin-bottom: building-units(2);

            .acc_content {
                &__headline {
                    height:     auto;
                    min-height: 40px;
                    border-bottom: none;
                    border-top: none;
                    &:hover {
                        color:            $cadfem-cornflower;
                        .acc_content__toggle {
                            background-color: transparent;
                        }
                    }
                }
                &__content {
                    background-color: transparent;
                    padding-bottom: 1px;
                }
                &__title {
                    padding: 15px 0;
                    font-size: 13px;
                }
            }
        }
    }

    // Contact_box inten
    &--intern {
        margin-top: 85px;

        .contact_box__image {
            width: 150px;
            margin-top: -90px;
            margin-bottom: building-units(2);
            @extend %shadow--light;
        }
    }

    // Contact_box referent
    &--referent {
        margin-top: 85px;

        .contact_box__image {
            width: 227px;
            margin-top: -100px;
            margin-bottom: building-units(2);
            @extend %shadow--light;
        }
    }

    // Contact_box direktkontakt
    &--direct {
        .contact_box__wrapper {
            flex-flow: row;
        }
    }

    &--outline {
        border: 1px solid $cadfem-steamgray;
        padding: building-units(2);
        border-radius: 8px;
    }

    //Contact box in footer
    &--footer {
        .card_cf--outline {
            padding-top: 1.7rem;
        }
        p{
            margin-bottom: 5px;
        }
        .contact_box {
            &__subheading {
                text-transform: uppercase;
            }
        }
        .img_content-box {
            width: 90px;
            height: 90px;
            float: left;
            top: 0;
            left: 0;
            margin: 10px 15px 15px;
        }
    }
}
