//-------------------------------------------------------------
// Header
//-------------------------------------------------------------

.header {
    &--fixed {
        position: fixed;
        width:    100%;
        z-index:  10000;
    }
}

.global-bar {
    background: $toolbar-background-color;
    font-size: 12px;

    &__container {
        @extend %flex-container;
        
        justify-content: space-between;
    }

    &__site-settings {
        //margin-left: auto;
    }

    &__link {
        color: $toolbar-text-color;
        text-decoration: none;
        @include reset-hover;

        &:hover,
        &:active,
        &:focus {
            color: $toolbar-text-hover-color;
        }

        &--active {
            font-weight: 900;
        }
    }

}

//-------------------------------------------------------------
// Brandbar
//-------------------------------------------------------------

$brandbar-height-units: 7;

.brandbar {
    background: $brandbar-background-color;
    height: building-units($brandbar-height-units);

    &__container {
        height: 100%;
        display: flex;
    }

    &__claim-text {
        font-size: 15px;
        text-transform: uppercase;
        color: $brandbar-text-color;
        font-weight: 900; 
    }
}


//-------------------------------------------------------------
// Logo
//-------------------------------------------------------------

.logo-wrapper {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.link-logo {
    margin-top: 1px;
}

//-------------------------------------------------------------
// Claim
//-------------------------------------------------------------

.claim-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 2rem;

    img {
        height: auto;
        width: auto;
        max-height: 100%;

    }
}
