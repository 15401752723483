//-------------------------------------------------------------
// CadfemIcon
//-------------------------------------------------------------

@font-face {
  font-family: 'CadfemIcon';
  src: url('#{$project-icon-font-path}/cadfemicon.eot?e35f9c');
  src: url('#{$project-icon-font-path}/cadfemicon.eot?e35f9c#iefix') format('embedded-opentype'),
  url('#{$project-icon-font-path}/cadfemicon.ttf?e35f9c') format('truetype'),
  url('#{$project-icon-font-path}/cadfemicon.woff?e35f9c') format('woff'),
  url('#{$project-icon-font-path}/cadfemicon.svg?e35f9c#cadfemicon') format('svg');
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: block;
}

%cadfemicon {
  position: relative;
  bottom: rem(1px);
  vertical-align: middle;
  display: inline-block;
  font-family: 'CadfemIcon', serif;
  font-size: rem(18px);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1;
  speak: never;
  text-transform: none !important; // if icon is used in uppercase context
  -webkit-font-smoothing: antialiased; // reduce font weight
  -moz-osx-font-smoothing: grayscale; // reduce font weight
}

.cadfemicon {
  @extend %cadfemicon;

  @each $size in $icon-sizes {
    &.icon-#{$size} {
      font-size: rem($size + px) !important;
    }
  }
}

// Icons
.cadfemicon.icon-user:before {
    content: "\61";
}
.cadfemicon.icon-watchlist:before {
    content: "\62";
}
.cadfemicon.icon-directcontact:before {
    content: "\63";
}
.cadfemicon.icon-caddy:before {
    content: "\64";
}
.cadfemicon.icon-search:before {
    content: "\65";
}
.cadfemicon.icon-related-link:before {
    content: "\66";
}
.cadfemicon.icon-extended-link:before {
    content: "\67";
}
.cadfemicon.icon-show-more:before {
    content: "\68";
}
.cadfemicon.icon-arrow-up:before {
    content: "\69";
}
.cadfemicon.icon-watch:before {
    content: "\6a";
}
.cadfemicon.icon-watch-1:before {
    content: "\6b";
}
.cadfemicon.icon-phone:before {
    content: "\6c";
}
.cadfemicon.icon-phone-1:before {
    content: "\6d";
}
.cadfemicon.icon-mail:before {
    content: "\6e";
}
.cadfemicon.icon-global:before {
    content: "\6f";
}
.cadfemicon.icon-remember:before {
    content: "\70";
}
.cadfemicon.icon-plus:before {
    content: "\71";
}
.cadfemicon.icon-minus:before {
    content: "\72";
}
.cadfemicon.icon-close:before {
    content: "\73";
}
.cadfemicon.icon-download:before {
    content: "\75";
}
.cadfemicon.icon-direct-access:before {
    content: "\76";
}
.cadfemicon.icon-direct-access-1:before {
    content: "\77";
}
.cadfemicon.icon-upload:before {
    content: "\78";
}
.cadfemicon.icon-direct-access-2:before {
    content: "\79";
}
.cadfemicon.icon-message-alert:before {
    content: "\7a";
}
.cadfemicon.icon-message-success:before {
    content: "\31";
}
.cadfemicon.icon-message-warning:before {
    content: "\32";
}
.cadfemicon.icon-message-info:before {
    content: "\33";
}
.cadfemicon.icon-watchlist-1:before {
    content: "\34";
}
.cadfemicon.icon-navigation-left:before,
.cadfemicon.icon-small-arrow-left:before {
    content: "\35";
}
.cadfemicon.icon-navigation-right:before,
.cadfemicon.icon-small-arrow-right:before {
    content: "\36";
}
.cadfemicon.icon-navigation-up:before,
.cadfemicon.icon-small-arrow-up:before {
    content: "\37";
}
.cadfemicon.icon-navigation-down:before,
.cadfemicon.icon-small-arrow-down:before {
    content: "\38";
}
.cadfemicon.icon-checkmark:before {
    content: "\39";
}
.cadfemicon.icon-checkbox:before {
    content: "\41";
}
.cadfemicon.icon-quote:before {
    content: "\42";
}
.cadfemicon.icon-lock:before {
    content: "\43";
}
.cadfemicon.icon-user-1:before {
    content: "\44";
}
.cadfemicon.icon-facebook:before {
    content: "\45";
}
.cadfemicon.icon-linkedin:before {
    content: "\46";
}
.cadfemicon.icon-twitter:before {
    content: "\47";
}
.cadfemicon.icon-youtube:before {
    content: "\48";
}
.cadfemicon.icon-xing:before {
    content: "\49";
}
.cadfemicon.icon-rss:before {
    content: "\4a";
}
.cadfemicon.icon-rdbtn-selected:before {
    content: "\4b";
}
.cadfemicon.icon-rdbtn-not-selected:before {
    content: "\4c";
}
.cadfemicon.icon-internal-link-lrg:before {
    content: "\4d";
}
.cadfemicon.icon-external-link-lrg:before {
    content: "\4e";
}
.cadfemicon.icon-internal-link:before {
    content: "\4f";
}
.cadfemicon.icon-document:before {
    content: "\50";
}
.cadfemicon.icon-doc-jpg:before {
    content: "\51";
}
.cadfemicon.icon-doc-png:before {
    content: "\52";
}
.cadfemicon.icon-doc-pdf:before {
    content: "\53";
}
.cadfemicon.icon-share:before {
    content: "\54";
}
.cadfemicon.icon-marker-international:before {
    content: "\55";
    color: #fd9b41;
}
.cadfemicon.icon-marker-national:before {
    content: "\56";
    color: #4b73ab;
}
.cadfemicon.icon-filter-active:before {
    content: "\58";
}
.cadfemicon.icon-filter:before {
    content: "\59";
}
.cadfemicon.icon-lecture-language:before {
    content: "\5a";
}
.cadfemicon.icon-tile-view-active:before {
    content: "\5b";
}
.cadfemicon.icon-tile-view-inactive:before {
    content: "\5d";
}
.cadfemicon.icon-mail-send-on:before {
    content: "\21";
}
.cadfemicon.icon-list-view-active:before {
    content: "\22";
}
.cadfemicon.icon-list-view-inactive:before {
    content: "\a7";
}
.cadfemicon.icon-clock:before {
    content: "\24";
}
.cadfemicon.icon-elearning:before,
.cadfemicon.icon-e-learning:before {
    content: "\25";
}
.cadfemicon.icon-seminar:before {
    content: "\26";
}
.cadfemicon.icon-webinar:before {
    content: "\2f";
}
.cadfemicon.icon-calendar:before {
    content: "\28";
}
.cadfemicon.icon-datepicker:before {
    content: "\29";
}
.cadfemicon.icon-menu:before {
    content: "\7b";
}
.cadfemicon.icon-degreecourse:before,
.cadfemicon.icon-academic-offers:before {
    content: "\40";
}
.cadfemicon.icon-consulting:before {
    content: "\20ac";
}
.cadfemicon.icon-product-services:before {
    content: "\2d";
}
.cadfemicon.icon-project-partner:before {
    content: "\2e";
}
.cadfemicon.icon-quality-consulting:before {
    content: "\2c";
}
.cadfemicon.icon-references-case-studies:before {
    content: "\3b";
}
.cadfemicon.icon-seminars-events:before {
    content: "\3a";
}
.cadfemicon.icon-solution:before {
    content: "\5f";
}
.cadfemicon.icon-speaker:before {
    content: "\27";
}
.cadfemicon.icon-task:before {
    content: "\23";
}
.cadfemicon.icon-time-display:before {
    content: "\2b";
}
.cadfemicon.icon-use:before {
    content: "\2a";
}
.cadfemicon.icon-requirements:before {
    content: "\7e";
}
.cadfemicon.icon-double-arrow:before {
    content: "\7d";
}
.cadfemicon.icon-clearall:before {
    content: "\5c";
}
.cadfemicon.icon-infoday:before,
.cadfemicon.icon-info-event:before {
    content: "\7c";
}
.cadfemicon.icon-level-basic:before {
    content: "\3c";
}
.cadfemicon.icon-level-advanced:before {
    content: "\3e";
}
.cadfemicon.icon-level-expert:before {
    content: "\b5";
}
.cadfemicon.icon-video-play:before {
    content: "\b13";
}
.cadfemicon.icon-play-button:before {
    content: "\b3";
}
.cadfemicon.icon-navigation-arrow:before,
.cadfemicon.icon-navigation-arrow-down:before {
    content: "\b2";
}
.icon-navigation-arrow-down {
    transform: rotate(90deg);
}

.cadfemicon.icon-home:before {
    content: "\b4";
}

.cadfemicon.icon-conference:before {
    content: "\e941";
}

//SOCIAL SHARE
.shariff-button {
    background-color: transparent;
    border-radius: 0;

    > a {
        border-radius: 50%;

        &:before {
            font-size: 28px;
            margin-right: 1px;
            line-height: 30px;
        }
    }
}

.shariff-button.fab {
    display: none;
}

.shariff-button.facebook > a {
    @extend %cadfemicon;
    background-color: #1877f2 !important;
    &:before {
        content: "\b6";
        line-height: 33px;
    }
}
.shariff-button.linkedin > a {
    @extend %cadfemicon;
    background-color: #2867B2 !important;
    &:before {
        content: "\b7";
    }
}
.shariff-button.mail > a {
    @extend %cadfemicon;
    background-color: #00345F !important;
    &:before {
        content: "\b8";
    }
}
.shariff-button.twitter > a {
    @extend %cadfemicon;
    background-color: #1da1f2 !important;
    &:before {
        content: "\b9";
    }
}
.shariff-button.xing > a {
    @extend %cadfemicon;
    background-color: #006567 !important;
    &:before {
        content: "\b10";
    }
}

.shariff-button a:hover {
    box-shadow:inset 0 0 0 99999px rgba(0, 0, 0,0.15);
}

// TODO once the back arrow is added as its own glyph in the iconfont remove this
.cadfemicon.icon-navigation-arrow-back {
    transform: scale(-1); // Flip Arrow

    // because of transform if this icon is used in a button we need to flip paddings
    .btn>&.icon--right, .cc-dismiss>&.icon--right {
        padding-left: 0;
        padding-right: rem(5px);
    }
}
