//-------------------------------------------------------------
// Variables
//-------------------------------------------------------------
$footer-color: $cadfem-purewhite;
$footer-info-font-size: 12px;

//-------------------------------------------------------------
// Footer
//-------------------------------------------------------------
.footer-cf {
    position: relative;
    background-color: $footer-background-color;
    border-top: 7px solid $cadfem-cornflower;
    color: $cadfem-darkblue;

    //-------------------------------------------------------------
    // general
    //-------------------------------------------------------------
    &-headline {
        font-weight: $font-weight-bold;
        font-size: rem(13px);
        line-height: rem(20px);
        margin-bottom: 10px;
        letter-spacing: 0.7px;
        color: $cadfem-darkblue;
    }

    &-leadtext {
        font-weight: $font-weight-regular;
        color: $cadfem-darkblue;
        font-size: rem(13px);
        line-height: rem(20px);
    }

    &-subtitle {
        color: $cadfem-lightblue;
        line-height: 17px;
    }

    .logo {
        height: 37px;

        @include media-breakpoint-down(lg) {
            height: 25px;
        }
    }

    //accordion overwrite
    .acc_content2 .acc_content2__headline {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        padding: rem(10px) 0;
        height: auto;

        @include media-breakpoint-up(md) {
            border-top-color: transparent;
            border-bottom-color: transparent;
        }

        @include media-breakpoint-down(md) {
            height: 50px;
        }

        @include media-breakpoint-only(xs) {
            justify-content: space-between;
            border-bottom: 0;
        }

    }

    .acc_content2 .acc_content2__content {
        padding-left: 0;
    }

    .acc_content2 .acc_content2__title,
    .acc_content2.acc_content2__toggle {

        @include media-breakpoint-down(sm) {
            font-size: rem(16px);
        }


        &:hover {
            color: $link-color-hover;
        }
    }

    .acc_content2 .acc_content2__toggle {
        background-color: transparent !important;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

}

//-------------------------------------------------------------
// brand
//-------------------------------------------------------------
.footer-cf-brand {
    padding-top: building-units(2);
}

//-------------------------------------------------------------
// section
//-------------------------------------------------------------
.footer-cf-section {
    color: $cadfem-darkblue;
    margin-bottom: building-units(2);

    .row>div {
        margin-bottom: 0; //remove grid column margins
    }

}

//-------------------------------------------------------------
// contact
//-------------------------------------------------------------
.footer-cf-contact {
    margin-bottom: building-units(1);

    &-items {
        margin-bottom: building-units(2);

        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $footer-border-color;
        }
    }

    &-area,
    .contact_box__label {
        font-weight: $font-weight-semibold;
        margin-bottom: 10px;
        color: $cadfem-darkblue;
        display: block;
        font-size: rem($font-size-default);
        line-height: rem($line-height-default);
        text-transform: none;
    }

    .contact_box__name {
        font-weight: $font-weight-semibold;
        color: #757575;
        font-size: rem($font-size-default);
        line-height: rem($line-height-default);
        text-transform: none;
    }

    .contact_box__image img {
        border-radius: 50%;
    }

    //acc_content overwrites
    .acc_content2__headline {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
    }

    .acc_content2__title {
        color: $cadfem-cornflower !important;
        font-size: 14px !important;
        letter-spacing: 1.75px;
    }

    .acc_content2__headline .cadfemicon {
        color: $cadfem-cornflower !important;
    }

    .row .row>* {
        margin-bottom: 0;
    }
}

//-------------------------------------------------------------
// social
//-------------------------------------------------------------
.footer-cf-social {
    border-top: 1px solid $footer-border-color;
    border-bottom: 1px solid $footer-border-color;
    margin-bottom: building-units(2);

    a {
        background-color: $cadfem-silkwhite;
        color: $cadfem-darkblue;
    }
}

//-------------------------------------------------------------
// Footer Navigation
//-------------------------------------------------------------
.footer-cf-navigation {
    margin-bottom: building-units(2);

    &__title {
        @extend %text-default;
    }

    ul {
        margin: 0 auto;
        padding-left: 0;

        li {
            display: block;

            a.link {
                @extend %text-small;
                margin-bottom: building-units(1);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        ul li {
            padding-left: building-units(1);
        }

        .acc_content2__headline {
            min-height: 50px !important;

        }

        .acc_content2--open {

            .acc_content2__title,
            .acc_content2__toggle-icon:before {
                color: $cadfem-cornflower;
            }
        }

        .acc_content2__headline {
            padding: 0 building-units(1) !important;
            justify-content: flex-start;
            align-items: center !important;
        }

        .acc_content2__headline:nth-last-child(-n+3) {
            border-bottom-color: transparent;
        }

        .acc_content2__title {
            font-weight: $font-weight-semibold;
        }
    }
    //for the lines of the footer navigation in sm and xs
    .row:nth-child(2) {
        @include media-breakpoint-down(sm) {
            .acc_content2 {
                border-top: solid 1px $footer-border-color;
            }
        }

        @include media-breakpoint-only(sm) {
            [class^="col"]:nth-last-child(-n+3) .acc_content2 {
                border-bottom: solid 1px $footer-border-color;
            }
        }

        @include media-breakpoint-only(xs) {
            [class^="col"]:last-child .acc_content2 {
                border-bottom: solid 1px $footer-border-color;
            }
        }
    }

    .row>div {
        margin-bottom: 0;
    }

    a.link {
        text-decoration: none;
    }
}

//-------------------------------------------------------------
// info
//-------------------------------------------------------------
.footer-cf-info {
    padding: building-units(1) 0;
    line-height: rem(30px);
    font-size: rem($footer-info-font-size);
    border-top: 1px solid $cadfem-steamgray;


    @include media-breakpoint-only(sm) {
        border-top: 0;
    }

    ul {
        margin: 0 building-units(1) 0 0;

        li {
            display: inline-block;
            margin-right: building-units(1);
            position: relative;

            a {
                color: $cadfem-darkblue;
                font-size: rem($footer-info-font-size);

                &:hover {
                    color: $cadfem-cornflower;
                }
            }
        }
    }

    .row>div {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(xs) {
        ul {
            margin: 0 0 building-units(1) 0;
            padding-left: 0;
            text-align: left;
        }

        ul li:not(:last-child):after {
            content: " ";
            position: absolute;
            top: 9px;
            right: -10px;
            height: 12px;
            border-right: 1px solid $cadfem-darkblue;
        }
    }
}

//-------------------------------------------------------------
// copyright
//-------------------------------------------------------------
.footer-cf-copyright {
    text-align: right;
    width: 100%;

    @include media-breakpoint-down(xs) {
        text-align: left;
    }
}
