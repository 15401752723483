//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
.hero-image {
  display: block;
  width: 100%;
  position: relative;
  max-height: rem(417px);
  overflow: hidden;

  /**
   * Elements
   */
  &__image {
    width: 100%;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    padding: 11px 24px 22px 24px;
    width: 100%;
    margin-bottom: 24px;
    display: flex;
  }

  &__content {
    align-self: flex-end;
    max-width: rem(600px);
    max-height: rem(417px);

    > h1 {
      font-weight: 200;
      margin: 0;
    }

    a {
      color: $hero-image-link-color;
      text-decoration: underline;
    }

    a:hover {
      color: $hero-image-link-hover-color;
      text-decoration: underline;
    }
  }

  /**
   * MODIFIER
   */
  //aligment, horizontal-vertical
  &--alignment-left-top {
    .hero-image__wrapper {
      justify-content: flex-start;
    }

    .hero-image__content {
      align-self: flex-start;
    }
  }

  &--alignment-left-center {
    .hero-image__wrapper {
      justify-content: flex-start;
    }

    .hero-image__content {
      align-self: center;
    }
  }

  &--alignment-center {
    .hero-image__wrapper {
      justify-content: center;
    }

    .hero-image__content {
      align-self: center;
      text-align: center;
    }
  }

  &--alignment-center-bottom {
    .hero-image__wrapper {
      justify-content: center;
    }

    .hero-image__content {
      align-self: flex-end;
      text-align: center;
    }
  }

  &--alignment-right-center {
    .hero-image__wrapper {
      justify-content: flex-end;
    }

    .hero-image__content {
      align-self: center;
      text-align: right;
    }
  }

  &--alignment-right-bottom {
    .hero-image__wrapper {
      justify-content: flex-end;
    }

    .hero-image__content {
      align-self: flex-end;
      text-align: right;
    }
  }

  &--gradient-small {
    background: linear-gradient(to right, $magenta, transparent 70%) !important;
  }

  &--bg-primary {
    background-color: rgba($hero-image-background-color, 0.5);
  }

  &--scheme-light {
    color: $hero-image-text-color;
  }

  &--scheme-dark {
      color: $scheme-dark;
  }

  &--small {
    max-height: rem(191px);

    .hero-image__wrapper {
      height: 100%;
      margin-bottom: 0;
    }

    .hero-image__content {
      max-width: rem(660px);
      max-height: rem(191px);
    }
  }
}

//viewport md
@include media-breakpoint-down(lg) {
  .hero-image:not(.hero-image--small) {
    max-height: rem(322px);
    .hero-image__content {
      max-height: rem(322px);
      max-width: rem(460px);
    }
  }

  .hero-image.hero-image--alignment-center:not(.hero-image--small) {
    .hero-image__content {
      max-height: rem(322px);
      max-width: rem(660px);
    }
  }

  .hero-image.hero-image--small {
    max-height: 191px;

    .hero-image__content {
      max-height: rem(191px);
      max-width: rem(660px);
    }
  }
}

//viewport sm
@include media-breakpoint-down(md) {
  .hero-image:not(.hero-image--small) {
    max-height: rem(204px);
    .hero-image__content {
      max-height: rem(204px);
      max-width: rem(460px);
    }
  }

  .hero-image.hero-image--alignment-center:not(.hero-image--small) {
    .hero-image__content {
      max-height: rem(204px);
      max-width: rem(460px);
    }
  }

  .hero-image.hero-image--small {
    max-height: rem(160px);

    .hero-image__content {
      max-height: rem(160px);
      max-width: rem(460px);
    }
  }
}
//viewport xs
@include media-breakpoint-down(sm) {
  .hero-image:not(.hero-image--small) {
    max-height: rem(204px);
    .hero-image__content {
      max-height: rem(204px);
      max-width: rem(290px);
    }
  }

  .hero-image.hero-image--alignment-center:not(.hero-image--small) {
    .hero-image__content {
      max-width: rem(290px);
    }
  }

  .hero-image.hero-image--small {
    max-height: rem(160px);

    .hero-image__content {
      max-height: rem(160px);
      max-width: rem(290px);
    }
  }
}
