//-------------------------------------------------------------
// Navbar
//-------------------------------------------------------------

$navbar-bg-color: $cadfem-silkwhite;
$navbar-br-color: $gray-220;

$navbar-link-font-size-lg: 18px;
$navbar-link-font-size-xl: 21px;
$navbar-link-color: $cadfem-cornflower;
$navbar-link-color-hover: $cadfem-cornflower;
$navbar-link-bg-color-hover: $cadfem-silkwhite;
$navbar-link-color-active: $cadfem-cornflower;
$navbar-link-bg-color-active: $cadfem-silkwhite;
$li-active-navbar-link-color: $cadfem-cornflower;
$li-active-navbar-link-color-hover: $text-color;

.navbar-cf {
    background: $navbar-bg-color;
    position: relative;
    z-index: 20;
    height: building-units(4);
    transition: height 0.3s, padding-bottom 0.3s;

    @include media-breakpoint-up(lg) {
        padding: 0;
        min-height: rem(60px);
    }

    @include media-breakpoint-down(md) {
        background: $cadfem-purewhite;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $cadfem-steamgray;
    }

    &__container {
        padding: 0 building-units(2);
        height: 100%;

        @extend %flex-container;
        align-items: center;

        @include media-breakpoint-up(lg) {
            min-height: rem(60px);
        }
        @include media-breakpoint-down(md) {
            padding: 0 building-units(0.5);
        }
    }
}

.home-link {
    @extend %navbar-link;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &__icon {
        margin-right: 6px;
    }

    &__text {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        line-height: 1;

        span {
            display: block;
            white-space: nowrap;
        }
    }
}

.main-navigation-cf {
    flex: auto;
    display: none;
    width: 100%;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    &__list {
        @extend %horizontal-nav;
        justify-content: space-between;
    }

    &__item {
        border-bottom: 3px solid transparent;
        margin: 0 20px;
        height: 60px;

        &--active,
        &:hover {
            border-bottom-color: $cadfem-cornflower;
        }

        &--active > a {
            color: $li-active-navbar-link-color;
        }
    }

    &__link {
        @extend %navbar-link;

        position:       relative;
        display:        block;
        width:          100%;
        height:         60px;
        font-size:      rem(13px);
        line-height:    rem(30px);
        font-weight:    $font-weight-bold;
        white-space:    nowrap;
        text-align:     center;
        text-transform: uppercase;
        padding:        16px building-units(1);

        &:active {
            color:      $navbar-link-color-active;
            background: $navbar-link-bg-color-active;
        }

        // Styles when list-item is active
        &--active {
            color: $li-active-navbar-link-color;

            &:hover,
            &:focus,
            &:active {
                color: $li-active-navbar-link-color;
            }
        }
    }

    //fix to open menu also within living styleguide typo3
    &--open-on-hover {
        .main-navigation__item:hover {
            .megamenu {
                display: block;
            }
        }
    }
}

.megamenu-cf {
    position: absolute;
    z-index: 10;
    top: 99%;
    width: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 5px 24px 0 rgba(0,0,0,0.05);
    display: none;
    margin-top: 1px;

    &--open {
        display: block;
    }

    &__top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    &__close-button > .cadfemicon {
        font-size: 32px;
        margin-top: 24px;
    }

    &__overview-link {
        @extend %h4;
        @extend %navbar-link;

        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-top: building-units(2);
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            @extend .h5;
            color: $cadfem-cornflower;
        }
    }

    &__menu-list {
        @extend %horizontal-nav;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    &__menu-item {
        position: relative;
        width: 20%;
        min-height: 1px; // Prevent collapsing
        margin-bottom: building-units(3);
        padding: 0 building-units(2) building-units(1) building-units(2);
        display: block;

        &:not(:last-child) {
            &:before {
                content: " ";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                border-right: 1px solid $cadfem-silkwhite;
            }

            &:after {
                content: " ";
                position: absolute;
                top: 0;
                right: 1px;
                height: 100%;
                border-right: 1px solid $cadfem-steamgray;
            }
        }

        &--no-left-padding {
            padding-left: 0;
        }

        &--no-border:before,
        &--no-border:after {
            border-right-color: transparent !important;
        }
    }

    &__menu-item--columns-2 {
        width: 40%;

        .megamenu-cf__submenu {
            display: flex;
            flex-flow: row;
        }

        .megamenu-cf__submenu-list {
            width: 50%;
        }
    }

    &__menu-teaser {
        margin-left: auto;
    }

    &__menu-link {
        @extend %navbar-link;
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        display: block;
        width: 100%;
    }

    &__link-icon {
        margin-right: 6px;
    }

    &__image-box {
        margin-top: building-units(1);
    }

    &__item-image {
        width: 100%;
        max-width: 100%;
        border-radius: 8px;
    }

    &__submenu-list {
        @extend %navigation-list;
        padding-top: 12px;
    }

    &__submenu-item {
        margin-bottom: 6px;
    }

    &__submenu-link {
        @extend %navbar-link;
        font-size: rem(13px);
        line-height: rem(20px);
    }

    &__quicklinks {
        margin-top: building-units(1);

        h6 {
            @extend %text-default;
            font-weight: $font-weight-semibold;
            color: $cadfem-ashgray;
            margin-bottom: 5px;
            text-transform: none;

            .cadfemicon {
                width: 16px;
                height: 16px;
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }

    &__quicklinks-list {
        @extend %horizontal-nav;
    }

    &__quicklinks-item {
        margin-right: building-units(2)
    }

    &__quicklinks-link {
        @extend %navbar-link;
        @extend %text-small;

        color: $cadfem-darkblue;
        line-height: rem(30px);
    }

    /************************************
     * TEASER
     ************************************/
    &__teaser {
        display: flex;
        flex-flow: row;

        &:not(:last-child) {
            margin-bottom: building-units(4);
        }
    }

    &__teaser-image {
        border-radius: 8px;
        margin-right: building-units(1);
        max-width: 180px;
        max-height: 180px;
        height: 180px;
        min-width: -moz-max-content; //1066 fix for Firefox
    }

    &__teaser-content {
        p {
            @extend %text-small;
        }
    }

    &__teaser:hover .megamenu-cf__teaser-content {
        //
        h5, p {
            color: $cadfem-darkblue;
        }

        //trigger link animation
        > span.link:after {
            @extend %internalLinkHover;
        }
    }
}

.login-dropdown {
    &__role {
        font-size: 18px;
        margin-bottom: 24px;
    }
}

.navbar-cf-link-icon {
    @extend %navbar-link;

    padding: 0 building-units(1);
}

.navbar-search-cf {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $navbar-background-color;
    display: none;
    max-height: 70px;

    &--open {
        display: block;
    }

    &__container {
        max-height: 100%;
    }

    &__form {
        display: flex;
        flex-flow: row nowrap;
        padding: 7px 0;

        @include media-breakpoint-up(lg) {
            padding: 6px 0 7px building-units(1);
        }
    }

    &__input {
        flex: 1;
        min-width: 0;
        height: 50px;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        text-indent: building-units(1);

        &:focus {
            outline-style : none;
            box-shadow: none;
        }
    }

    &__close-button {
        align-self: center;
        margin-left: 24px;
        color: $gray-56;
        font-size: 28px;

        &:hover {
            color: $cadfem-cornflower;
        }

        > .cadfemicon {
            margin-right: 0 !important;
        }
    }
}

.navbar-cf-mobile-menu {
  position: absolute;
  z-index: 999;
  top: 60px;
  width: 100%;
  overflow-y: auto;
  background-color: $white;
  padding: 0;
  border: 1px solid $footer-border-color;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.05);

  .navbar-cf__container {
    justify-content: flex-end;
  }

  &__navbar-title {
    font-weight: $font-weight-medium;
    color: $cadfem-darkblue;
  }
}

body.mobile-menu--open {
  overflow: hidden;
}
