//-------------------------------------------------------------
// Selectbox
//-------------------------------------------------------------

// Variables
$optionlist-br-color: $cadfem-steamgray;
$optionlist-bg: $white;
$optionlist-color-primary: $cadfem-darkblue;
$optionlist-color: $optionlist-color-primary;
$optionlist-bg-hover: $cadfem-silkwhite;
$optionlist-bg-select: $gray-220;
$optionlist-bg-hover-select: $gray-199;


// Selectbox stylings
.selectbox {
    position: relative;
    display: inline-block;
    width: 100%;

    .form-input-search {
        border-bottom: 1px solid $optionlist-br-color;
        padding: building-units(1 / 6) 0;
        margin-bottom: building-units(1);

        &::before {
            display: none;
        }

        .form-input {
            border: 0;
            padding: 0 building-units(.5);
            height: building-units(8 / 3);
            width: 100%;
            margin-bottom: 0;
        }
    }

    .empty-result-entry {
        color: $optionlist-color;
        padding: building-units(1);
    }

    &-label {
        height: 45px;
        color: $optionlist-color;
        vertical-align: middle;
        display: inline-flex;
        padding: rem(11px) rem(15px);
        background-color: $optionlist-bg;
        border: 1px solid $optionlist-br-color;
        border-radius: rem(8px);
        white-space: nowrap;
        line-height: 20px;
        width: 100%;

        > span {
            display: block;
            width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include reset-hover();

        &:hover,
        &:focus,
        &:focus-within {
            color: $optionlist-color;
            background-color: $optionlist-bg-hover;
        }

        &::before {
            @extend %cadfemicon;
            content: '\38';

            font-size: 16px;
            padding-left: 6px;
            margin-left: auto;
            align-self: center;

            position: absolute !important;
            left: calc(100% - 30px);
            bottom: 14px !important;
        }

        &--open {
            &:before,
            &::before {
                content: '\37';
            }
        }
    }

    &-optionlist {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: none;
        position: absolute;
        top: calc(45px + 15px + 4px);
        left: 0;
        min-width: building-units(15);
        background: $optionlist-bg;
        border-radius: 8px;
        z-index: 11;
        border: 1px solid $optionlist-br-color;
        font-size: inherit;
        overflow-y: auto;

        &.optionlist-open {
            display: block;
        }

        a {
            color: $optionlist-color;
            text-decoration: none;
            padding: rem(11px) rem(15px);
            display: block;

            &:hover,
            &:focus,
            &:focus-within {
                background: $optionlist-bg-hover;
            }

            &.selectbox-selected {
                color: $cadfem-cornflower;
            }

            &.option-inactive {
                color: $gray-184;
                cursor: no-drop;
            }
        }
    }

    &--inactive {
        .selectbox-label {
            color: $gray-184;
            background-color: #fff;
            border-color: $gray-220;
            cursor: no-drop;
        }
    }

    &.has-error,
    &--alert {
        .selectbox-label {
            background-color: rgba($color-error, .05);
            border-color: $color-error;
            color: $color-error;
        }
    }

    &--open-left {
        .selectbox-optionlist {
            left: auto;
            right: 0
        }
    }

    &--open-center {
        .selectbox-optionlist {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-hidden {
        visibility: hidden;
        position: absolute;
        left: -9999px;
    }

    &--dark {
        .selectbox-label {
            color: #fff;
        }
    }
}

.input-group.has-error .selectbox-label {
    background-color: rgba($color-error, .05);
    border-color: $color-error;
    color: $color-error;

    // Prevent overlapping of the icons (Alert, Select)
    &:after {
        margin-right: 20px;
    }
}
