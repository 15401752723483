//-------------------------------------------------------------
// CARD
//-------------------------------------------------------------
// Variables

.card_cf {
    display: block;
    width: 100%;
    border-radius: 8px;
    padding: rem(25px);
    margin-bottom: building-units(2);

    p:last-child:not(:first-child) {
        margin-bottom: 0;
    }

    &--bg-silkwhite {
        background-color: $cadfem-silkwhite;
    }

    &--bg-default,
    &--bg-white {
        background-color: $white !important;;
    }

    &--bg-primary,
    &--bg-iceblue {
        background-color: $cadfem-iceblue !important;
    }

    //only for living examples cases
    &--bg-secondary {
        background-color: $cadfem-darkblue !important;
        color: $white;

        .link {
            color: $cadfem-purewhite;

            &:hover {
                color: $cadfem-cornflower;
            }
        }
    }

    &--outline {
        border: 1px solid $cadfem-steamgray;
        box-shadow: none;
    }

    &--shadow {
        box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);
    }

    &--backgroundimage {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        .card_cf__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        //dimmer
        &:after {
            background-color: $cadfem-purewhite;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .6;
        }
    }
}

.filters .justify-content-center {
  justify-content: flex-start!important;
}

@media (min-width: 1024px) {
  .wrapper-information-down {
    display: none;
  }
}

@media (max-width: 1024px) {
  .wrapper-information-top {
    display: none;
  }
}
