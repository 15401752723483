//-------------------------------------------------------------
// wallpaper_ref_coop_listing
//-------------------------------------------------------------
// Variables

.wallpaper_ref_coop_listing {
    &-testimonial {
        margin-bottom: building-units(2);
    }

    &-top {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-top--equal-height .card_cf {
        height: 100%;
    }

    &-content {
        width: 100%;
        padding-bottom: building-units(5);
        // CADFEMEB-2057
        // border-bottom: 3px solid $cadfem-steamgray;
        margin-bottom: building-units(2);
    }

}

@include media-breakpoint-only(xs) {
    .wallpaper_ref_coop_listing-content .swiper-teaser .teaser {
        margin: 0;
    }
}

@include media-breakpoint-down(sm) {
    .wallpaper_ref_coop_listing-content .col-md-6.offset-md-0 {
        margin: 0 auto;
        padding-top: 24px;
    }
}

@include media-breakpoint-down(md) {
    .wallpaper_ref_coop_listing-content .swiper-teaser .swiper-container.js-slider.swiper-container-initialized.swiper-container-horizontal.swiper-container-ios {
        width: 100% !important;
    }
    .wallpaper_ref_coop_listing-content .swiper-teaser .swiper-container,
    .wallpaper_ref_coop_listing-content .swiper-teaser .swiper-container .swiper-wrapper .swiper-slide {
        width: 100% !important;
    }
}

.wallpaper-card {
    margin-bottom: building-units(4);
    text-align: center;

    &__headline {
        position: relative;
        z-index: 2;
        line-height: rem(18px);
        margin-bottom: 5px;

        .card_cf--backgroundimage & {
            font-size: rem($font-size-h4);
            line-height: rem($line-height-h4);
            font-weight: $font-weight-bold;
            letter-spacing: 1px;

            @include media-breakpoint-down(md) {
                letter-spacing: 0.8px;
                font-size: rem($font-size-h3-sm);
            }
        }
    }

    &__subline {
        @extend %text-small;

        display: block;
        line-height: rem(18px);
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }

    &__arrow {
        position: absolute;
        bottom: 5px;
        left: 50%;
        margin-left: -7.5px;
        pointer-events: none;
    }

    &__arrow > .cadfemicon {
        font-size: 24px;
        color: $cadfem-cornflower;
    }

    >.card_cf {
        margin-bottom: 0;
        padding-bottom: 35px;
        position: relative;

        &.card_cf--backgroundimage {
            min-height: 135px;
        }

        img {
            width: 100%;
            height: auto;
        }

        &.card_cf--bg-primary:hover {
            background-color: rgba($cadfem-denimblue, .15);
        }

        &:hover,
        &:focus {
            cursor: pointer;
            @extend %shadow--strong;
        }
    }

    &--active > .card_cf {
        @extend %shadow--strong;
    }

    &--active > .card_cf--bg-primary {
        background-color: rgba($cadfem-denimblue, 0.15);
    }

    /*&:hover .cadfemicon.icon-small-arrow-down:before,*/
    &--active .cadfemicon.icon-small-arrow-down:before {
        content: '\37';
    }

    /**
     * responsive things
     */
    @extend %grid-column;
    @include make-col(2, 12);

    &--large {
        @include make-col(4, 12);

        .wallpaper-card__headline {
            @extend .h4;
            margin-bottom: 5px;
        }

        >.card_cf--backgroundimage {
            min-height: 247px;
        }
    }

    &--medium {
        @include make-col(3, 12);
    }

    @include media-breakpoint-only(sm) {
        @include make-col(3, 12);
    }

    @include media-breakpoint-only(xs) {
        @include make-col(6, 12);
    }
}

/**
 * override styles of swiper for subcomponent
 */
.swiper-teaser {

    .swiper-button-next,
    .swiper-button-prev {
        &:after {
            font-size: 36px;
            color: $cadfem-steamgray;
            text-shadow: none !important;
        }

        &:hover {
            background: transparent;
        }

        &:hover:after {
            color: $cadfem-pearlgray;
        }

        @include media-breakpoint-down(md) {
            top: calc(100% + 20px) !important;
            z-index: 59;
        }
    }

    .swiper-slide__content {
        position: inherit;
        max-height: inherit;
        border-color: transparent;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;
    }

    .swiper-pagination-bullets {
        bottom: -30px;
    }

    .swiper-pagination-bullet {
        background-color: $cadfem-steamgray;
        width: building-units(1);
        height: building-units(1);
        margin-right: building-units(0.5);

        &:hover {
            background-color: $cadfem-pearlgray;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: $cadfem-cornflower;
    }
}

.references .wallpaper-card > .card_cf img {
    width: auto;
    max-width: 100%;
    height: 50px;
}
