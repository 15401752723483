//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$icon-collection-tile-border: #efefef;
$icon-collection-background-color: $cadfem-silkwhite;


.icon-collection-tile {
  border: 1px solid $icon-collection-tile-border;
  display: flex;
  flex-direction: column;

  &__header {
    padding: building-units(1.5) 0;
    background-color: $icon-collection-background-color;
    text-align: center;
    color: $black;

    &.dark {
      background: $gray-117;
    }
  }

  &__svg {
    width: building-units(4);
    height: building-units(4);
  }

  &__content {
    padding: building-units(2) building-units(1) building-units(1.5);
    background: $white;
    color: $text-color;
  }

  &__name, &__code, &__ascii {
    display: block;
    line-height: 1;
  }

  &__name {
    color: $gray-117;
    font-weight: $font-weight-medium;
    margin-bottom: 8px;
  }

  &__code {
    @extend .text-small;
    font-weight: $font-weight-medium;
    margin-bottom: 6px;
  }

  &__ascii {
    @extend .text-small;
  }

  &__footer {
    border-top: 1px solid $gray-237;
    padding: rem(building-units(1.5)) rem(building-units(1));
    background: $white;
    color: $text-color;
  }

  &__download {
    text-decoration: none;
    line-height: 24px;
    color: #383838;
  }

  &__download-icon {
    margin-right: 6px;
  }
}
