//-------------------------------------------------------------
// Hero Tabs
//-------------------------------------------------------------

$hero-tab-container-height: auto;
$hero-tab-container-br-color: $cadfem-steamgray;
$hero-tab-area-gradient-color-bottom: $cadfem-iceblue;
$hero-tab-area-gradient-color-top: $cadfem-purewhite;
$hero-tab-area-gradient-color-top-opacity: rgba($cadfem-iceblue, .4);

$hero-tab-area-max-width-medium: 310px;
$hero-tab-area-max-width-large: 430px;


.hero-tab__list {
    flex-flow: row nowrap;
    list-style: none;
    padding: 0;
}

.hero-tab-area {
       background: linear-gradient(to top, $hero-tab-area-gradient-color-bottom 80%, $hero-tab-area-gradient-color-top-opacity 100%);

    &__inner {
        max-width: rem($hero-tab-area-max-width-medium);
        margin: 0 auto;
        padding: rem(15px 15px 30px);

        @include media-breakpoint-up(lg) {
            max-width: rem($hero-tab-area-max-width-large);
        }

        .hero-tab__tab:nth-child(2) & {
            padding-bottom: rem(90px);
        }
        .hero-tab__tab:nth-child(3) & {
            padding-bottom: rem(150px);
        }
        .hero-tab__tab:nth-child(4) & {
            padding-bottom: rem(210px);
        }
    }

    &__skyline,
    &__headline,
    &__subline,
    &__image {
        display: block;
    }

    &__image-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0 auto rem(30px);
    }

    &__image {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
    }

    &__skyline {
        font-size:   rem($font-size-text-small);
        line-height: $line-height-text-small;
        margin-bottom: rem(5px);
        text-transform: uppercase;
    }

    &__headline {
        font-size:   rem($font-size-default);
        line-height: rem($line-height-default);
        margin-bottom: rem(15px);
        font-weight: $font-weight-semibold;

        @include media-breakpoint-up(lg) {
            font-size:   rem($font-size-text-large);
            line-height: $line-height-text-large;
            margin-bottom: rem(15px);
        }
    }

    &__subline,
    &__subline p {
        font-size:   rem($font-size-text-small);
        line-height: $line-height-text-small;
        margin-bottom: rem(20px);

        @include media-breakpoint-up(lg) {
            font-size:   rem($font-size-default);
            line-height: rem($line-height-default);
            margin-bottom: rem(25px);
        }
    }
}

.hero-tab__tab {
    // flex: 1;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: flex-end;
    margin-bottom: 0;

    &::after {
        display: block;
        content: '';
        height: rem(4px);
        background: $cadfem-steamgray;
        // margin: rem(0 -15px);

    }

    &:hover {
        &::after {
            background: $cadfem-cornflower;
        }
    }

    &.active::after {
        background: $cadfem-darkblue;
    }
}

.hero-tab__link {
    padding: rem(15px) rem(25px);
    height: rem(75px);
    background: linear-gradient(to top, $hero-tab-area-gradient-color-bottom 0%, $hero-tab-area-gradient-color-top 80%);
    font-size:   rem($font-size-text-small);
    line-height: $line-height-text-small;
    color: $cadfem-cornflower;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: rem(75px);
        font-size:   rem($font-size-default);
        //line-height: rem($line-height-default);
        line-height: rem(22px);
    }

    .active & {
        color: $cadfem-darkblue;

        &::after {
            content: '';
            position: absolute;
            bottom: rem(-14px);
            left: 50%;
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-left: rem(12px) solid transparent;
            border-right: rem(12px) solid transparent;
            border-top: rem(11px) solid $cadfem-darkblue;
        }
    }

    &-text {
        max-width: rem($hero-tab-area-max-width-medium);
        margin-bottom: rem(-2px);

        @include media-breakpoint-up(lg) {
            max-width: rem($hero-tab-area-max-width-large);
        }
    }
}

.hero-tab-dropdown {
    @extend .tabbar-dropdown;
}

.hero-tab-content {
    @extend .tabbar-content;

    &>.tab-pane {
        padding-top: 0;
        margin-top: rem(20px);

        @include media-breakpoint-up(md) {
            margin-top: rem(60px);
        }
    }
}
