//-------------------------------------------------------------
// kpi-tile
//-------------------------------------------------------------
// Variables

.kpi-tile {
    display: flex;
    flex-flow: row;

    &__wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap !important;
    }

    /**
     * ELEMENTS
     */
    &__image {
        display: grid;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &:before {
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 2;
            margin-top: -24px;
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 24px 22px 24px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    .img_content-box {
        margin-bottom: 0;
    }

    .img_content-box__image {
        border-radius: 0;
    }

    &__img {
        width: 100%;
        max-width: 100%;
        height: auto;
        display: block;
        @extend %imageTransitionHoverOut;
    }

    &__content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        padding: building-units(3) building-units(2);
        position: relative;
        width: 100%;
        height: 100%;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: block;
        }

        &:before {
            content: attr(data-number) " ";
            font-weight: $font-weight-semibold;
            font-size: rem(115px);
            line-height: rem(115px);
            position: absolute;
            z-index: 1;
            left: building-units(2);
            top: 50%;
            margin-top: -57.5px;
            color: rgba($cadfem-darkgray, 0.2);

            @include media-breakpoint-down(lg) {
                font-size: rem(80px);
                line-height: rem(80px);
            }
        }

        >div,
        >button {
            position: relative;
            z-index: 2;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                width: 100%;
            }
        }

        >.link {
            color: $cadfem-darkblue;
        }

        @include media-breakpoint-only(md) {
            padding: 20px;
        }

        @include media-breakpoint-down(xl) {
            padding: building-units(2);
        }
    }

    &__number {
        font-weight: $font-weight-semibold;
        font-size: rem(70px);
        line-height: rem(74px);
        margin-bottom: 5px;

        @include media-breakpoint-down(lg) {
            font-size: rem(40px);
            line-height: rem(54px);
        }
    }

    &__subline {
        font-size: $font-size-h4;
        line-height: $line-height-h4;
        font-weight: $font-weight-bold;
        margin-bottom: building-units(1);

        @include media-breakpoint-only(md) {
            font-size: $font-size-h6;
            line-height: $line-height-h6;
            font-weight: $font-weight-bold;
            margin-bottom: 5px;
        }
    }

    &__text {
        @extend %text-default;
        color: $cadfem-darkblue;
        line-height: 20px;

        @include media-breakpoint-only(md) {
            font-size: 13px;
        }
    }

    /**
     * MODIFIERS
     */
    &--bg-white {
        background-color: $cadfem-purewhite;

        .kpi-tile__image:before {
            border-color: transparent $cadfem-purewhite transparent transparent;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-purewhite transparent;
            }
        }

        &.kpi-tile--position-left .kpi-tile__image:before {
            border-color: transparent transparent transparent $cadfem-purewhite;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-purewhite transparent;
            }
        }
    }

    &--bg-lightblue {
        background-color: $cadfem-lightblue;

        .kpi-tile__image:before {
            border-color: transparent $cadfem-lightblue transparent transparent;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-lightblue transparent;
            }
        }

        &.kpi-tile--position-left .kpi-tile__image:before {
            border-color: transparent transparent transparent $cadfem-lightblue;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-lightblue transparent;
            }
        }
    }

    &--bg-iceblue {
        background-color: $cadfem-iceblue;

        .kpi-tile__image:before {
            border-color: transparent $cadfem-iceblue transparent transparent;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-iceblue transparent;
            }
        }

        &.kpi-tile--position-left .kpi-tile__image:before {
            border-color: transparent transparent transparent $cadfem-iceblue;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-iceblue transparent;
            }
        }
    }

    &--bg-steamgray {
        background-color: $cadfem-steamgray;

        .kpi-tile__image:before {
            border-color: transparent $cadfem-steamgray transparent transparent;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-steamgray transparent;
            }
        }

        &.kpi-tile--position-left .kpi-tile__image:before {
            border-color: transparent transparent transparent $cadfem-steamgray;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-steamgray transparent;
            }
        }
    }

    &--bg-navyblue {
        background-color: $cadfem-darkblue;

        .kpi-tile__image:before {
            border-color: transparent $cadfem-darkblue transparent transparent;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-darkblue transparent;
            }
        }

        &.kpi-tile--position-left .kpi-tile__image:before {
            border-color: transparent transparent transparent $cadfem-darkblue;

            @include media-breakpoint-only(xs) {
                border-color: transparent transparent $cadfem-darkblue transparent;
            }
        }

        .kpi-tile__number,
        .kpi-tile__subline,
        .kpi-tile__text {
            color: $cadfem-purewhite;
        }

        .kpi-tile__number:before {
            color: rgba($cadfem-iceblue, 0.2);
        }
    }

    &--position-right {
        flex-direction: row;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }
    }

    &--position-left {
        flex-direction: row-reverse;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
        }

        .kpi-tile__image:before {
            right: inherit;
            left: 0;
            border-width: 24px 0 24px 22px;

            @include media-breakpoint-only(xs) {
                border-width: 0 24px 24px 24px;
            }
        }
    }

    @include media-breakpoint-only(xs) {
        .kpi-tile__image:before {
            margin-left: -24px;
            left: 50%;
            top: 100%;
            border-width: 0 24px 24px 24px;
        }
    }

    /**
     * animation
     */
    &:hover .kpi-tile__img {
        @extend %imageTransitionHover;
    }

    &:hover .kpi-tile__content>.link:after {
        @extend %internalLinkHover;
    }
}

@include media-breakpoint-only(xl) {

    .kpi-tile__wrapper>a,
    .kpi-tile__wrapper>.kpi-tile {
        max-width: 50%;
        flex: 1 50%;
    }

    .kpi-tile__image,
    .kpi-tile__content {
        max-width: 392px;
        width: 392px;
        max-height: 392px;
    }

    .kpi-tile__content:before {
        width: 392px;
        height: 100%;
    }
}

@include media-breakpoint-only(lg) {

    .kpi-tile__wrapper>a,
    .kpi-tile__wrapper>.kpi-tile {
        max-width: 50%;
        flex: 1 50%;
    }

    .kpi-tile__image,
    .kpi-tile__content {
        max-width: 345px;
        width: 345px;
        max-height: 345px;
    }

    .kpi-tile__content:before {
        width: 345px;
    }
}

@include media-breakpoint-only(md) {

    .kpi-tile__wrapper>a,
    .kpi-tile__wrapper>.kpi-tile {
        max-width: 50%;
        flex: 1 50%;
    }

    .kpi-tile__image,
    .kpi-tile__content {
        max-width: 248px;
        width: 248px;
        max-height: 248px;
    }

    .kpi-tile__content:before {
        width: 248px;
    }
}

@include media-breakpoint-only(sm) {

    .kpi-tile__wrapper>a,
    .kpi-tile__wrapper>.kpi-tile {
        max-width: 100%;
        width: 100%;
    }

    .kpi-tile__image,
    .kpi-tile__content {
        max-width: 50%;
        width: 50%;
        height: auto;
    }

    .kpi-tile__content:before {
        width: 100%;
    }
}

@include media-breakpoint-only(xs) {

    .kpi-tile__wrapper>a,
    .kpi-tile__wrapper>.kpi-tile {
        width: 100%;
        max-width: 100%;
    }

    .kpi-tile__image,
    .kpi-tile__content {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }

    .kpi-tile,
    .kpi-tile__content:before {
        width: 100%;
    }
}
