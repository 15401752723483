//-------------------------------------------------------------
// banner
//-------------------------------------------------------------
// Variables

.banners {
    width: 100vw;
    margin-left: -50vw;
    position: relative;
    left: calc(50%);
    min-height: 255px;
    padding: building-units(4) building-units(1);

    &__wrapper {
        @extend .container;

        display: flex;
        justify-content: flex-start;
    }

    &__content {
        @include make-col(12, 12);
        padding-right: building-units(1);

        @include media-breakpoint-up(sm) {
            @include make-col(8, 12);
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        @include make-col(12, 12);

        @include media-breakpoint-up(sm) {
            @include make-col(4, 12);
        }
    }

    &__large_claim {
        @extend %text-x-large;
        color: $cadfem-cornflower;
        font-weight: $font-weight-light;
        margin-bottom: building-units(2);
        display: block;
    }

    &__introtext {
        @extend %text-large;
        color: $cadfem-cornflower;
        margin-bottom: building-units(2);
        display: block;
        @include media-breakpoint-down(md) {
            font-size: rem(16px);
        }
    }

    &--background-primary {
        background-color: $cadfem-darkblue;
        color: $cadfem-cornflower;

        .link {
            color: $cadfem-purewhite;
        }

        &:hover {
            background-color: rgba($cadfem-darkblue, 0.95);
        }
    }

    &--background-secondary {
        background-color: $cadfem-iceblue;

        &:hover {
            background-color: rgba($cadfem-cornflower, 0.15);
        }
    }

    &--background-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &--position-center {
        > .banners__wrapper {
            justify-content: center;
            flex-flow: wrap;
        }

        .banners__content, .banners__button {
            @include make-col(12, 12);
            text-align: center;
            justify-content: center;
        }
    }

    @include media-breakpoint-only(xs) {
        > .banners__wrapper {
            justify-content: center;
            flex-flow: column;
            display: block; // fix for IE
        }

        .banners__content, .banners__button {
            @include make-col(12, 12);
            text-align: center;
            justify-content: center;
            padding-left: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .banners__button {
            padding-left: 0;
        }
    }
}

.banners:not(.banners--position-center) .banners__button {
    padding-left: building-units(1);
}
