//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
@keyframes slidein {
    from { transform: scaleX(0); }
    to { transform: scaleX(1); }
  }

.typoclou {
    display: inline-flex;
    flex-flow: column;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 100%;

    &__number {
        font-weight: $font-weight-semibold;
        font-size: rem(70px);
        line-height: rem(70px);
        color: $cadfem-darkblue;
        position: relative;
        z-index: 2;
    }

    &__title {
        text-transform: none;
        font-weight: $font-weight-bold;
        color: $cadfem-darkblue;
        margin: 0 0 10px 0;
        width: 100%;
        max-width: 348px;

        @extend .h3;
    }

    &__subline {
        @extend %text-small;
        font-weight: $font-weight-semibold;
        color: $cadfem-cornflower;
        width: 100%;
        max-width: 348px;
    }

    &__line {
        height: building-units(1);
        background-color: $cadfem-lightblue;
        position: relative;
        z-index: 1;
        top: -12px;
        left: 15px;
        //width: 70px;
        //transition: width 450ms ease-in-out 500ms;
        width: 100%;
        transform-origin: left center;

        &--expand {
            //width: calc(100% + 30px);
            animation: 3s slidein;
        }

        &--shrink {
            width: 70px;
        }
    }
}
