.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}

html.no-js img.lazyload { display:none }
