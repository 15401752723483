//-------------------------------------------------------------
// Tabbar-Dropdown
//-------------------------------------------------------------

.tabbar-dropdown {
  width: 100%;
  padding-top: rem(15px);

  .selectbox-label, .selectbox-optionlist {
    width: 100%;
  }
}