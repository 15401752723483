.product-details {
    &__filters {
        &.hide-filters .research_module-search {
            display: none;
        }
    }

    &__subline {
        text-transform: initial;
    }

    &__manufacturerbar {
        border-top: 1px solid $cadfem-steamgray;
        border-bottom: 1px solid $cadfem-steamgray;
        padding: building-units(1);
        height: building-units(4);
        margin-bottom: building-units(1);
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        img {
            height: building-units(2);
            margin-right: building-units(1);
        }
    }

    &__specialistfields {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: building-units(1);

        @include media-breakpoint-only(xs) {
            display: inline-block;
        }

        img {
            width: building-units(2);
            min-width: building-units(2);
            margin-right: 5px;
        }

        @include media-breakpoint-only(xs) {
            .primary {
                float: left;
            }

            img {
                float: left;
            }

            .secondary {
                clear: both;
            }
        }

        .primary span {
            white-space: nowrap;
        }
        .primary {
            margin-top: 6px;
        }
        .secondary {
            margin-top: 6px;
            margin-left: building-units(2);
            position: relative;
        }

        .secondary span {
            &:empty {
                display: none;
            }
            &:before {
                content: " ";
                height: 15px;
                position: absolute;
                left: -15px;
                top: 12px;
                margin-top: -7.5px;
                border-right: 1px solid $cadfem-ashgray;

                @include media-breakpoint-only(xs) {
                    content: "";
                    border-right-color: transparent;
                }
            }
        }
    }

    &__specialistfield {
        line-height: 24px;

        > span {
            @extend %h5;
            min-height: 30px;
        }
    }

    &__optionsblock:before {
        content: " ";
        display: block;
        width: 45px;
        border-top: 2px solid #7198cf;
        margin-bottom: 5px;
    }
}

.product-details-actionbar {
    background-color: $cadfem-purewhite;
    border-top: 7px solid $cadfem-steamgray;
    padding: building-units(3) 0;
    justify-content: space-between;

    &__jumpmarks {
        display: flex;
        flex-flow: row;

        @include media-breakpoint-only(xs) {
            justify-content: center;
        }

        > a {
            margin-right: 25px;
        }
    }

    &__price {
        display: flex;
        flex-flow: row;
        @include media-breakpoint-only(xs) {
            justify-content: center;
        }
    }

    &__buttons {
        width: 100%;
        display: inline-flex;
        flex-flow: row;
        justify-content: flex-end;

        @include media-breakpoint-only(xs) {
            flex-flow: column;
            justify-content: center;
        }

        > button {
            display: inline-flex;
            margin: 0 building-units(1) building-units(1) building-units(1);
        }
    }
}

.product-details-infoinvader {
    margin: 0 building-units(2);

    &__title {
        text-align: center;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }
    }
}

.fotorama {
    &__stage__frame.fotorama__active {
        border-radius: 8px;
    }

    &__stage .fotorama__stage__shaft .fotorama__img {
        transform: none;
        position: inherit;
        border-radius: 8px;
        border: 1px solid $cadfem-steamgray !important;
        left: 0;
        top: 0;
        width: 100% !important;
    }

    &--fullscreen .fotorama__stage__frame .fotorama__img--full {
        border-radius: 16px;
    }

    &__nav-wrap {
        margin-top: building-units(-1);
    }

    &__nav__frame {
        padding-right: 10px !important;
    }

    &__nav__frame.fotorama__active > .fotorama__thumb {
        border: 2px solid $cadfem-cornflower;
    }

    &__thumb {
        border-radius: 8px;
        background-color: $cadfem-steamgray !important;

        &:hover {
            cursor: pointer;
        }
    }

    &__thumb-border {
        display: none;
    }

    &-video-container:after {
        left: 50%;
        top: 50%;
        margin-left: -50px;
        margin-top: -85px;

        @include media-breakpoint-down(xs) {
            margin-top: -75px;
        }
    }

    &__dot {
        background-color: $cadfem-steamgray !important;
        border-color: $cadfem-steamgray !important;
        width: building-units(1) !important;
        height: building-units(1) !important;
        margin-right: building-units(1) !important;
        display: inline-block !important;
        border-radius: 100% !important;

        &:hover {
            background: $cadfem-pearlgray !important;
            border-color: $cadfem-pearlgray !important;
        }
    }

    &__active .fotorama__dot {
        background-color: $cadfem-cornflower !important;
        border: $cadfem-cornflower !important;
    }

    &__wrap .fotorama__grab {
        cursor: pointer !important;
    }
}

.video-thumb-icon {
    @extend %cadfemicon;
    position: relative;

    &:after {
        display: none;
    }

    &:before {
        content:"\b13";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -17px;
        margin-top: -18px;
        font-size: 34px;
        z-index: 6;
        color: $cadfem-purewhite;
        background-color: $cadfem-denimblue;
        border-radius: 50%;
        border: 1px solid #fff
    }

    &:hover {
        cursor: pointer;
    }
}

.product-add-form {
    .selectbox {
        min-width: 170px;
    }
}

.catalog-product-view .product-tile-wrapper.wallpaper-card--active > .product-tile-small {
    @extend %shadow--strong;
}

.catalog-product-view .section_cf {
    padding: building-units(2) building-units(1) 0 building-units(1);
}

.catalog-product-view .section_cf .swiper .swiper-container {
    margin-bottom: 60px;
}

#productdetails .tab-pane.agenda .card_cf {
    height: calc(100% - 15px);
    margin-bottom: 15px;
}

.product-features {
    .benefit-short-description > td > p {
        margin-bottom: 0;
    }

    .benefit-short-description > td > ul {
        @extend .list--icon-confirm;
    }

    td.bold {
        white-space: nowrap;
    }
}

body.customer-logged-in {
    .customer-logged-out {
        display: none;
    }

    .customer-logged-in {
        display: block;
    }
}

body.customer-logged-out {
    .customer-logged-in {
        display: none;
    }

    .customer-logged-out {
        display: block;
    }
}

body.catalog-product-view {
    //because
    .main {
        padding: 30px 0 140px;
    }
}

body.softwarewrapper,
body.hardwarewrapper,
body.tokenwrapper,
body.ecadfemwrapper {
    .filter-can-hide {
        display: none;
    }
}

body.softwarewrapper .product-details-actionbar {
    padding: 45px 0 25px 0 !important;
}


/**
 * product_banner_event_card
 */
.frame-type-mmscadfemebccintegration_heroevent .hero_img__wrapper {
    padding-left: 0;

    @include media-breakpoint-down(sm) {
        padding-left: building-units(0.5);
    }

    .hero_img__card {
        max-width: 43%;
    }
}

.product_banner_event_card span.text {
    letter-spacing: 0;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
}

@include media-breakpoint-only(xs) {
    .product_banner_event_card .eventcard__buttons {
        flex-flow: column;
    }
}

#event-register-form .selectbox-label {
    white-space: normal;
}

.event-register-form span[data-autofillid="product_name"] {
    @extend .h5;
    text-transform: uppercase;
}

body.catalog-product-view .research_module-chips-container {
    .chip {
        margin-top: 0;
    }
}

body.seminar {
    .product-details__top {
        .product-details__top__wrapper {
            display: grid;
            grid-template:
              "first second"
              "third second"
              "third fourth" / 1fr 1fr;
            height: fit-content;
            align-content: space-between;
            margin-left: -15px;
            margin-right: -15px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-flow: row wrap;
                height: unset !important;
            }
        }

        .product-details__top__wrapper__item {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }

        .product-details__top__wrapper__item:nth-child(1) {
            grid-area: first;
        }
        .product-details__top__wrapper__item:nth-child(2) {
            grid-area: second;
        }
        .product-details__top__wrapper__item:nth-child(3) {
            grid-area: third;
        }
        .product-details__top__wrapper__item:nth-child(4) {
            grid-area: fourth;
        }

        /* Force new columns */
        .product-details__top__wrapper::before,
        .product-details__top__wrapper::after {
            content: "";
            flex-basis: 100%;
            width: 0;
            order: 2;
        }
    }

    .product-overview__content {
        ul {
            @extend .list--icon-confirm;
            margin-bottom: 0;
        }
    }

    .seminardates {
        .product-tile-container {
            margin-left: inherit;
            margin-right: inherit;
        }

        .product-tile-small {
            width: 100%;
            height: auto;
        }
    }

    .fotorama {
        .fotorama__nav__frame.fotorama__nav__frame--thumb {
            padding-top: 15px;
            padding-right: 0 !important;
        }

        .fotorama__nav-wrap{
          &--horizontal{
            .video-thumb-icon:before {
              margin-left: -16px !important;
              margin-top: -20px !important;
            }
          }
        }

        .fotorama__nav-wrap{
          &--vertical {
            .video-thumb-icon:before {
              margin-left: -16px !important;
              margin-top: -11px !important;
            }
          }
        }

        .fotorama__thumb,
        .fotorama__img,
        .lazyload__banner,
        .fotorama__stage__frame.fotorama__active,
        .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
            border-radius: 0 !important;
        }

        .fotorama-video-container:after {
            bottom: unset;
            top: 40%;
        }

        .product-video {
            margin: 0 !important;
        }
    }

    .gallery-placeholder {

        .fotorama {
            @include media-breakpoint-up(lg) {
                max-height: 344px !important;
            }

            @include media-breakpoint-up(xs) {
                max-height: 416px
            }
        }

        &.without-thumbnails .fotorama {
            @include media-breakpoint-up(lg) {
                max-height: 435px !important;
            }

            @include media-breakpoint-up(xs) {
                max-height: 569px
            }
        }
    }

    > .fotorama {
        .fotorama-video-container:after {
            bottom: unset;
            top: 50% !important;
        }
    }

    #agenda .card_cf {
        margin-bottom: building-units(1) !important;
    }

    .product-tile-small {
        margin-bottom: building-units(2) !important;
    }
}
