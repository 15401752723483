//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables

.component-demonstrator {

    &__headline {
        &:after {
            @extend %teleicon;
            color: $component-demonstrator-after-color;
            content: "";
            margin-left: building-units(1);
        }

        &:hover:after {
            content: "\61"
        }
    }

    &__technology {
        display: none;

        &.show {
            display: block;
        } 
    }
}

@import "briefing-component";
@import "code-component-demonstrator";
@import "syntax-highlight";
@import "living-example-demonstrator";