.video-layer-trigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($cadfem-purewhite, 0.6);
    color: $cadfem-cornflower;

    &:hover {
        color: $cadfem-denimblue;
    }

    &:active {
        color: $cadfem-darkblue;
    }


    &::after {
        display: block;
        width: 80px;
        height: 80px;
        font-family: 'CadfemIcon', serif;
        font-size: rem(80px);
        font-style: normal;
        font-weight: $font-weight-regular;
        line-height: 1;
        text-transform: none !important; // if icon is used in uppercase context
        -webkit-font-smoothing: antialiased; // reduce font weight
        -moz-osx-font-smoothing: grayscale; // reduce font weight

        content: "\b3";

        // TODO add play icon
    }
}

.video-layer {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        position: relative;
        z-index: 5;
        width: 98%;
        max-width: 960px;

        @include media-breakpoint-up(lg) {
            max-width: 1067px;
        }
    }

    &__dimmer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba($cadfem-darkblue, 0.5);
        z-index: 4;
    }

    &__close-btn {
        position: absolute;
        z-index: 5;
        top: -40px;
        right: 0;
        color: $cadfem-purewhite;
        padding: 0;
        height: auto;
        border: none;

        @include media-breakpoint-up(md) {
            top: 0;
            right: -40px;
        }
    }

    .video-js .vjs-tech {
        position: inherit;
        top: auto;
        left: auto;
    }

    .vjs-big-play-button {
        display: none;
    }

    &--hidden {
        display: none;
    }
}
