//-------------------------------------------------------------
// General
//-------------------------------------------------------------

* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing:  antialiased;
}

// Set the rem baseline to 1 Building Unit
html {
    @include rem-baseline;
    min-height: 100%;
    position: relative;
}

body {
    @extend %text-default;

    font-family: $font-family-base;
    color: $cadfem-darkblue;
    font-size: rem($font-size-base);
    line-height: rem($line-height-base);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

html,
body {
    overflow-x: hidden;
}

@include media-breakpoint-only(xs) {
    .container {
        min-width: $screen-xs;
    }
}

/**
 * override 3th party
 */
.gm-style {
    font-family: $font-family-base;
    @extend %text-default;
}
