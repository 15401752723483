//-------------------------------------------------------------
// {component}
//-------------------------------------------------------------
// Variables
$iframe-bg-color: #f4f6f8;

$iframe-heights: (
  sm: 120px,
  md: 300px,
  lg: 900px,
  xl: 1200px
) !default;

.living-example-demonstrator {
  width: 100%;
  border-radius: rem(4px);
  border: 1px solid $gray-220;
  margin: rem(24px 0 0);
  background-color: $cadfem-steamgray;
  overflow: hidden;

  &--fullscreen {
    position: relative;
  }

  &__top {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: rem(1px) solid $gray-220;
    background-color: $cadfem-steamgray;
  }

  &__viewportswitcher, &__extra_button {

    button {
      background-color: $gray-248;
      border: none;
      padding: building-units(1);
      border-radius: 0;
      margin: 0;
    }

    .teleicon {
      padding-right: 0;
    }
  }

  &__viewportswitcher {
    flex: 0 auto
  }

  &__extra_button {
    flex: 0 auto;
    margin-left: auto;

      .btn--icon {
        background-color: $cadfem-steamgray;
        color: $cadfem-cornflower;
        border-radius: 0;

        &:hover {
          background: $cadfem-pearlgray;
          color: $cadfem-denimblue;
        }
      }
  }

  &__extra_button {
    text-align: right;
  }

  &__footer {
    //border-bottom: 1px solid $gray-220;
    display: flex;
    flex-flow: row nowrap;

    button {
      border-radius: 0;
      border: none;
      padding: building-units(1);
      margin: 0;
    }

  }

  &__show-code {
    margin-left: auto;
    padding: rem(0 12px);
  }
}

.modal .living-example-demonstrator__top {
  display: none;
}

.iframe-container {
  position: relative;
  text-align: center;
  overflow: hidden;

  @each $size, $value in $iframe-heights {
    &.is-height-#{$size} {
      height: $value;
    }
  }

  iframe {
    display: block;
    border: none;
    margin: 0 auto;
    background: #fff;
    transition: opacity .2s;
    min-height: 100%;
    margin-top: building-units(2);
  }
}

.iframe-container-inner {
  display: inline-block;
  height: 100%;
  max-width: 100%;
  overflow: auto;
  outline: 1px solid #dcdcdc;
  background-color: $iframe-bg-color;
  opacity: 0;
  transition: opacity 400ms;

  &.loaded {
    opacity: 1;
  }
}


.viewport-switcher {
  flex: 0 auto;

  .radio-btn-input {
    opacity: 1;
    width: 100%;
    height: 100%;

    + label {
      background: $cadfem-steamgray;
      color: $cadfem-cornflower;
      margin-bottom: 0;

      &:hover {
        background: $cadfem-pearlgray;
        color: $cadfem-denimblue;
      }
    }

    &:checked + label {
      background: $cadfem-darkblue;
      color: $cadfem-purewhite;
    }
  }

  .btn {
    width: rem(40px);
    background: none;
    border-radius: 0;
    padding: rem(6px 0);

    .teleicon{
      font-size: rem(24px);
      text-align: center;
    }
  }
}

/******************************************************
 * SLIDER
 ******************************************************/
/*! nouislider - 13.1.1 - 2/14/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  z-index: 2;
  direction: ltr;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 25px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-marker.noUi-marker-large {
  top: -16px;
  height: 16px;
}

.noUi-marker.noUi-marker-normal {
  display: none;
}

.noUi-value {
  top: -16px;
  width: 2px !important;
  text-indent: building-units(1);
  background-color: $cadfem-steamgray;
}

#viewport-slider .slider-rangeHighlight.viewport-xs {
  background: $cadfem-pinegreen;
}

#viewport-slider .slider-rangeHighlight.viewport-sm {
  background: $cadfem-apricot;
}

#viewport-slider .slider-rangeHighlight.viewport-md {
  background: $cadfem-iceblue;
}

#viewport-slider .slider-rangeHighlight.viewport-lg {
  background: $cadfem-darkblue;
}

#viewport-slider .slider-rangeHighlight.viewport-xl {
  background: $cadfem-chesnut;
}
