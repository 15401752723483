//-------------------------------------------------------------
// Table
//-------------------------------------------------------------

// Variables
$table-border-color: $cadfem-steamgray; //$gray-220;
$table-checkbox-size: building-units(2);
$table-controls-size: building-units(2);

$table-sorting-height: 18px;
$table-sorting-icon-font-size: 16px;
$table-sorting-icon-color: $gray-178;
$table-sorting-icon-color-active: $text-color;



// Table container for scrolling horizontal
.table-container {
    padding-bottom: building-units(1);
    overflow-x: auto;
    overflow-y: hidden;
    display: block;

    @media all and (max-width: $screen-md) {
        margin: 0;
    }

    h3 {
        margin-bottom: building-units(2);
    }
}

.table-caption {
    @extend %text-small;

    text-align: left;
    margin: building-units(1) 0 building-units(2) 0;
    color: $cadfem-ashgray;
    caption-side: bottom; //default at the end of table
}

.table-subheader {
    @extend %text-default;

    display: block;
    margin: building-units(1) 0 building-units(2);
}

// General table styles
.table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include media-breakpoint-down(sm) {
        display: block;
        overflow: auto;
    }

    &.small {
        font-size: 13px;
    }

    >thead th,
    >tbody td,
    >tbody th {
        text-align: left;

        &.table-column-checkbox {
            padding: 11px 0 building-units(1);
            width: $table-checkbox-size;
            max-width: $table-checkbox-size;

            +td,
            +th {
                text-align: left;
            }

            .form-checkbox-set {
                margin: 0;

                .form-checkbox {
                    +label {
                        padding: 0;
                    }
                }
            }
        }

        &.table-column-controls {
            padding: 11px 0 building-units(1);
            width: $table-controls-size;
            max-width: $table-controls-size;
        }
    }

    >thead {
        th {
            border-bottom: 1px solid $table-border-color;
            padding: building-units(1);
            vertical-align: bottom;

            @include media-breakpoint-down(sm) {
                min-width: 130px;
            }
        }
    }

    >tbody {

        td,
        th {
            border-bottom: 1px solid $table-border-color;
            padding: 13px building-units(1);
            vertical-align: top;

            @include media-breakpoint-down(sm) {
                min-width: 130px;
            }
        }
    }
}

.table-filtering {
    margin-bottom: building-units(2);
    position: relative;

    .action-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0;
    }

    .form-input-set {
        margin: 0;
    }

    .link {
        line-height: building-units(3);

        @media all and (max-width: $screen-md) {
            margin-top: building-units(1);
            text-align: center;
            width: 100%;
        }
    }
}

.is-sortable {
    cursor: pointer;
    white-space: nowrap;

    .thead-entry {
        display: inline-block;
    }

    &.sorting-asc {
        .cadfemicon {
            &.icon-small-arrow-up1 {
                color: $table-sorting-icon-color-active;
                top: 0;
            }

            &.icon-small-arrow-down {
                display: none;
            }
        }
    }

    &.sorting-desc {
        .cadfemicon {
            &.icon-small-arrow-up1 {
                display: none;
            }

            &.icon-small-arrow-down {
                color: $table-sorting-icon-color-active;
                bottom: 0;
            }
        }
    }
}

.sorting {
    display: inline-block;
    padding-left: building-units(.5);
    vertical-align: middle;
    height: $table-sorting-height;
    position: relative;

    .cadfemicon {
        font-size: $table-sorting-icon-font-size;
        position: absolute;

        &.icon-small-arrow-up1 {
            color: $table-sorting-icon-color;
            top: -6px;
        }

        &.icon-small-arrow-down {
            color: $table-sorting-icon-color;
            bottom: -2px;
        }
    }
}

table {
    td,
    th {
        vertical-align: top;
    }
}

// CKEditor 5 adjustments
div.frame figure.table {
  margin: 0;
}
