//-------------------------------------------------------------
// Tabbar
//-------------------------------------------------------------

$tabbar-container-height: auto;
$tabbar-container-br-color: $cadfem-steamgray;

.tabbar-container {
    display:       inline-block;
    height:        $tabbar-container-height;

    &:before {
        display:        block;
        content:        "";
        width:          100%;
        position:       absolute;
        left:           0;
        bottom:         0;
        border-bottom:  3px solid $tabbar-container-br-color;
    }
}

@import "tabbar-list";
@import "tabbar-link";
@import "tabbar-content";
@import "tabbar-dropdown";