//-------------------------------------------------------------
// Accordion
//-------------------------------------------------------------
// Variables

.acc_content {
    display: block;
    border: 1px solid $cadfem-steamgray;


    &__stack {
        > .acc_content:not(:last-child) {
            border-bottom: 0;
        }

        > .acc_content--open {
            border-bottom: 1px !important;
        }

        > .acc_content:not(.acc_content--open):not(:last-child) .acc_content__toggle {
            position: relative;

            &:before {
                position: absolute;
                content: " ";
                border-bottom: 1px solid $cadfem-purewhite;
                bottom: -1px;
                left: 0;
                width: 100%;
            }
        }
    }

    &__headline {
        @include flexbox();
        flex-direction:   row;
        flex-wrap:        nowrap;
        justify-content:  flex-start;
        align-items:      stretch;
        width:            100%;
        cursor:           pointer;
        text-decoration:  none;
        height:           auto;
        min-height:       64px;

        @include reset-hover();

        //wrapper for header and subline
        > div {
            @include flexbox();
            justify-content:  center;
            align-items:      center;
            height:           auto;
            min-height:       64px;
        }

        &:hover, &:active {
            cursor: pointer;

            .acc_content__toggle {
                background-color: $cadfem-pearlgray;
            }

            .cadfemicon, .acc_content__title {
                color: $magenta;
            }
        }

        > .cadfemicon {
            line-height: $line-height-h3;
            margin-right: building-units(0.5);
        }
    }

    &__title {
        display:        block;
        color:          $cadfem-darkblue;
        font-size:      $font-size-text-small;
        line-height:    $line-height-text-small;
        font-weight:    $font-weight-semibold;
        letter-spacing: 0;
        padding:        15px 25px;
    }

    &__subline {
        margin-top: building-units(0.5);
    }

    &__toggle {
        flex:               0 0 64px;
        width:              64px;
        height:             auto;
        min-height:         64px;
        text-decoration:    none;
        color:              $cadfem-darkblue;
        background-color:   $cadfem-steamgray;
        margin-left:        auto;
        align-content:      center;

        @include flexbox();
        justify-content:    center;
        align-items:        center;

        .icon-small-arrow-up,
        > .icon-minus {
            display: none
        }

        .icon-small-arrow-down,
        > .icon-plus {
            display: inline-block
        }
    }

    &__content {
        font-size: $font-size-default;
        padding: 0 building-units(1.67);
        max-height: 0;
        overflow: hidden;

        @extend %accordionOpen;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &--open {
        > .acc_content__headline {
            background-color: $cadfem-silkwhite;
            color: $magenta;
        }

        .acc_content__title {
            color: $magenta;
        }

        .acc_content__toggle {
            background-color: $cadfem-steamgray;
        }

        > .acc_content__content {
            @extend %accordionOpen;

            background-color: $cadfem-silkwhite;
            padding: building-units(1) building-units(1.67);
            max-height: 9999px;
        }

        > .acc_content__headline > .acc_content__toggle {

            > .icon-small-arrow-up,
            > .icon-minus {
                display: inline-block;
            }

            > .icon-small-arrow-down,
            > .icon-plus {
                display: none;
            }
        }
    }

    &--inactive {
        color: $gray-184;

        .acc_content__headline,
        .acc_content__title,
        .acc_content__toggle,
        .acc_content__subline {
            color: $gray-184;
        }

        .acc_content__headline,
        .acc_content__toggle:hover {
            cursor: no-drop;
            color: $gray-184;

            .cadfemicon, .acc_content__title {
                color: $gray-184;
            }
        }

        .acc_content__headline:hover {
            .acc_content__toggle {
                background-color: $cadfem-steamgray;
            }
        }
    }
}
