//-------------------------------------------------------------
// Linklist File
//-------------------------------------------------------------

//Thumbnails
.linklist_thumbnail {
    display: inline-flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: building-units(1);

    &__image {
        position: relative;
        margin-right: building-units(1);
    }

    &__icon {
        position: absolute;
        top: 120px;
        right: 0;

        color:  $cadfem-purewhite;
        width:  building-units(2);
        height: building-units(2);
        background-color: $cadfem-cornflower;
        margin-right: 0 !important;
    }

    &__img {
        width:  building-units(6);
        height: building-units(6);
        transition: transform .8s ease-out;
        transform: scale(1.00);

        &:hover {
            transition: transform .8s ease-in, .8s ease-out;
            transform: scale(1.08);
        }

        @include media-breakpoint-up(lg) {
            width:  building-units(10);
            height: building-units(10);
        }
    }

    &--small {
        .linklist_thumbnail__img {
            width:  building-units(6);
            height: building-units(6);
        }

        .link__image {
            min-width:  building-units(6);
            width:  building-units(6);
            height: building-units(6);
        }
    }

    .link__image {
        overflow: hidden;
    }
    .icon-document {
        border: 1px solid;
        border-color: $cadfem-cornflower;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            border-color: $cadfem-denimblue;
            &:before {
                color: $cadfem-denimblue;
            }
        }
    }

}
