//-------------------------------------------------------------
// product-tile
//-------------------------------------------------------------
// Variables

/** can override the design if it shows list or grid view */
.product-tile-container {
    display: flex;
    flex-flow: row wrap;
    /*width: 100%;*/

    /* to center tiles in mobile viewport */
    @include media-breakpoint-up(sm) {
        margin-left: -15px;
        margin-right: -15px;
    }

    .product-tile__badges {
        position: relative;
        z-index: 100;
        top: -17px;
        right: 0;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        max-width: 100%;
        max-height: building-units(2);
        overflow: hidden;
    }

    .product-tile__badge {
        padding: 5px building-units(1);
        margin-right: building-units(1);
        margin-bottom: building-units(1);
        background-color: $cadfem-cornflower;
        color: $cadfem-purewhite;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1.2px;
        height: 30px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    &--grid {
        .product-tile-wrapper {
            @include make-col(4, 12);

            @include media-breakpoint-down(sm) {
                @include make-col(12, 12);
            }
        }

        .product-tile {
            flex-direction: column;
            border-radius: 8px;
            border-top-color: $cadfem-steamgray !important;

            .product-tile__image,
            .product-tile__content,
            .product-tile__info {
                flex: 0 0 auto;
                max-width: 100%;
            }

            .product-tile__info {
                margin-top: auto;
            }

            .product-tile__image {
                &:before {
                    @extend %dusk-white-gradient;
                }

                figure.img_content-box {
                    height: auto;
                    width: 100%;
                    margin-bottom: 0;
                }

                picture.img_content-box__image {
                    width: 100%;
                    height: auto;
                }

                img.img_content-box__img {
                    height: auto;
                    width: 100%;
                    /*min-height: 205px;*/
                    max-width: 100%;
                    border-radius: 0;
                    position: inherit;
                    top: 0;
                    right: inherit;
                    left: 0;
                }
            }

            .product-tile__manufacturer-logo {
                flex-flow: row;
                justify-content: flex-start;
                align-items: flex-end;
                padding-bottom: building-units(1);
            }

            .product-tile__content {
                padding-bottom: 0;

                > .product-tile__badges {
                    display: none
                }

                &.has-badges {
                    margin-top: -30px;
                }
            }

            .product-tile__info {
                flex-direction: row-reverse;
                background-color: transparent;
            }

            .product-tile__wishlist {
                padding-top: 5px;
                margin-bottom: 0;
            }

            .hidden-xs-up {
                display: flex !important;
            }
        }

        .product-tile--placeholder {
            max-width: 458px;
            width: 100%;
        }

        .wishlist-title {
            display: none;
        }

        .hidden-grid {
            display: none;
        }
    }

    &--list {
        .product-tile {
            position: relative;
            border-top-width: 3px;
            border-radius: 0 0 8px 8px;
            min-height: 250px;
            background-image: url(../assets/images/welle.png);
            background-size: 100% 130px;
            background-repeat: no-repeat;
            background-position: left bottom;
            margin-bottom: building-units(1);

            .product-tile__image {
                border-bottom-width: 0;
                border-radius: 0 0 0 8px;

                &:before {
                    @extend %dawn-white-gradient;
                }
            }

            > .product-tile__badges {
                display: none;
            }

            .product-tile__content {
                position: relative;
            }

            .product-tile__badges {
                position: absolute;
                top: 0;
                right: 0;
                justify-content: flex-start;
                height: auto;
                max-height: inherit;
                width: fit-content !important;
            }

            .product-tile__badge {
                width: fit-content;
            }

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                @include make-col(4, 12);
                border-radius: 8px;

                &:not(:nth-child(3n)) {
                    padding-right: building-units(1);
                }

                .product-tile__image,
                .product-tile__content,
                .product-tile__info {
                    flex: 0 0 auto;
                    max-width: 100%;
                }

                .product-tile__image {
                    overflow: hidden;
                    max-height: 215px;

                    figure.img_content-box {
                        height: auto;
                        min-height: 370px;
                        width: 100%;
                    }

                    picture.img_content-box__image {
                        width: 100%;
                        height: auto;
                        max-height: 215px;
                        position: inherit;
                    }

                    img.img_content-box__img {
                        height: auto;
                        width: 100%;
                        max-width: 100%;
                        border-radius: 8px 8px 0 0;
                    }
                }

                .product-tile__manufacturer-logo {
                    flex-flow: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                    padding-bottom: building-units(1);
                }

                .product-tile__info {
                    flex-direction: row-reverse;
                    background-color: transparent;
                }
            }

            .product-tile--placeholder {
                height: 400px;
                width: 100%;
            }
        }

        .hidden-list {
            display: none;
        }
    }
}

@include media-breakpoint-up(md) {
    .col-lg-4, .col-md-4, .col-lg-6, .col-md-6 {
        .product-tile-widget {
            .product-tile-wrapper {
                min-width: 100%;
            }
        }
    }
}

.product-tile {
    background-color: $cadfem-purewhite;
    border: 1px solid $cadfem-steamgray;
    border-top-width: 1px;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 0 0 8px 8px;
    font-size: $font-size-text-small;
    line-height: $line-height-text-small;
    height: 100%;
    text-align: left;

    @include make-col(12, 12);

    &-widget {
        .product-tile-wrapper {
            min-width: 100%;
            @include media-breakpoint-up(md) {
                min-width: 33%;
            }
        }
    }

    &-wrapper {
        padding: building-units(1);
        width: 100%;
    }

    &__image {
        @include make-col(2, 12);
        position: relative;
        overflow: hidden;
        border-bottom-style: solid;
        border-bottom-width: 3px;
        border-bottom-color: $cadfem-steamgray;
        border-radius: 8px 8px 0 0;

        @extend .gradient;

        > a {
            display: block;
            height: 100%;
        }

        figure.img_content-box {
            border-radius: 0;
            height: 100%;
        }

        picture.img_content-box__image {
            width: auto;
            height: 100%;
            border-radius: 0;
            position: relative;
        }

        img.img_content-box__img {
            height: 100%;
            width: auto;
            max-width: inherit;
            border-radius: 0 0 0 8px;
            //image cutout for list view
            position: absolute;
            top: 0;
            right: -150px;
        }
    }

    &__manufacturer-logo {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        > img {
            max-width: 100px;
            max-height: 50px;
            margin-top: 15px;
            margin-left: building-units(1);
        }
    }

    &__content {
        @include make-col(7, 12);
        padding: 25px;
    }

    &__specialfield {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: building-units(1);
        font-weight: $font-weight-semibold;
        line-height: 15px;
        text-transform: uppercase;
        letter-spacing: 1.6px;

        > img {
            width: building-units(2);
            min-width: building-units(2);
            margin-right: 5px;
        }
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
    }

    &__option {
        width: 100%;

        &--50 {
            width: 50%;
        }
    }

    &__info {
        @include make-col(3, 12);
        background-color: $cadfem-iceblue;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__info-label,
    &__info-value {
        margin-bottom: 0;
        text-transform: none;
        line-height: building-units(1);
    }

    &__wishlist {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: building-units(1);

        > a {
            color: $cadfem-cornflower;

            &:hover {
                color: $cadfem-denimblue;
            }
        }
    }

    &__price {
        padding-bottom: building-units(1);
        margin-bottom: 20px;
        border-bottom: 1px solid $cadfem-steamgray;
    }

    &__button .btn {
        width: 100%;
    }

    &--placeholder {

    }

    /***************************************
     * BORDER COLORS
     *************************************+*/
    &--specialistfield-acoustics {
        border-top-color: #554280;

        .product-tile__specialfield {
            color: #554280;
        }

        .product-tile__image {
            border-bottom-color: #554280;
        }
    }

    &--specialistfield-electromagnetics {
        border-top-color: #008752;

        .product-tile__specialfield {
            color: #008752;
        }

        .product-tile__image {
            border-bottom-color: #008752;
        }
    }

    &--specialistfield-embeddedsoftware {
        border-top-color: #cd871e;

        .product-tile__specialfield {
            color: #cd871e;
        }

        .product-tile__image {
            border-bottom-color: #cd871e;
        }
    }

    &--specialistfield-scalabilityofsimulations {
        border-top-color: #c3811f;

        .product-tile__specialfield {
            color: #c3811f;
        }

        .product-tile__image {
            border-bottom-color: #c3811f;
        }
    }

    &--specialistfield-fluidmechanics {
        border-top-color: #0079c1;

        .product-tile__specialfield {
            color: #0079c1;
        }

        .product-tile__image {
            border-bottom-color: #0079c1;
        }
    }

    &--specialistfield-structuralmechanics {
        border-top-color: #55305c;

        .product-tile__specialfield {
            color: #55305c;
        }

        .product-tile__image {
            border-bottom-color: #55305c;
        }
    }

    &--specialistfield-discreteparticlesimulations {
        border-top-color: #a01414;

        .product-tile__specialfield {
            color: #a01414;
        }

        .product-tile__image {
            border-bottom-color: #a01414;
        }
    }

    &--specialistfield-temperaturefields,
    &--specialistfield-heattransfer {
        border-top-color: #e46226;

        .product-tile__specialfield {
            color: #e46226;
        }

        .product-tile__image {
            border-bottom-color: #e46226;
        }
    }

    &--specialistfield-optics {
        border-top-color: #cf9b1d;

        .product-tile__specialfield {
            color: #cf9b1d;
        }

        .product-tile__image {
            border-bottom-color: #cf9b1d;
        }
    }

    &--specialistfield-systemsimulation {
        border-top-color: #51c6d9;

        .product-tile__specialfield {
            color: #51c6d9;
        }

        .product-tile__image {
            border-bottom-color: #51c6d9;
        }
    }

    &--specialistfield-conceptdesignphasesimulations {
        border-top-color: #553F81;

        .product-tile__specialfield {
            color: #553F81;
        }

        .product-tile__image {
            border-bottom-color: #553F81;
        }
    }

    &--specialistfield-overviewofsimulation,
    &--specialistfield-simulationandmanagement,
    &--specialistfield-biomechanics {
        border-top-color: #0079c1;

        .product-tile__specialfield {
            color: #0079c1;
        }

        .product-tile__image {
            border-bottom-color: #0079c1;
        }
    }

    &--specialistfield-multiphysics {
        border-top-color: #0079c1;

        .product-tile__specialfield {
            color: #0079c1;
        }

        .product-tile__image {
            border-bottom-color: #0079c1;
        }
    }
}

.product-tile-small {
    background-color: $cadfem-purewhite;
    border: 1px solid $cadfem-steamgray;
    border-radius: 8px;
    padding: building-units(1);

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    max-width: 100%;
    margin-bottom: building-units(1);

    &__option {
        display: flex;
        flex-flow: row;

        @extend %text-small;
    }

    &__bottom {
        margin-top: building-units(1);
    }

    div.product-tile__price > .flatrate-true {
      display: none;
    }

    div.product-tile__price > .flatrate-false {
      display: block;
    }

    &--flatrate {
        position: relative;
        overflow: hidden;
        &:before {
            background-color: $cadfem-cornflower;
            height: 100px;
            width: 100px;
            position: absolute;
            top: -50px;
            right: -50px;
            content: ' ';

            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        &:after {
            position: absolute;
            top: 9px;
            right: 13px;
            color: $cadfem-purewhite;
            font-size: 20px;
            font-weight: $font-weight-semibold;
            content: '%'
        }

        div.product-tile__price > .flatrate-true {
            display: block;
        }

        div.product-tile__price > .flatrate-false {
            display: none;
        }
    }

    &.elearning {
        box-shadow: $shadow;
        border-color: $cadfem-cornflower;
    }
}

/*
We set the element we are applying our loading mask to relative
*/
.loading-mask {
    position: fixed;
}

/*
Because we set .loading-mask relative, we can span our ::before
element over the whole parent element
*/
.loading-mask::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

/*
Spin animation for .loading-mask::after
*/
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/*
The loading throbber is a single spinning element with three
visible borders and a border-radius of 50%.
Instead of a border we could also use a font-icon or any
image using the content attribute.
*/
.loading-mask::after {
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: 2s linear 0s normal none infinite running spin;
    filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
}

.swiper-slide__content .product-tile-container {
    margin-left: 0;
    margin-right: 0;
    min-width: 100%;
}

/*
hide image if typo3 image attribute is set to 'No' CADFEMEB-4802
*/
.product-tile-container--noimage .product-tile .product-tile__image {
  display: none;
}

.product-tile-container--noimage .product-tile .product-tile__badges {
  margin-top: 30px!important;
}

.frame.frame-default.frame-type-list {
  .row {
    div {
      &:has(.product-tile-widget.product-tile-container) {
        display: flex;
        margin-bottom: 0;
      }
    }
  }
}
