//-------------------------------------------------------------
//Reference Partner Teaser
//-------------------------------------------------------------

// Variables

// General
.partner_teaser {
    padding: 25px;
    margin-bottom: 0;
    border: 1px solid $cadfem-steamgray;
    border-radius: 8px;
    flex-flow: row wrap !important;

    &__kicker {
        color: $cadfem-ashgray;

        &:after {
            content: " ";
            display: block;
            width: 45px;
            border-top: 2px solid $cadfem-cornflower;
            margin: 10px auto 30px;
        }
    }

    &__logo {
        max-width: 210px;
        max-height: 80px;
        height: auto;
        margin-bottom: 30px;
    }

    &__img {
        width: 32px;
        height: 32px;
        font-size: 32px;
        margin: 0 auto;
        color: $cadfem-ashgray;
    }

    &__content {
        text-align: center;
        padding: 0;
    }

    &__text {
        color: $cadfem-darkblue;
        margin-bottom: 30px;
    }
}
