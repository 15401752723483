// stylelint-disable declaration-no-important

//
// Border
//
$border-width: 1px;
$border-color: $blue;
$border-radius: 6px;

.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

.border-2        { border-width: 2px !important; }
.border-top-2    { border-top-width: 2px !important; }
.border-right-2  { border-right-width: 2px !important; }
.border-bottom-2 { border-bottom-width: 2px !important; }
.border-left-2   { border-left-width: 2px !important; }

.border-3        { border-width: 3px !important; }
.border-top-3    { border-top-width: 3px !important; }
.border-right-3  { border-right-width: 3px !important; }
.border-bottom-3 { border-bottom-width: 3px !important; }
.border-left-3   { border-left-width: 3px !important; }

.border-4        { border-width: 4px !important; }
.border-top-4    { border-top-width: 4px !important; }
.border-right-4  { border-right-width: 4px !important; }
.border-bottom-4 { border-bottom-width: 4px !important; }
.border-left-4   { border-left-width: 4px !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border-0#{$infix} {
            border: 0 !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border-top-show#{$infix} {
            border-top-width: $border-width !important;
            border-top-style: solid;
        }

        .border-right-show#{$infix} {
            border-right: $border-width !important;
            border-top-style: solid;
        }

        .border-bottom-show#{$infix} {
            border-bottom: $border-width !important;
            border-top-style: solid;
        }

        .border-left-show#{$infix} {
            border-left: $border-width !important;
            border-top-style: solid;
        }

        .border-top-hide#{$infix} {
            border-top: 0 !important;
        }

        .border-right-hide#{$infix} {
            border-right: 0 !important;
        }

        .border-bottom-hide#{$infix} {
            border-bottom: 0 !important;
        }

        .border-left-left#{$infix} {
            border-left: 0 !important;
        }
    }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

.rounded {
  border-radius: $border-radius !important;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.pipe-left {
    display: inline;
    border-left: 1px solid $cadfem-ashgray;
    padding-left: 10px;
    margin-left: 8px;
}

.pipe-right {
    display: inline-block;
    border-right: 1px solid $cadfem-ashgray;
    padding-right: 10px;
    margin-right: 8px;
}
